import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { AxiosError } from "axios";
import { toast } from "sonner";
import { ApiProvider } from "../ApiProvider";
import { getInitialState } from "../helpers";
import { IMarkups } from "./getSharedSettings";

interface IUpdateSharingPayload {
	ListingIds: Array<number>;
	ShareOutSettings: Array<Omit<IMarkups, "Listing_ID">>;
}

const initialState = getInitialState([]);

export const updateSharing = createAsyncThunk<unknown, IUpdateSharingPayload>(
	"listingsSharing/updateSharing",
	async (payload, { rejectWithValue }) => {
		try {
			await ApiProvider.default.post("/api/pricing/UpdateListingsSharing", payload);
			const allListingIDs = payload.ListingIds.reduce((acc, item) => acc + ` ${item}`, "");
			toast.success(`Successfully updated listings: ${allListingIDs}`);
		} catch (error) {
			if (error instanceof AxiosError) return rejectWithValue(error.message);
			else return rejectWithValue("Unknown error");
		}
	},
);

export const listingsSharingSlice = createSlice({
	name: "listingsSharing",
	initialState,
	reducers: {},
	extraReducers: (builder) => {
		builder
			.addCase(updateSharing.pending, (state) => {
				state.loading = true;
			})
			.addCase(updateSharing.fulfilled, (state, _action) => {
				state.loading = false;
				state.version++;
				state.init = true;
				state.error = null;
			})
			.addCase(updateSharing.rejected, (state, action) => {
				state.loading = false;
				state.error = `${action.payload}`;
			});
	},
});
