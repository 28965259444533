import { getThunkAndSlice } from "@src/store/getThunkAndSlice";

const initialState = new Array<{
	Location_ID: number | null;
	Location_Name: string;
}>();

export const { thunk: listLocations, slice: listLocationsSlice } = getThunkAndSlice<
	{
		_dc: string;
	},
	typeof initialState
>({
	initialState,
	path: "/api/System/ListLocations",
});
