import { yupResolver } from "@hookform/resolvers/yup";
import { ResetIcon } from "@src/assets/general-icons";
import { Button, ButtonVariant } from "@src/components/button/Button";
import { FiltersModal } from "@src/components/filtersModal/FiltersModal";
import { DateInput } from "@src/components/input/DateInput";
import { Input } from "@src/components/input/Input";
import { SelectYup } from "@src/components/select/SelectYup";
import { salesFiltersEmptyValues } from "@src/components/utils/hookFormUtils";
import { getEventOptions } from "@src/components/utils/reactSelectUtils";
import { eventActions, settingsActions } from "@src/store";
import { useAppDispatch, useAppSelector } from "@src/store/hooks";
import { getListCategoriesSales } from "@src/store/sales/listCategoriesSlice";
import { getListHeadlinersSales } from "@src/store/sales/listHeadlinersSlice";
import { getListMarketplaces } from "@src/store/sales/listMarketplacesSlice";
import { getListSaleStatuses } from "@src/store/sales/listSalesStatusesSlice";
import { getListTags } from "@src/store/sales/listTagsSlice";
import { getListVenuesSales } from "@src/store/sales/listVenueSlice";
import { RootState } from "@src/store/store";
import { SalesFiltersProps, SalesHeaderFiltersValidation } from "@src/validation/SalesHeaderFiltersValidation";
import React, { useEffect } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { useSearchParams } from "react-router-dom";
import { salesInputItems } from "./sales-input-items";
import { SalesFilters } from "./SalesFilters";
import styles from "./SalesHeader.module.scss";

export const SalesHeader: React.FC = () => {
	const [params, setParams] = useSearchParams();
	const companyName = useAppSelector((state) => state.settings.companyName);
	const isAdmin = useAppSelector((state) => state.settings.isAdmin);

	const dispatch = useAppDispatch();

	const { data, loading } = useAppSelector((state) => state.pricing.companies);
	const companyOptions = data.map((x) => ({
		label: x,
		value: x,
	}));

	const events = useAppSelector((state) => state.pricing.events.data.Events);

	// CATEGORIES
	const categoryOptionsData = useAppSelector((state: RootState) => state.sales.listCategoriesSales.categories);

	const uniqueCategories = Array.from(new Set(categoryOptionsData.map((item) => item.Cat_Type.toLocaleLowerCase())));

	const categoryOptions = uniqueCategories.map((item) => ({
		label: item,
		value: item,
	}));

	// VENUES
	const venueOptionsData = useAppSelector((state: RootState) => state.sales.listVenues.venues);
	const uniqueVenues = Array.from(new Set(venueOptionsData.map((item) => item.Venue_Name))).slice(0, 200);

	const venueOptions = uniqueVenues.map((item) => ({
		label: item,
		value: item,
	}));

	// HEADLINERS
	const headlinerOptionsData = useAppSelector((state: RootState) => state.sales.listHeadliners.headliners);
	const uniqueHeadliners = Array.from(new Set(headlinerOptionsData.map((item) => item.Event_Headliner))).slice(0, 100);

	const headlinerOptions = uniqueHeadliners.map((item) => ({
		label: item,
		value: item,
	}));

	// SALE STATUSES
	const saleStatusesOptionsData = useAppSelector((state: RootState) => state.sales.listSaleStatuses.saleStatuses);

	const saleStatusesOptions = saleStatusesOptionsData.map((item) => ({
		label: item,
		value: item,
	}));

	// MARKETPLACES
	const marketplacesOptionsData = useAppSelector((state: RootState) => state.sales.listMarketplaces.marketplaces);

	const marketplaceOptions = marketplacesOptionsData.map((item) => ({
		label: item.Exchange_Name,
		value: item.Exchange_Name,
	}));

	// TAGS
	const tagOptionsData = useAppSelector((state: RootState) => state.sales.listTags.tags);

	const uniqueTags = Array.from(new Set(tagOptionsData.map((item) => item.Tag)));

	const tagOptions = uniqueTags.map((item) => ({
		label: item,
		value: item,
	}));

	useEffect(() => {
		dispatch(getListCategoriesSales());
		dispatch(getListVenuesSales());
		dispatch(getListHeadlinersSales());
		dispatch(getListSaleStatuses());
		dispatch(getListMarketplaces());
		dispatch(getListTags());
	}, [dispatch]);

	// this will be changed by next sprint
	const smartSearchOptions = getEventOptions(events, "Venue_Name");

	const { control, handleSubmit, reset, setValue, getValues } = useForm({
		mode: "onChange",
		resolver: yupResolver(SalesHeaderFiltersValidation),
		defaultValues: {
			companyName: companyName ?? "",
			category: params.get("category") ?? "",
			salesStartDate: params.get("salesStartDate") ?? "",
			salesEndDate: params.get("salesEndDate") ?? "",
			salesHeadliner: params.get("salesHeadliner") ?? "",
			venue: params.get("venue") ?? "",
			eventStartDate: params.get("eventStartDate") ?? "",
			eventEndDate: params.get("eventEndDate") ?? "",
			smartSearch: params.get("smartSearch") ?? "",
			tags: params.get("tags") ?? "",
			marketplace: params.get("marketplace") ?? "",
			paymentStartDate: params.get("paymentStartDate") ?? "",
			paymentEndDate: params.get("paymentEndDate") ?? "",
			salesStatus: params.get("salesStatus") ?? "",
			section: params.get("section") ?? "",
			row: params.get("row") ?? "",
			minQuantity: params.get("minQuantity") ?? "",
			invoiceId: params.get("invoiceId") ?? "",
			eventId: params.get("eventId") ?? "",
			buyerPOId: params.get("buyerPOId") ?? "",
		},
	});

	const mappedInputs = salesInputItems.map(({ id, name, placeholder, type, showType }) => {
		return (
			<div className={styles.inputWrapper} data-type={type} key={id}>
				<div className={styles.smartSearchWrapper}>
					{showType === "input" && (
						<Input wrapperStyle={{ width: "100%" }} type={type} control={control} placeholder={placeholder} name={name} />
					)}
					{showType === "date" && (
						<DateInput wrapperStyle={{ minWidth: "100px" }} control={control} placeholder={placeholder} name={name} />
					)}
				</div>
			</div>
		);
	});

	const salesFilterProps = {
		control,
		reset,
		setValue,
		categoryOptions,
		headlinerOptions,
		venueOptions,
		tagOptions,
		smartSearchOptions,
		marketplaceOptions,
		saleStatusesOptions,
	};

	const allQueryNames = Object.entries(getValues())
		.map(([k, _]) => k)
		.concat("showSold", "showParking", "showCanceled");
	const hookFormReset = () => reset(salesFiltersEmptyValues);

	const onSubmit: SubmitHandler<SalesFiltersProps> = (data) => {
		Object.entries(data).forEach(([k, v]) => {
			v ? params.set(k, String(v)) : params.delete(k);
		});
		params.delete("eventsPage");
		if (params.get("companyName")) {
			const companyName = params.get("companyName") ?? "";
			dispatch(settingsActions.setCompanyName(companyName));
			dispatch(eventActions.setEvent({ event: null }));
			params.delete("companyName");
		}
		setParams(params);
	};

	return (
		<form className={styles.form} onSubmit={handleSubmit(onSubmit)}>
			{isAdmin && (
				<div className={styles.companyhWrapper}>
					<SelectYup
						isLoading={loading}
						name="companyName"
						placeholder="Company name"
						control={control}
						options={companyOptions}
					/>
				</div>
			)}
			{mappedInputs}
			<div className={styles.buttonContainer}>
				<Button
					type="button"
					onClick={() => {
						setParams({});
						reset(salesFiltersEmptyValues);
					}}
					variant={ButtonVariant.Quaternary}>
					<div className={styles.filterButton}>
						<ResetIcon />
					</div>
				</Button>
				<FiltersModal
					onReset={{
						queriesToReset: allQueryNames,
						resetFunction: hookFormReset,
					}}>
					<SalesFilters {...salesFilterProps} />
				</FiltersModal>
				<Button style={{ width: "100px" }} type="submit">
					Search
				</Button>
			</div>
		</form>
	);
};
