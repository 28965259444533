import { getThunkAndSlice } from "@src/store/getThunkAndSlice";

const initialState = false;
export interface IAppendListingNotes {
	listingIds: Array<number>;
	internalNotes: string | null;
	externalNotes: string | null;
}
export const { thunk: appendListingNotes, slice: appendListingNotesSlice } = getThunkAndSlice<
	IAppendListingNotes,
	typeof initialState
>({
	initialState,
	path: "/api/Pricing/AppendListingNotes",
	method: "post",
});
