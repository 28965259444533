import helperStyles from "@src/components/helpers.module.scss";
import React from "react";

export const Trashcan = () => {
	return (
		<div className={helperStyles.iconContainer}>
			<svg width="8" height="11" viewBox="0 0 8 11" fill="none" xmlns="http://www.w3.org/2000/svg">
				<path d="M3.33333 4.07143H2.66667V8.35714H3.33333V4.07143Z" fill="#101828" />
				<path d="M5.33333 4.07143H4.66667V8.35714H5.33333V4.07143Z" fill="#101828" />
				<path
					d="M0 1.92857V2.64286H0.666667V9.78571C0.666667 9.97515 0.736905 10.1568 0.861929 10.2908C0.986953 10.4247 1.15652 10.5 1.33333 10.5H6.66667C6.84348 10.5 7.01305 10.4247 7.13807 10.2908C7.2631 10.1568 7.33333 9.97515 7.33333 9.78571V2.64286H8V1.92857H0ZM1.33333 9.78571V2.64286H6.66667V9.78571H1.33333Z"
					fill="#101828"
				/>
				<path d="M5.33333 0.5H2.66667V1.21429H5.33333V0.5Z" fill="#101828" />
			</svg>
		</div>
	);
};
