import React from "react";
import { Toaster } from "sonner";
import "./global.module.scss";
import { Routes } from "./routes/Routes";
import "./styles/styles.scss";
import { useAppSelector } from "@src/store/hooks";

export const App: React.FC = () => {
	const theme = useAppSelector((state) => state.settings.theme);

	return (
		<div className={`theme--${theme}`}>
			<Routes />
			<Toaster duration={2000} position="top-center" expand={false} closeButton />
			<div id="modal"></div>
		</div>
	);
};
