import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { getThunkAndSlice } from "@src/store/getThunkAndSlice";

interface TItemTagsInitialState {
	listingId: number;
}

export interface ISeatItem {
	Item_ID: number;
	Seat_Number: number;
	IsPdfAttached: boolean;
	Barcode: string;
	Attachment_Date: string | null;
	Attachment_Notes: string | null;
}

const initialState = new Array<ISeatItem>();

export const {
	thunk: getSeats,
	slice: hiddenGetSeatsSlice,
	extraReducers: hiddenExtraReducers,
} = getThunkAndSlice<TItemTagsInitialState, typeof initialState>({
	initialState,
	path: "/api/System/GetSeats",
});
export const getSeatsSlice = createSlice({
	name: hiddenGetSeatsSlice.name,
	initialState: hiddenGetSeatsSlice.getInitialState(),
	reducers: {
		updateGetSeats: (state, action: PayloadAction<Array<ISeatItem>>) => {
			state.data = action.payload;
			state.version++;
		},
		reset(state) {
			state.data = initialState;
			state.version++;
		},
	},
	extraReducers: hiddenExtraReducers,
});
