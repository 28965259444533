import { createSelector, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { FeaturesDictionary } from "@src/store/auto-pricer/autopricerSlice";
import { EventInventoryRecord } from "@src/store/pricing/queryEventInventoryAllSlice";
import { FillLayer, MapboxMap } from "react-map-gl";
import {
	createFillLayer,
	groupFeaturesBySection,
	normalizeName,
	queryMapPolygonFeatures,
} from "@src/components/map-view/map.utils";
import { RootState } from "@src/store/store";
import { SELECTED_SECTION_FILL_PAINT, USERS_SECTION_FILL_PAINT } from "@src/components/map-view/map.constants";
import { difference, getUniqueArrayValues, convertMapToRecord, mapDictionaryKeysToValues, union } from "@src/utils/array.utils";

export interface MapSliceType {
	featuresBySection: FeaturesDictionary;
	selectedSectionNames: Array<string>;
	usersSectionNames: Array<string>;
}

const initialState: MapSliceType = {
	featuresBySection: {},
	selectedSectionNames: [],
	usersSectionNames: [],
};

export interface InitializeStatePayload {
	eventInventoryData: Array<EventInventoryRecord>;
	map: MapboxMap;
}

const mapSlice = createSlice({
	name: "map",
	initialState,
	reducers: {
		initializeState: (state, action: PayloadAction<InitializeStatePayload>) => {
			const { eventInventoryData, map } = action.payload;

			const allMapboxSectionFeatures = queryMapPolygonFeatures(undefined, map, "section");

			state.featuresBySection = convertMapToRecord(groupFeaturesBySection(allMapboxSectionFeatures));
			state.usersSectionNames = getUniqueArrayValues(eventInventoryData.map(({ Section }) => normalizeName(Section)));
		},
		toggleSection: (state, action: PayloadAction<string>) => {
			const { selectedSectionNames } = state;
			const sectionName = action.payload;

			state.selectedSectionNames = selectedSectionNames.includes(sectionName)
				? difference(selectedSectionNames, sectionName)
				: union(selectedSectionNames, sectionName);
		},
		resetSelectedSections: (state) => {
			state.selectedSectionNames = [];
		},
		resetMapState: (state) => {
			state.selectedSectionNames = [];
			state.usersSectionNames = [];
		},
	},
});

const _selectSelectedSectionNames = (state: RootState) => state.map.selectedSectionNames;
const _selectUsersSectionNames = (state: RootState) => state.map.usersSectionNames;
const _selectFeaturesBySection = (state: RootState) => state.map.featuresBySection;

export const selectPricingMapFillLayers = createSelector(
	[_selectSelectedSectionNames, _selectUsersSectionNames, _selectFeaturesBySection],
	(selectedSectionNames, usersSectionNames, featuresBySection) => {
		const selectedSectionsFeatures = mapDictionaryKeysToValues(featuresBySection, selectedSectionNames);
		const usersSectionsFeatures = mapDictionaryKeysToValues(
			featuresBySection,
			difference(usersSectionNames, selectedSectionNames),
		);

		const fillLayers: Array<FillLayer> = [];

		if (selectedSectionsFeatures.length > 0) {
			fillLayers.push(createFillLayer("selected-sections-fill-layer", SELECTED_SECTION_FILL_PAINT, selectedSectionsFeatures));
		}

		if (usersSectionsFeatures.length > 0) {
			fillLayers.push(createFillLayer("users-sections-fill-layer", USERS_SECTION_FILL_PAINT, usersSectionsFeatures));
		}

		return fillLayers;
	},
);

export default mapSlice;
