import { getThunkAndSlice } from "@src/store/getThunkAndSlice";

const initialState = new Array<{
	Venue_ID: number;
	Venue_Name: string;
}>();

export const { thunk: listVenues, slice: listVenuesSlice } = getThunkAndSlice<Record<string, never>, typeof initialState>({
	initialState,
	path: "/api/Pricing/ListVenues",
});
