// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.SalesHeader-module_inputWrapper___I8O7x {
  width: 268px;
}
.SalesHeader-module_inputWrapper___I8O7x[data-type=date] {
  width: 126px;
}
.SalesHeader-module_inputWrapper___I8O7x .SalesHeader-module_smartSearchWrapper___QdMMO {
  width: 100%;
}

.SalesHeader-module_companyhWrapper___KdVJX {
  flex-shrink: 1;
  width: 268px;
}

.SalesHeader-module_form___NThlO {
  display: flex;
  gap: 1.2rem;
  align-items: center;
  min-width: 0;
}
.SalesHeader-module_form___NThlO .SalesHeader-module_buttonContainer___bAzZ5 {
  display: flex;
  gap: 1.2rem;
  align-items: center;
}
.SalesHeader-module_form___NThlO .SalesHeader-module_buttonContainer___bAzZ5 .SalesHeader-module_filterButton___Ol9D7 {
  display: flex;
  gap: 0.4rem;
  align-items: center;
  justify-content: center;
}`, "",{"version":3,"sources":["webpack://./src/components/header/components/salesHeader/SalesHeader.module.scss"],"names":[],"mappings":"AAAA;EACC,YAAA;AACD;AACC;EACC,YAAA;AACF;AAEC;EACC,WAAA;AAAF;;AAIA;EACC,cAAA;EACA,YAAA;AADD;;AAIA;EACC,aAAA;EACA,WAAA;EACA,mBAAA;EACA,YAAA;AADD;AAGC;EACC,aAAA;EACA,WAAA;EACA,mBAAA;AADF;AAGE;EACC,aAAA;EACA,WAAA;EACA,mBAAA;EACA,uBAAA;AADH","sourcesContent":[".inputWrapper {\r\n\twidth: 268px;\r\n\r\n\t&[data-type=\"date\"] {\r\n\t\twidth: 126px;\r\n\t}\r\n\r\n\t.smartSearchWrapper {\r\n\t\twidth: 100%;\r\n\t}\r\n}\r\n\r\n.companyhWrapper {\r\n\tflex-shrink: 1;\r\n\twidth: 268px;\r\n}\r\n\r\n.form {\r\n\tdisplay: flex;\r\n\tgap: 1.2rem;\r\n\talign-items: center;\r\n\tmin-width: 0;\r\n\r\n\t.buttonContainer {\r\n\t\tdisplay: flex;\r\n\t\tgap: 1.2rem;\r\n\t\talign-items: center;\r\n\r\n\t\t.filterButton {\r\n\t\t\tdisplay: flex;\r\n\t\t\tgap: 0.4rem;\r\n\t\t\talign-items: center;\r\n\t\t\tjustify-content: center;\r\n\t\t}\r\n\t}\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"inputWrapper": `SalesHeader-module_inputWrapper___I8O7x`,
	"smartSearchWrapper": `SalesHeader-module_smartSearchWrapper___QdMMO`,
	"companyhWrapper": `SalesHeader-module_companyhWrapper___KdVJX`,
	"form": `SalesHeader-module_form___NThlO`,
	"buttonContainer": `SalesHeader-module_buttonContainer___bAzZ5`,
	"filterButton": `SalesHeader-module_filterButton___Ol9D7`
};
export default ___CSS_LOADER_EXPORT___;
