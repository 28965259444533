import React from "react";
import { BounceLoader } from "react-spinners";

export interface ILoaderProps {
	color?: React.CSSProperties["color"];
	controlSize?: "xxl" | "xl" | "l" | "m" | "sm" | "xsm";
}

import { useAppSelector } from "@src/store/hooks";
import { clx } from "../utils/stringUtils";
import styles from "./Loader.module.scss";

export const Loader: React.FC<ILoaderProps> = ({ color, controlSize = "m" }) => {
	let size = 18;
	switch (controlSize) {
		case "xxl":
		case "xl":
		case "l":
			size = 18;
			break;
		case "m":
			size = 10;
			break;
		case "sm":
		case "xsm":
			size = 8;
			break;
	}

	const theme = useAppSelector((state) => state.settings.theme);
	const themeColor = color ?? (theme === "light" ? "black" : "white");

	return (
		<div className={clx(styles.main, styles[controlSize])} data-is-full-page={controlSize == "xxl"}>
			<BounceLoader className={styles.BounceLoader} color={themeColor} size={size} />
		</div>
	);
};
