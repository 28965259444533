import { getThunkAndSlice } from "@src/store/getThunkAndSlice";

interface TItemTagsInitialState {
	eventIds: Array<number>;
}

const initialState = new Array<{
	TagId: number;
	TagDesc: string;
}>();

export const { thunk: getEventTags, slice: getEventTagsSlice } = getThunkAndSlice<TItemTagsInitialState, typeof initialState>({
	initialState,
	method: "post",
	path: "/api/System/GetEventTags",
});
