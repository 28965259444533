import React from "react";
import { Tooltip } from "react-tooltip";
import { ISubItem } from "./Items";
import styles from "./Sidebar.module.scss";

interface ISidebarTooltipProps {
	subItems?: Array<ISubItem>;
	label: string;
	isActive: boolean;
	isExpanded: boolean;
}

export const SidebarTooltip: React.FC<ISidebarTooltipProps> = ({ subItems, isActive, label, isExpanded }) => {
	const mappedSubItems = subItems?.map((i) => (
		<div className={styles.itemContainer} onClick={() => i.onClick && i.onClick()} key={i.id}>
			<div className={styles.itemLabel} style={{ color: "var(--activeIcon)" }}>
				{i.label}
			</div>
		</div>
	));

	const popupLabel = (
		<div className={styles.itemLabel} style={{ color: "var(--activeIcon)" }} data-is-active={isActive}>
			{label}
		</div>
	);
	const popupSubItems = (
		<div className={styles.subItemsContainerInner} style={{ padding: "10px 0px" }}>
			{mappedSubItems}
		</div>
	);
	const popupElement = subItems ? popupSubItems : popupLabel;
	const itemBorder = subItems ? "1px solid #5C5F65" : undefined;

	if (isExpanded) return null;
	return (
		<Tooltip
			style={{
				background: subItems ? "var(--appBlack)" : "var(--popupBackground)",
				borderRadius: subItems ? "12px" : "4px",
			}}
			className={styles.main}
			data-has-subitems={!!subItems}
			clickable
			place="right"
			offset={18}
			opacity={1}
			border={itemBorder}
			anchorSelect={`#${label}`}>
			{popupElement}
		</Tooltip>
	);
};
