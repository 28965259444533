import { yupResolver } from "@hookform/resolvers/yup";
import { ResetIcon } from "@src/assets/general-icons";
import { Button, ButtonVariant } from "@src/components/button/Button";
import { FiltersModal } from "@src/components/filtersModal/FiltersModal";
import { DateInput } from "@src/components/input/DateInput";
import { Input } from "@src/components/input/Input";
import { SelectYup } from "@src/components/select/SelectYup";
import { filtersEmptyValues } from "@src/components/utils/hookFormUtils";
import { MixpanelCategory, mixpanelTrack } from "@src/components/utils/mixPanelUtils";
import { getEventOptions } from "@src/components/utils/reactSelectUtils";
import { eventActions, pricingActions, settingsActions } from "@src/store";
import { useAppDispatch, useAppSelector } from "@src/store/hooks";
import { HeaderFiltersProps, HeaderFiltersValidation } from "@src/validation/HeaderFiltersValidation";
import React, { useCallback, useEffect, useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { useSearchParams } from "react-router-dom";
import { inputItems } from "./input-items";
import { PricingFilters } from "./PricingFilters";
import styles from "./PricingHeader.module.scss";

export const PricingHeader: React.FC = () => {
	const [params, setParams] = useSearchParams();
	const companyName = useAppSelector((state) => state.settings.companyName);
	const dispatch = useAppDispatch();

	const setFormValues = useCallback(
		(params: URLSearchParams) => ({
			companyName: companyName ?? "",
			eventName: params.get("eventName") ?? "",
			eventHeadliner: params.get("eventHeadliner") ?? "",
			venue: params.get("venue") ?? "",
			startDate: params.get("startDate") ?? "",
			endDate: params.get("endDate") ?? "",
			smartSearch: params.get("smartSearch") ?? "",
			tags: params.get("tags") ?? "",
			category: params.get("category") ?? "",
			section: params.get("section") ?? "",
			row: params.get("row") ?? "",
			minQuantity: params.get("minQuantity") ?? "",
			daysSinceLastUpdate: params.get("daysSinceLastUpdate") ?? "",
			listingId: params.get("listingId") ?? "",
			eventId: params.get("eventId") ?? "",
			poId: params.get("poId") ?? "",
			poStartDate: params.get("poStartDate") ?? "",
			poEndDate: params.get("poEndDate") ?? "",
		}),
		[companyName],
	);

	const savedFilterLS = JSON.parse(localStorage.portal_saved_filters ?? null);
	const [savedFilters, setSavedFilters] = useState(savedFilterLS);

	const { control, handleSubmit, reset, setValue, getValues } = useForm({
		mode: "onChange",
		resolver: yupResolver(HeaderFiltersValidation),
		defaultValues: setFormValues(params),
	});

	const onFiltersSave = () => {
		const values = getValues();
		if (!savedFilters) {
			setSavedFilters([values]);
			localStorage.setItem("portal_saved_filters", JSON.stringify([values]));
			return;
		}
		const combinedFilters = [...savedFilters, values];

		setSavedFilters(combinedFilters);
		localStorage.setItem("portal_saved_filters", JSON.stringify(combinedFilters));

		mixpanelTrack(MixpanelCategory.SAVED_FILTERS, {
			data: combinedFilters,
		});
	};

	useEffect(() => {
		if (!companyName) return;
		setValue("companyName", companyName);
	}, [companyName, setValue]);

	useEffect(() => {
		reset(setFormValues(params));
	}, [params, reset, setFormValues]);

	const onSubmit: SubmitHandler<HeaderFiltersProps> = (data) => {
		const filterData = Object.entries(data);
		filterData.forEach(([k, v]) => {
			v ? params.set(k, String(v)) : params.delete(k);
		});
		params.delete("eventsPage");
		const companyNameQuery = params.get("companyName") ?? "";
		const hasCompanyChanged = companyName !== companyNameQuery;

		if (hasCompanyChanged) {
			dispatch(eventActions.setEvent({ event: null }));
			dispatch(settingsActions.setCompanyName(companyNameQuery));
		}

		params.delete("companyName");
		setParams(params);
		const mixPanelData = Object.fromEntries(Object.entries(data).filter(([_, v]) => v));
		if (Object.keys(mixPanelData).length) {
			mixpanelTrack(MixpanelCategory.FILTERS_APPLIED, {
				data: mixPanelData,
			});
		}
	};

	const events = useAppSelector((state) => state.pricing.events.data.Events);

	const categoryOptions = getEventOptions(events, "Cat_Type");
	const headlinerOptions = getEventOptions(events, "Event_Headliner");
	const venueOptions = getEventOptions(events, "Venue_Name");

	const smartSearchOptions = [...categoryOptions, ...headlinerOptions, ...venueOptions];

	const mappedInputs = inputItems.map(({ id, name, placeholder, type, showType }) => {
		return (
			<div className={styles.inputWrapper} data-type={type} key={id}>
				<div className={styles.smartSearchWrapper}>
					{showType === "input" && (
						<Input wrapperStyle={{ width: "100%" }} type={type} control={control} placeholder={placeholder} name={name} />
					)}
					{showType === "select" && (
						<SelectYup name={name} placeholder={placeholder} control={control} options={smartSearchOptions} />
					)}
					{showType === "date" && (
						<DateInput wrapperStyle={{ minWidth: "100px" }} control={control} placeholder={placeholder} name={name} />
					)}
				</div>
			</div>
		);
	});

	const isAdmin = useAppSelector((state) => state.settings.isAdmin);

	const { data, loading } = useAppSelector((state) => state.pricing.companies);
	const companyOptions = data.map((x) => ({
		label: x,
		value: x,
	}));

	useEffect(() => {
		if (!isAdmin) return;
		dispatch(pricingActions.listCompanies({}));
	}, [isAdmin, dispatch]);

	const pricingFilterProps = {
		control,
		reset,
		setValue,
		categoryOptions,
		headlinerOptions,
		venueOptions,
		smartSearchOptions,
		savedFilters,
	};

	const allQueryNames = Object.entries(getValues())
		.map(([k, _]) => k)
		.concat("showSold", "showParking", "showCanceled");
	const hookFormReset = () => reset(filtersEmptyValues);

	return (
		<form className={styles.form} onSubmit={handleSubmit(onSubmit)}>
			{isAdmin && (
				<div className={styles.companyhWrapper}>
					<SelectYup
						isLoading={loading}
						name="companyName"
						placeholder="Company name"
						control={control}
						options={companyOptions}
					/>
				</div>
			)}
			{mappedInputs}
			<div className={styles.buttonContainer}>
				<Button
					type="button"
					onClick={() => {
						setParams({});
						reset(filtersEmptyValues);
					}}
					variant={ButtonVariant.Quaternary}>
					<div className={styles.filterButton}>
						<ResetIcon />
					</div>
				</Button>
				<FiltersModal
					onReset={{
						queriesToReset: allQueryNames,
						resetFunction: hookFormReset,
					}}
					buttonRightContent={
						<Button
							onClick={onFiltersSave}
							style={{
								minWidth: "96px",
							}}
							variant={ButtonVariant.Tertiary}>
							Save
						</Button>
					}
					onOpenButtonClick={() => mixpanelTrack(MixpanelCategory.FILTERS_BUTTON_CLICKED)}>
					<PricingFilters {...pricingFilterProps} />
				</FiltersModal>
				<Button style={{ width: "100px" }} type="submit">
					Search
				</Button>
			</div>
		</form>
	);
};
