import { FillPaint } from "mapbox-gl";
import { APPLE, CADMIUM_ORANGE, COLADON_BLUE } from "@src/styles/colors";

export const SELECTED_SECTION_FILL_PAINT: FillPaint = {
	"fill-color": APPLE,
	"fill-opacity": 0.4,
};

export const USERS_SECTION_FILL_PAINT: FillPaint = {
	"fill-color": COLADON_BLUE,
	"fill-opacity": 0.4,
};

export const COMPARATIVE_SECTION_FILL_PAINT: FillPaint = {
	"fill-color": CADMIUM_ORANGE,
	"fill-opacity": 0.4,
};
