import { getThunkAndSlice } from "@src/store/getThunkAndSlice";
import { fromType } from "@src/store/helpers";

const initialState = fromType({
	m_Item1: 0,
	m_Item2: 0,
	m_Item3: 0,
});

export const { thunk: getMappingCounts, slice: getMappingCountsSlice } = getThunkAndSlice<
	{
		forceRefresh: boolean;
	},
	typeof initialState
>({
	initialState,
	path: "/api/Notifications/GetMappingCounts",
});
