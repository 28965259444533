import helperStyles from "@src/components/helpers.module.scss";
import React from "react";

export const Axs: React.FC = () => {
	return (
		<div className={helperStyles.iconContainer}>
			<svg
				version="1.0"
				xmlns="http://www.w3.org/2000/svg"
				width="100%"
				height="100%"
				viewBox="0 0 435.000000 226.000000"
				preserveAspectRatio="xMidYMid meet">
				<metadata>Created by potrace 1.16, written by Peter Selinger 2001-2019</metadata>
				<g transform="translate(0.000000,226.000000) scale(0.100000,-0.100000)" fill="#0254a3" stroke="none">
					<path
						d="M690 1889 c-151 -11 -371 -71 -384 -104 -3 -9 26 -51 72 -107 88
		-105 81 -103 202 -67 111 32 315 33 383 2 38 -17 52 -31 67 -65 22 -49 26
		-123 8 -135 -7 -4 -105 -11 -218 -14 -284 -8 -397 -39 -498 -137 -158 -154
		-159 -476 -1 -646 81 -89 210 -131 381 -125 97 4 111 7 179 41 51 25 93 56
		133 97 32 34 62 61 66 61 5 0 33 -30 63 -68 84 -104 83 -103 168 -100 l74 3 0
		470 c0 422 -2 478 -19 550 -34 150 -94 235 -201 286 -109 52 -276 73 -475 58z
		m354 -824 c-1 -206 -106 -335 -273 -335 -80 0 -136 25 -171 77 -20 29 -26 51
		-28 112 -8 174 82 231 358 227 l115 -1 -1 -80z"
					/>
					<path
						d="M3343 1890 c-147 -16 -263 -73 -333 -161 -53 -67 -74 -135 -74 -239
		0 -118 23 -187 88 -258 66 -72 144 -108 351 -161 187 -48 240 -72 266 -121 59
		-110 -23 -197 -195 -207 -105 -6 -200 14 -312 66 -48 23 -88 41 -89 41 -9 0
		-165 -202 -165 -214 0 -32 202 -112 385 -152 96 -21 276 -23 355 -5 89 22 195
		75 243 123 121 122 151 350 65 510 -62 116 -152 170 -387 230 -170 43 -244 77
		-273 123 -22 35 -18 87 9 117 40 45 72 53 198 52 114 -1 204 -18 298 -59 20
		-8 69 38 146 138 53 69 51 73 -57 111 -158 56 -370 83 -519 66z"
					/>
					<path
						d="M1365 1871 c-6 -10 23 -47 349 -446 107 -132 196 -245 196 -252 0 -7
		-67 -150 -150 -318 -82 -168 -147 -312 -144 -320 5 -12 27 -15 105 -15 l98 0
		58 68 c32 37 103 122 157 189 55 67 103 120 107 117 5 -3 123 -146 264 -319
		140 -172 267 -323 281 -334 36 -28 124 -30 124 -3 0 9 -179 377 -398 817 -335
		672 -403 802 -426 813 -33 15 -611 17 -621 3z"
					/>
					<path
						d="M2327 1869 c-9 -6 -29 -33 -43 -60 l-25 -50 86 -252 c47 -139 89
		-255 93 -259 4 -4 41 34 82 84 41 50 155 188 253 305 97 118 177 221 177 229
		0 12 -46 14 -302 14 -189 0 -310 -4 -321 -11z"
					/>
				</g>
			</svg>
		</div>
	);
};
