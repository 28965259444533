import { getThunkAndSlice } from "@src/store/getThunkAndSlice";
const initialState = new Array<{
	MasterRuleId: number;
	RuleType: string;
	MasterRuleName: string;
	TierAmount?: number | null;
	IsTierAmountPercent?: boolean | null;
	ActiveTiers?: number | null;
}>();
export const { thunk: listMasterRules, slice: listMasterRulesSlice } = getThunkAndSlice<
	{
		eventId: number;
	},
	typeof initialState
>({
	initialState,
	path: "/api/AutoPricer/ListMasterRules",
});
