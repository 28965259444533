import { HeaderFiltersProps } from "@src/validation/HeaderFiltersValidation";
import { SalesFiltersProps } from "@src/validation/SalesHeaderFiltersValidation";

export const filtersEmptyValues: HeaderFiltersProps = {
	eventName: "",
	eventHeadliner: "",
	venue: "",
	startDate: "",
	endDate: "",
	smartSearch: "",
	tags: "",
	category: "",
	section: "",
	row: "",
	minQuantity: "",
	daysSinceLastUpdate: "",
	listingId: "",
	eventId: "",
	poId: "",
	poStartDate: "",
	poEndDate: "",
};

export const salesFiltersEmptyValues: SalesFiltersProps = {
	smartSearch: "",
	tags: "",
	category: "",
	salesHeadliner: "",
	venue: "",
	eventStartDate: "",
	eventEndDate: "",
	marketplace: "",
	paymentStartDate: "",
	paymentEndDate: "",
	salesStatus: "",
	salesStartDate: "",
	salesEndDate: "",

	section: "",
	row: "",
	minQuantity: "",

	invoiceId: "",
	eventId: "",
	buyerPOId: "",
};
