import styles from "@src/components/helpers.module.scss";
import React from "react";

export const ObstructedIcon = () => {
	return (
		<div className={styles.iconContainer}>
			<svg width="14" height="12" viewBox="0 0 14 12" fill="none" xmlns="http://www.w3.org/2000/svg">
				<path
					d="M7.81348 2.19106C7.54642 2.16217 7.27506 2.14562 7 2.1415C5.79804 2.14683 4.55206 2.4392 3.37268 2.99942C2.497 3.43251 1.644 4.04392 0.903198 4.79811C0.539373 5.18311 0.0750283 5.74057 0 6.33534C0.00886667 6.85056 0.561785 7.48649 0.903198 7.87259C1.59787 8.59716 2.42866 9.19107 3.37268 9.67128C3.40477 9.68686 3.43696 9.70224 3.46924 9.71742L2.59339 11.2469L3.78345 11.9503L10.2166 0.749898L9.07114 0.0498047L7.81348 2.19106ZM10.5299 2.95498L9.65575 4.46998C10.0579 4.99243 10.2966 5.63671 10.2966 6.33534C10.2966 8.07667 8.82054 9.48842 6.99914 9.48842C6.9204 9.48842 6.84409 9.47992 6.76671 9.47474L6.18822 10.4762C6.45502 10.5048 6.72477 10.5256 6.99999 10.5292C8.20309 10.5238 9.44837 10.228 10.6265 9.67128C11.5021 9.23819 12.356 8.62678 13.0968 7.87259C13.4606 7.4876 13.9249 6.93013 14 6.33534C13.9911 5.82013 13.4382 5.1842 13.0968 4.7981C12.4021 4.07353 11.5705 3.47962 10.6265 2.99939C10.5946 2.98395 10.562 2.97005 10.5299 2.95498ZM6.99915 3.18228C7.07902 3.18228 7.15826 3.18548 7.23669 3.19082L6.55908 4.36403C5.60808 4.55684 4.89453 5.36597 4.89453 6.3345C4.89453 6.5778 4.93934 6.81072 5.02185 7.02662C5.02194 7.02687 5.02175 7.02724 5.02185 7.02749L4.34252 8.20412C3.93942 7.68118 3.70165 7.03478 3.70165 6.33533C3.70166 4.59402 5.17776 3.18227 6.99915 3.18228ZM8.97045 5.65602L7.44349 8.30153C8.38937 8.1051 9.09778 7.29966 9.09778 6.3345C9.09778 6.09564 9.05011 5.86854 8.97045 5.65602Z"
					fill="#101828"
				/>
			</svg>
		</div>
	);
};
