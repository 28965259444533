import styles from "@src/components/helpers.module.scss";
import React, { CSSProperties } from "react";

interface IXIcon {
	color?: CSSProperties["color"];
	size?: number;
}

export const XIcon: React.FC<IXIcon> = ({ color, size }) => {
	return (
		<div className={styles.iconContainer}>
			<svg
				style={{ cursor: "pointer" }}
				width={size ?? 10}
				height={size ?? 10}
				viewBox="0 0 10 10"
				fill="none"
				xmlns="http://www.w3.org/2000/svg">
				<path
					d="M9.85403 9.14604C9.90048 9.1925 9.93733 9.24765 9.96247 9.30834C9.98762 9.36904 10.0006 9.43409 10.0006 9.49979C10.0006 9.56549 9.98762 9.63054 9.96247 9.69124C9.93733 9.75194 9.90048 9.80709 9.85403 9.85354C9.80757 9.9 9.75242 9.93684 9.69173 9.96199C9.63103 9.98713 9.56598 10.0001 9.50028 10.0001C9.43458 10.0001 9.36953 9.98713 9.30883 9.96199C9.24813 9.93684 9.19298 9.9 9.14653 9.85354L5.00028 5.70666L0.854028 9.85354C0.760208 9.94736 0.63296 10.0001 0.500278 10.0001C0.367596 10.0001 0.240348 9.94736 0.146528 9.85354C0.0527077 9.75972 2.61548e-09 9.63247 0 9.49979C-2.61548e-09 9.36711 0.0527077 9.23986 0.146528 9.14604L4.2934 4.99979L0.146528 0.85354C0.0527077 0.759719 -9.88558e-10 0.632472 0 0.49979C9.88558e-10 0.367108 0.0527077 0.23986 0.146528 0.14604C0.240348 0.0522194 0.367596 -0.00048828 0.500278 -0.000488281C0.63296 -0.000488282 0.760208 0.0522194 0.854028 0.14604L5.00028 4.29291L9.14653 0.14604C9.24035 0.0522194 9.3676 -0.000488284 9.50028 -0.000488281C9.63296 -0.000488279 9.76021 0.0522194 9.85403 0.14604C9.94785 0.23986 10.0006 0.367108 10.0006 0.49979C10.0006 0.632472 9.94785 0.759719 9.85403 0.85354L5.70715 4.99979L9.85403 9.14604Z"
					fill={color ?? "white"}
				/>
			</svg>
		</div>
	);
};
