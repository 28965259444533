import styles from "@src/components/helpers.module.scss";
import React from "react";

interface IDrawerIconProps {
	onClick: () => void;
	isExpanded: boolean;
}

export const DrawerIcon: React.FC<IDrawerIconProps> = ({ onClick, isExpanded }) => {
	const handleDrawerClick = (e: React.MouseEvent<HTMLDivElement>) => {
		e.stopPropagation();
		onClick();
	};

	return (
		<div
			aria-hidden
			className={styles.iconContainer}
			onClick={handleDrawerClick}
			style={{
				marginLeft: "auto",
				transform: isExpanded ? undefined : "rotate(180deg)",
				transition: "200ms",
			}}>
			<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
				<path
					d="M11.5284 10.4717C11.7888 10.7321 12.2109 10.7321 12.4712 10.4717C12.7316 10.2114 12.7316 9.78927 12.4712 9.52892L8.47124 5.52892C8.21089 5.26857 7.78878 5.26857 7.52843 5.52892L3.52843 9.52892C3.26808 9.78927 3.26808 10.2114 3.52843 10.4717C3.78878 10.7321 4.21089 10.7321 4.47124 10.4717L7.99984 6.94313L11.5284 10.4717Z"
					fill="#C0C0C0"
				/>
			</svg>
		</div>
	);
};
