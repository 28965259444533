import React, { Suspense } from "react";
import { useRoutes } from "react-router-dom";
import { paths } from "./paths";

import { Loader } from "@src/components/loader/Loader";
import { Home } from "@src/pages/home/Home";

const LazyAutologin = React.lazy(() => import("@src/pages/autologin/AutoLogin").then((x) => ({ default: x.AutoLogin })));
const LazyLogin = React.lazy(() => import("@src/pages/login/Login").then((x) => ({ default: x.Login })));
const LazySignup = React.lazy(() => import("@src/pages/signup/Signup").then((x) => ({ default: x.Signup })));
const LazyDashboard = React.lazy(() =>
	import("@src/pages/home/children/dashboard/Dashboard").then((x) => ({ default: x.Dashboard })),
);
const LazyPricing = React.lazy(() => import("@src/pages/home/children/pricing/Pricing").then((x) => ({ default: x.Pricing })));
const LazyReports = React.lazy(() => import("@src/pages/home/children/reports/Reports").then((x) => ({ default: x.Reports })));
const LazySales = React.lazy(() => import("@src/pages/home/children/sales/Sales").then((x) => ({ default: x.Sales })));
const LazyPurchasing = React.lazy(() =>
	import("@src/pages/home/children/purchasing/Purchasing").then((x) => ({ default: x.Purchasing })),
);
const LazyNotification = React.lazy(() =>
	import("@src/pages/home/children/notification/Notification").then((x) => ({ default: x.Notification })),
);
const LazySettings = React.lazy(() =>
	import("@src/pages/home/children/settings/Settings").then((x) => ({ default: x.Settings })),
);

export const Routes = () => {
	return useRoutes([
		{
			path: paths.autologin,
			element: (
				<Suspense fallback={<Loader controlSize="xxl" />}>
					<LazyAutologin />
				</Suspense>
			),
		},
		{
			path: paths.login,
			element: (
				<Suspense fallback={<Loader controlSize="xxl" />}>
					<LazyLogin />
				</Suspense>
			),
		},
		{
			path: paths.signup,
			element: (
				<Suspense fallback={<Loader controlSize="xxl" />}>
					<LazySignup />
				</Suspense>
			),
		},
		{
			path: paths.home,
			element: <Home />,
			children: [
				{
					index: true,
					element: (
						<Suspense fallback={<Loader controlSize="xxl" />}>
							<LazyPricing />
						</Suspense>
					),
				},
				{
					element: (
						<Suspense fallback={<Loader controlSize="xxl" />}>
							<LazyDashboard />
						</Suspense>
					),
					path: paths.dashboard,
				},
				{
					element: (
						<Suspense fallback={<Loader controlSize="xxl" />}>
							<LazyPricing />
						</Suspense>
					),
					path: paths.pricing,
				},
				{
					element: (
						<Suspense fallback={<Loader controlSize="xxl" />}>
							<LazyReports />
						</Suspense>
					),
					path: paths.reports,
				},
				{
					element: (
						<Suspense fallback={<Loader controlSize="xxl" />}>
							<LazySales />
						</Suspense>
					),
					path: paths.sales,
				},
				{
					element: (
						<Suspense fallback={<Loader controlSize="xxl" />}>
							<LazyPurchasing />
						</Suspense>
					),
					path: paths.purchasing,
				},
				{
					element: (
						<Suspense fallback={<Loader controlSize="xxl" />}>
							<LazyNotification />
						</Suspense>
					),
					path: paths.notification,
				},
				{
					element: (
						<Suspense fallback={<Loader controlSize="xxl" />}>
							<LazySettings />
						</Suspense>
					),
					path: paths.settings,
				},
			],
		},
	]);
};
