import styles from "@src/components/helpers.module.scss";
import React from "react";

export const DuplicateIcon = () => {
	return (
		<div className={styles.iconContainer}>
			<svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
				<path
					d="M4.89941 2.4499C4.89941 2.17143 5.01004 1.90435 5.20695 1.70744C5.40386 1.51053 5.67094 1.3999 5.94941 1.3999H8.66471C8.9431 1.40015 9.20999 1.51093 9.40671 1.7079L11.5921 3.8919C11.6897 3.98953 11.767 4.10545 11.8198 4.23301C11.8725 4.36057 11.8996 4.49727 11.8994 4.6353V8.7499C11.8994 9.02838 11.7888 9.29545 11.5919 9.49236C11.395 9.68928 11.1279 9.7999 10.8494 9.7999H10.1494V7.4346C10.1493 6.87769 9.92797 6.34364 9.53411 5.9499L7.34941 3.7652C6.95568 3.37135 6.42162 3.15002 5.86471 3.1499H4.89941V2.4499Z"
					fill="#1F1F1F"
				/>
				<path
					d="M3.14961 4.19971C2.87113 4.19971 2.60406 4.31033 2.40715 4.50725C2.21023 4.70416 2.09961 4.97123 2.09961 5.24971V11.5497C2.09961 11.8282 2.21023 12.0953 2.40715 12.2922C2.60406 12.4891 2.87113 12.5997 3.14961 12.5997H8.04961C8.32809 12.5997 8.59516 12.4891 8.79207 12.2922C8.98898 12.0953 9.09961 11.8282 9.09961 11.5497V7.43441C9.09936 7.15602 8.98858 6.88913 8.79161 6.69241L6.60761 4.50701C6.50998 4.40944 6.39406 4.33207 6.2665 4.27934C6.13894 4.22661 6.00224 4.19955 5.86421 4.19971H3.14961Z"
					fill="#1F1F1F"
				/>
			</svg>
		</div>
	);
};
