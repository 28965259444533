import helperStyles from "@src/components/helpers.module.scss";
import React from "react";

export interface IHoldSize {
	size?: string;
}

export const HoldHide: React.FC<IHoldSize> = ({ size }) => {
	return (
		<div className={helperStyles.iconContainer}>
			<svg width={size ?? "100%"} height={size ?? "100%"} viewBox="0 0 10 11" fill="none" xmlns="http://www.w3.org/2000/svg">
				<path
					d="M5 0.5C2.28571 0.5 0 2.78571 0 5.5C0 8.21429 2.28571 10.5 5 10.5C7.71429 10.5 10 8.21429 10 5.5C10 2.78571 7.71429 0.5 5 0.5ZM6.63821 7.64286L4.64286 5.64643V2.28571H5.35714V5.35071L7.14286 7.13786L6.63821 7.64286Z"
					fill="#5B646F"
				/>
			</svg>
		</div>
	);
};
