import React from "react";
import styles from "@src/components/helpers.module.scss";
import { IIconActiveProps } from "./helpers";

export const SalesIcon: React.FC<IIconActiveProps> = ({ isActive }) => {
	return (
		<div className={styles.iconContainer}>
			<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
				<path
					fillRule="evenodd"
					clipRule="evenodd"
					d="M12.9998 1.66699C12.6462 1.66699 12.3071 1.80747 12.057 2.05752C11.807 2.30757 11.6665 2.6467 11.6665 3.00033V6.66699H7.99984C7.64622 6.66699 7.30708 6.80746 7.05703 7.05752C6.80698 7.30757 6.6665 7.64671 6.6665 8.00032V12.5003H2.99984C2.64622 12.5003 2.30708 12.6408 2.05703 12.8908C1.80698 13.1409 1.6665 13.48 1.6665 13.8337V17.0003C1.6665 17.3539 1.80698 17.6931 2.05703 17.9431C2.30708 18.1932 2.64622 18.3337 2.99984 18.3337H16.9998C17.3535 18.3337 17.6926 18.1932 17.9426 17.9431C18.1927 17.6931 18.3332 17.3539 18.3332 17.0003V3.00033C18.3332 2.6467 18.1927 2.30757 17.9426 2.05752C17.6926 1.80747 17.3535 1.66699 16.9998 1.66699H12.9998ZM8.33317 8.33366H11.6665V16.667H8.33317V8.33366ZM16.6665 16.667H13.3332V3.33366H16.6665V16.667ZM6.6665 14.167V16.667H3.33317V14.167H6.6665Z"
					fill={isActive ? "var(--activeIcon)" : "var(--inactiveIcon)"}
				/>
			</svg>
		</div>
	);
};
