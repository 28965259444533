import React from "react";
import styles from "./GridSection.module.scss";

interface IGridSection {
	headline?: string;
	children: React.ReactNode;
	numberOfItems?: number;
}

export const GridSection: React.FC<IGridSection> = ({ headline, children, numberOfItems = 4 }) => {
	return (
		<div className={styles.root}>
			<div className={styles.gridContainerHeadline}>{headline}</div>
			<div className={styles.gridContainer} style={{ "--number-of-items": numberOfItems } as React.CSSProperties}>
				{children}
			</div>
		</div>
	);
};
