import { DrawerIcon } from "@src/assets/general-icons";
import { IIconActiveProps } from "@src/assets/sidebar-Icons";
import React, { useLayoutEffect, useRef, useState } from "react";
import { ISubItem } from "./Items";
import styles from "./Sidebar.module.scss";
import { SidebarTooltip } from "./SidebarTooltip";

interface ISidebarItemProps {
	label: string;
	isActive: boolean;
	Icon: React.FC<IIconActiveProps>;
	navigateToRoute: () => void;
	subItems?: Array<ISubItem>;
	isExpanded: boolean;
	onClick?: () => void;
}

export const SidebarItem: React.FC<ISidebarItemProps> = ({
	navigateToRoute,
	Icon,
	isActive,
	label,
	subItems,
	isExpanded,
	onClick,
}) => {
	const containerRef = useRef<HTMLDivElement>(null);

	const [isExpandedInner, setIsExpandedInner] = useState(true);
	const [maxHeight, setMaxHeight] = useState<number>();

	const handleSidebarClick = () => onClick?.();

	const mappedSubItems = subItems?.map((i) => {
		return (
			<div className={styles.itemContainer} onClick={() => i.onClick && i.onClick()} key={i.id}>
				<div className={styles.itemLabel}>{i.label}</div>
			</div>
		);
	});

	const toggleExpanded = () => setIsExpandedInner((prev) => !prev);

	useLayoutEffect(() => {
		setMaxHeight(containerRef.current?.scrollHeight);
		setIsExpandedInner(false);
	}, [isExpanded]);

	return (
		<div
			onClick={handleSidebarClick}
			className={styles.sectionContainer}
			id={label}
			style={{ maxHeight: isExpandedInner ? `${maxHeight}px` : "40px" }}
			ref={containerRef}
		>
			<SidebarTooltip isExpanded={isExpanded} isActive={isActive} label={label} subItems={subItems} />
			<div className={styles.itemContainer} data-is-active={isActive} onClick={navigateToRoute}>
				<Icon isActive={isActive} />
				<div className={styles.itemLabel} data-is-active={isActive}>
					{label}
				</div>
				{subItems ? <DrawerIcon isExpanded={isExpandedInner} onClick={toggleExpanded}></DrawerIcon> : null}
			</div>
			{subItems && isExpanded ? (
				<div className={styles.subItemsContainer}>
					<div className={styles.subItemsContainerInner}>{mappedSubItems}</div>
				</div>
			) : null}
		</div>
	);
};
