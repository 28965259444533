import helperStyles from "@src/components/helpers.module.scss";
import React from "react";

interface ICheckIcon {
	onClick?: () => void;
}
export const CheckIcon: React.FC<ICheckIcon> = ({ onClick }) => {
	return (
		<div className={helperStyles.iconContainer}>
			<svg
				style={{ cursor: "pointer" }}
				onClick={onClick}
				width="14"
				height="14"
				viewBox="0 0 14 14"
				fill="none"
				xmlns="http://www.w3.org/2000/svg">
				<path d="M13 2L4.75 11L1 6.90909" stroke="white" strokeWidth="1.66667" strokeLinecap="round" strokeLinejoin="round" />
			</svg>
		</div>
	);
};
