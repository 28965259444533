import { getThunkAndSlice } from "@src/store/getThunkAndSlice";

const initialState = new Array<{
	Is_System: boolean;
	Rule_ID: number;
	Rule_Name: string;
}>();

export const { thunk: listSavedSharingRules, slice: listSavedSharingRulesSlice } = getThunkAndSlice<
	{
		venueId: number;
	},
	typeof initialState
>({
	initialState,
	path: "/api/AutoPricer/ListSavedSharingRules",
});
