import { getThunkAndSlice } from "@src/store/getThunkAndSlice";
import { pricingActions, RootState } from "..";

const initialState = false;

export const { thunk: holdTickets, slice: holdTicketsSlice } = getThunkAndSlice<
	{
		ItemIds: Array<number>;
		Comments: string;
		ExpirationDate: string;
	},
	typeof initialState
>({
	initialState,
	path: "/api/Pricing/HoldTickets",
	method: "post",
	onAfterRequest: async (data, params, state, dispatch) => {
		const rootState = state as RootState;

		const newData = rootState.pricing.eventInventoryAll.data.map((event) => {
			if (!params.ItemIds.includes(event.Item_ID)) return event;
			return {
				...event,
				On_Hold_Comments: params.Comments ?? "",
				Consignor_Hold: true,
			};
		});
		dispatch(pricingActions.updateInventoryAll({ data: newData }));
		return data;
	},
});
