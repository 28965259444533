import React, { CSSProperties, JSX } from "react";
import { Tooltip } from "react-tooltip";
import styles from "./Tooltip.module.scss";

interface IMyTooltipProps extends Omit<React.ComponentProps<typeof Tooltip>, "anchorSelect"> {
	element: JSX.Element;
	elementStyle?: CSSProperties;
	renderElementChildren?: boolean;
	id: string;
	popup?: React.ReactNode;
	popupStyle?: CSSProperties;
}

export const MyTooltip: React.FC<IMyTooltipProps> = ({
	element,
	elementStyle,
	renderElementChildren,
	popup,
	popupStyle,
	id,
	...rest
}) => {
	const idWithSuffix = id + "-tooltip";

	const elementWithId = React.cloneElement(element, {
		id: idWithSuffix,
		style: elementStyle,
	});

	let elem = element;
	if (renderElementChildren) {
		elem = elem.props.children;
	}

	return (
		<>
			{elementWithId}
			<Tooltip className={styles.main} style={popupStyle} opacity={1} {...rest} anchorSelect={`#${idWithSuffix}`}>
				{popup ?? elem}
			</Tooltip>
		</>
	);
};
