import * as yup from "yup";

export type HeaderFiltersProps = yup.InferType<typeof HeaderFiltersValidation>;

export const HeaderFiltersValidation = yup.object().shape({
	companyName: yup.string().optional(),
	eventName: yup.string(),
	eventHeadliner: yup.string(),
	venue: yup.string(),
	startDate: yup.string(),
	endDate: yup.string(),
	smartSearch: yup.string(),
	tags: yup.string(),
	category: yup.string(),
	section: yup.string(),
	row: yup.string(),
	minQuantity: yup.string(),
	daysSinceLastUpdate: yup.string(),
	listingId: yup.string(),
	eventId: yup.string(),
	poId: yup.string(),
	poStartDate: yup.string(),
	poEndDate: yup.string(),
});
