import React, { useState } from "react";
import styles from "./ApStatus.module.scss";
import { EventInventoryRecord } from "@src/store/pricing/queryEventInventoryAllSlice";

interface IApStatusType {
	row: EventInventoryRecord;
}

export const ApStatus: React.FC<IApStatusType> = ({ row }) => {
	const options = [
		{ text: "Hit Ceiling", color: "#54C339" },
		{ text: "Hit Floor", color: "#FF2D5F" },
		{ text: "Active", color: "#3F7BF3" },
		{ text: "", color: "" },
	];
	const [item] = useState(() => {
		if (!row.Is_AutoPriced) return options[3];
		if (row.Is_At_Ceiling) return options[0];
		if (row.Is_At_Floor) return options[1];
		return options[2];
	});
	return item.text ? (
		<div className={styles.main} style={{ color: item.color }}>
			<div className={styles.circle} />
			<div className={styles.text}>{item.text}</div>
		</div>
	) : (
		<></>
	);
};
