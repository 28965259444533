import React from "react";
import styles from "@src/components/helpers.module.scss";

export const MatchedIcon: React.FC = () => {
	return (
		<div className={styles.iconContainer}>
			<svg width="12" height="13" viewBox="0 0 12 13" fill="none" xmlns="http://www.w3.org/2000/svg">
				<path
					d="M5.99935 12.3332C2.7776 12.3332 0.166016 9.72159 0.166016 6.49984C0.166016 3.27809 2.7776 0.666504 5.99935 0.666504C9.2211 0.666504 11.8327 3.27809 11.8327 6.49984C11.8327 9.72159 9.2211 12.3332 5.99935 12.3332ZM5.31277 7.74817L3.69985 6.13409L3.08268 6.75125L4.9021 8.57184C5.01149 8.6812 5.15984 8.74263 5.31452 8.74263C5.4692 8.74263 5.61754 8.6812 5.72693 8.57184L9.19893 5.101L8.57943 4.4815L5.31277 7.74817Z"
					fill="#0178AE"
				/>
			</svg>
		</div>
	);
};
