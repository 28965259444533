import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AxiosError } from "axios";
import { ApiProvider } from "../ApiProvider";

interface Tag {
	Tag: string;
}

interface ListTagsState {
	tags: Array<Tag>;
	loading: boolean;
	error: string | null;
}

const initialState: ListTagsState = {
	tags: [],
	loading: false,
	error: null,
};

export const getListTags = createAsyncThunk<Array<Tag>, undefined, { rejectValue: string }>(
	"listTags/getListTags",
	async (_, { rejectWithValue }) => {
		try {
			const response = await ApiProvider.default.get<Array<Tag>>("/api/System/ListAllTags");
			return response;
		} catch (error) {
			if (error instanceof AxiosError) return rejectWithValue(error.message);
			return rejectWithValue("Unknown error");
		}
	},
);

export const listTagsSlice = createSlice({
	name: "listTags",
	initialState,
	reducers: {},
	extraReducers: (builder) => {
		builder
			.addCase(getListTags.pending, (state) => {
				state.loading = true;
				state.error = null;
			})
			.addCase(getListTags.fulfilled, (state, action: PayloadAction<Array<Tag>>) => {
				state.loading = false;
				state.tags = action.payload;
			})
			.addCase(getListTags.rejected, (state, action) => {
				state.loading = false;
				state.error = action.payload ?? "Failed to fetch tags";
			});
	},
});
