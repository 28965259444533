import styles from "@src/components/helpers.module.scss";
import React from "react";

export const InstantDelivery = () => {
	return (
		<div className={styles.iconContainer}>
			<svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
				<path d="M12 5.23529H9.14286V1H4.85714V5.23529H2L7 10.1765L12 5.23529ZM2 11.5882V13H12V11.5882H2Z" fill="#101828" />
			</svg>
		</div>
	);
};
