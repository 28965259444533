import { ArrowDownIcon } from "@src/assets/general-icons";
import helperStyles from "@src/components/helpers.module.scss";
import React, { ReactElement, useCallback, useEffect, useRef, useState } from "react";
import { Button, ButtonVariant } from "../button/Button";
import styles from "./Dropdown.module.scss";
import { clx } from "../utils/stringUtils";

export interface IItems {
	preventClose?: boolean;
	id: number;
	label: string | ReactElement;
	value?: string | number;
	isDisabled?: boolean;
	onClick?: (value: IItems["value"], close: () => void) => void;
}

interface IDropdown extends React.ComponentProps<"div"> {
	label?: string;
	isDisabled?: boolean;
	containerWidth?: string;
	minHolderWidth?: number;
	buttonText?: React.ReactNode;
	items: Array<IItems>;
	dynamicText?: boolean;
	controlSize?: "m" | "sm";
	theme?: "primary" | "secondary";
	onItemSelect?: (item: IItems) => void;
}

export const Dropdown: React.FC<IDropdown> = ({
	buttonText,
	items,
	minHolderWidth,
	containerWidth,
	label,
	dynamicText = false,
	isDisabled = false,
	controlSize = "m",
	theme = "primary",
	onItemSelect,
	...rest
}) => {
	const [open, setOpen] = useState(false);
	const [selected, setSelected] = useState(label ?? items?.[0].label);
	const buttonRef = useRef<HTMLDivElement>(null);

	const toggleOpen = () => !isDisabled && setOpen((prev) => !prev);

	const mappedItems = items?.map((item) => {
		const { id, value, label, onClick, preventClose } = item;
		return (
			<div
				className={clx({ [styles.item]: true, [styles.disabled]: !!item.isDisabled })}
				aria-hidden="true"
				onClick={() => {
					onClick?.(value, toggleOpen);
					onItemSelect?.(item);
					if (dynamicText) setSelected(label);
					if (preventClose) return;
					toggleOpen();
				}}
				key={id}>
				{label}
			</div>
		);
	});

	const handleOutsideClick = useCallback((event: MouseEvent) => {
		if (buttonRef.current && !buttonRef.current.contains(event.target as Node)) {
			setOpen(false);
		}
	}, []);

	useEffect(() => {
		if (open) {
			document.addEventListener("mousedown", handleOutsideClick);
		}
		return () => {
			document.removeEventListener("mousedown", handleOutsideClick);
		};
	}, [open, handleOutsideClick]);

	useEffect(() => {
		setSelected(label ?? items?.[0].label);
	}, [items.map((item) => item.label).join("")]);

	return (
		<div className={styles.main} {...rest}>
			{label && <div className={styles.label}>{label}</div>}
			<div
				style={{ width: containerWidth ?? undefined }}
				ref={buttonRef}
				className={clx(styles.dropdownContainer, styles[`${theme}Theme`])}>
				<Button
					style={{ width: containerWidth || "100%" }}
					onClick={toggleOpen}
					disabled={isDisabled}
					controlSize={controlSize}
					variant={ButtonVariant.Tertiary}
					className={styles.dropdownButtonContainer}>
					<div className={`${styles.dropdownButton} ${helperStyles.center}`}>
						<div>{dynamicText ? selected : (buttonText ?? "Default button")}</div>
						<div>
							<ArrowDownIcon />
						</div>
					</div>
				</Button>
				{open && (
					<div
						style={{ minWidth: minHolderWidth ? `${minHolderWidth}px` : "100%" }}
						className={clx(styles.itemsContainer, styles[`${theme}Theme`])}>
						{mappedItems ?? (
							<div className={styles.item} aria-hidden="true">
								"No options"
							</div>
						)}
					</div>
				)}
			</div>
		</div>
	);
};
