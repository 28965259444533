// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Switch-module_main___QpaET {
  display: flex;
  gap: 5px;
  user-select: none;
}
.Switch-module_main___QpaET .Switch-module_label___eOLs4 {
  white-space: nowrap;
  opacity: 0.6;
  font-weight: 500;
  font-size: 10px;
  display: flex;
  align-items: center;
  color: #1f1f1f;
}
.Switch-module_main___QpaET .Switch-module_label___eOLs4.Switch-module_active___QP83_ {
  opacity: 1;
}`, "",{"version":3,"sources":["webpack://./src/components/switch/Switch.module.scss"],"names":[],"mappings":"AAAA;EACC,aAAA;EACA,QAAA;EACA,iBAAA;AACD;AACC;EACC,mBAAA;EACA,YAAA;EACA,gBAAA;EACA,eAAA;EACA,aAAA;EACA,mBAAA;EACA,cAAA;AACF;AACE;EACC,UAAA;AACH","sourcesContent":[".main {\r\n\tdisplay: flex;\r\n\tgap: 5px;\r\n\tuser-select: none;\r\n\r\n\t.label {\r\n\t\twhite-space: nowrap;\r\n\t\topacity: 0.6;\r\n\t\tfont-weight: 500;\r\n\t\tfont-size: 10px;\r\n\t\tdisplay: flex;\r\n\t\talign-items: center;\r\n\t\tcolor: #1f1f1f;\r\n\r\n\t\t&.active {\r\n\t\t\topacity: 1;\r\n\t\t}\r\n\t}\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"main": `Switch-module_main___QpaET`,
	"label": `Switch-module_label___eOLs4`,
	"active": `Switch-module_active___QP83_`
};
export default ___CSS_LOADER_EXPORT___;
