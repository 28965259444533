import { Button, ButtonVariant } from "@src/components/button/Button";
import { Dropdown } from "@src/components/dropdown/Dropdown";
import { GridSection } from "@src/components/filtersModal/GridSection";
import { DateInput } from "@src/components/input/DateInput";
import { Input } from "@src/components/input/Input";
import { SelectYup } from "@src/components/select/SelectYup";
import { Switch } from "@src/components/switch/Switch";
import { filtersEmptyValues } from "@src/components/utils/hookFormUtils";
import { MixpanelCategory, mixpanelTrack } from "@src/components/utils/mixPanelUtils";
import { systemActions } from "@src/store";
import { useAppSelector, useAppDispatch } from "@src/store/hooks";
import { HeaderFiltersProps } from "@src/validation/HeaderFiltersValidation";
import React, { useEffect } from "react";
import { Control, UseFormReset, UseFormSetValue } from "react-hook-form";
import { useSearchParams } from "react-router-dom";
import styles from "./PricingFilters.module.scss";

interface ICategoriesGeneric {
	label: string;
	value: string;
}

interface IAllOPtions {
	categoryOptions: Array<ICategoriesGeneric>;
	headlinerOptions: Array<ICategoriesGeneric>;
	venueOptions: Array<ICategoriesGeneric>;
	smartSearchOptions: Array<ICategoriesGeneric>;
}

interface IAllFilters extends IAllOPtions {
	control: Control;
	setValue: UseFormSetValue<HeaderFiltersProps>;
	reset: UseFormReset<HeaderFiltersProps>;
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	savedFilters: any;
}

export const PricingFilters: React.FC<IAllFilters> = (props) => {
	const { control, savedFilters, setValue, reset, categoryOptions, headlinerOptions, venueOptions, smartSearchOptions } = props;

	const [params, setParams] = useSearchParams();

	const switches = {
		showSold: params.get("showSold") === "true",
		showParking: (params.get("showParking") ?? "true") === "true",
		showCanceled: params.get("showCanceled") === "true",
	};

	const savedFilterOptions = (savedFilters ?? []).map((item: HeaderFiltersProps, index: number) => ({
		id: index + 1,
		label: `Saved Filters ${index + 1}`,
		value: item,
		onClick: (value: HeaderFiltersProps) => {
			reset(filtersEmptyValues);
			const values = Object.entries(value) as Array<[keyof HeaderFiltersProps, string]>;
			values.forEach(
				([k, v]) =>
					v &&
					setValue(k, v, {
						shouldDirty: true,
					}),
			);
		},
	}));

	const filterOptionsWithReset = [
		{
			id: 0,
			label: "Select",
			onClick: reset,
		},
		...savedFilterOptions,
	];

	const handleSwitchChange = (switchName: "showSold" | "showParking" | "showCanceled") => {
		const newValue = !switches[switchName];
		params.set(switchName, String(newValue));
		setParams(params);
		mixpanelTrack(MixpanelCategory.FILTERS_SWITCH_CLICKED, {
			switchName,
			value: newValue,
		});
	};

	const { data: allTags, loading: tagsLoading } = useAppSelector((state) => state.system.allTags);
	const dispatch = useAppDispatch();

	const tagOptions = allTags.map((i) => Object.values(i)).map(([x]) => ({ label: x, value: x }));

	useEffect(() => {
		if (allTags.length) return;
		dispatch(systemActions.listAllTags({}));
	}, []);

	return (
		<div className={styles.root}>
			<div className={styles.savedFiltersContainer}>
				<GridSection numberOfItems={2} headline="Saved Filters">
					<Dropdown dynamicText isDisabled={false} items={filterOptionsWithReset} />
				</GridSection>
			</div>
			<div className={styles.content}>
				<div className={styles.inner}>
					<GridSection headline="Smart Search">
						<SelectYup
							name="smartSearch"
							placeholder="Event name, Venue, etc."
							control={control}
							options={smartSearchOptions}
						/>
						<SelectYup name="tags" placeholder="Tags" isLoading={tagsLoading} control={control} options={tagOptions} />
					</GridSection>
					<GridSection headline="Event">
						<SelectYup name="category" placeholder="Category" control={control} options={categoryOptions} />
						<SelectYup name="eventHeadliner" placeholder="Headliner" control={control} options={headlinerOptions} />
						<SelectYup name="venue" placeholder="Venue" control={control} options={venueOptions} />
						<div className={styles.subItems}>
							<DateInput control={control} name="startDate" placeholder="Start Date" />
							<DateInput control={control} name="endDate" placeholder="End Date" />
						</div>
					</GridSection>
					<GridSection headline="Ticket">
						<Input control={control} name="section" placeholder="Section" />
						<Input control={control} name="row" placeholder="Row" />
						<Input control={control} type="number" name="minQuantity" placeholder="Min. Quantity" />
						<Input control={control} type="number" name="daysSinceLastUpdate" placeholder="Days Since Last Update" />
					</GridSection>
					<GridSection headline="Advanced">
						<Input name="listingId" placeholder="Listing ID" control={control} />
						<Input name="eventId" placeholder="Event ID" control={control} />
						<Input name="poId" placeholder="PO ID" control={control} />
						<div className={styles.subItems}>
							<DateInput control={control} name="poStartDate" placeholder="PO Start Date" />
							<DateInput control={control} name="poEndDate" placeholder="PO End Date" />
						</div>
					</GridSection>
					<div className={styles.switches}>
						<Button
							style={{
								padding: "0px 9px",
								fontSize: "11px",
							}}
							controlSize="sm"
							variant={ButtonVariant.Tertiary}>
							<Switch checked={switches.showSold} onChange={() => handleSwitchChange("showSold")} label="Show Sold" />
						</Button>
						<Button
							style={{
								padding: "0px 9px",
								fontSize: "11px",
							}}
							controlSize="sm"
							variant={ButtonVariant.Tertiary}>
							<Switch
								checked={switches.showParking}
								onChange={() => handleSwitchChange("showParking")}
								label="Show Parking"
							/>
						</Button>
						<Button
							style={{
								padding: "0px 9px",
								fontSize: "11px",
							}}
							controlSize="sm"
							variant={ButtonVariant.Tertiary}>
							<Switch
								checked={switches.showCanceled}
								onChange={() => handleSwitchChange("showCanceled")}
								label="Show Canceled"
							/>
						</Button>
					</div>
				</div>
			</div>
		</div>
	);
};
