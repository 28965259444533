import helperStyles from "@src/components/helpers.module.scss";
import React from "react";

export const CalendarIcon = () => {
	return (
		<div className={helperStyles.iconContainer}>
			<svg width="12" height="13" viewBox="0 0 12 13" fill="none" xmlns="http://www.w3.org/2000/svg">
				<path
					d="M0.166992 5.25033C0.166992 4.15016 0.166992 3.60066 0.508826 3.25883C0.850659 2.91699 1.40016 2.91699 2.50033 2.91699H9.50033C10.6005 2.91699 11.15 2.91699 11.4918 3.25883C11.8337 3.60066 11.8337 4.15016 11.8337 5.25033C11.8337 5.52508 11.8337 5.66274 11.7485 5.74849C11.6627 5.83366 11.5245 5.83366 11.2503 5.83366H0.750326C0.475576 5.83366 0.337909 5.83366 0.252159 5.74849C0.166992 5.66274 0.166992 5.52449 0.166992 5.25033ZM0.166992 10.5003C0.166992 11.6005 0.166992 12.15 0.508826 12.4918C0.850659 12.8337 1.40016 12.8337 2.50033 12.8337H9.50033C10.6005 12.8337 11.15 12.8337 11.4918 12.4918C11.8337 12.15 11.8337 11.6005 11.8337 10.5003V7.58366C11.8337 7.30891 11.8337 7.17124 11.7485 7.08549C11.6627 7.00033 11.5245 7.00033 11.2503 7.00033H0.750326C0.475576 7.00033 0.337909 7.00033 0.252159 7.08549C0.166992 7.17124 0.166992 7.30949 0.166992 7.58366V10.5003Z"
					fill="#D8D5D5"
				/>
				<path d="M3.08398 1.75V3.5M8.91732 1.75V3.5" stroke="#D8D5D5" strokeWidth="2" strokeLinecap="round" />
			</svg>
		</div>
	);
};
