import { Header } from "@src/components/header/Header";
import { Sidebar } from "@src/components/sidebar/Sidebar";
import { useAuth } from "@src/routes/authProvider";
import { paths } from "@src/routes/paths";
import { settingsActions } from "@src/store";
import React from "react";
import { Navigate, Outlet } from "react-router-dom";

import styles from "./Home.module.scss";
import { useAppDispatch, useAppSelector } from "@src/store/hooks";

export const Home: React.FC = () => {
	const appDispatch = useAppDispatch();
	const portalSidebarExpanded = useAppSelector((state) => state.settings.portalSidebarExpanded);
	const portalSidebarIsHidden = useAppSelector((state) => state.settings.portalSidebarIsHidden);

	const { isAuthenticated } = useAuth();
	if (!isAuthenticated && location.pathname !== paths.login) {
		return <Navigate to={paths.login} replace />;
	}

	const toggleExpanded = () => {
		appDispatch(settingsActions.setPortalSidebarExpanded(!portalSidebarExpanded));
	};
	return (
		<div className={styles.main} data-is-expanded={portalSidebarExpanded} data-is-side-bar-hidden={portalSidebarIsHidden}>
			<Header />
			<Sidebar isExpanded={portalSidebarExpanded} isHidden={portalSidebarIsHidden} toggleExpanded={toggleExpanded} />
			<div className={styles.childRoutesContainer}>
				<main>
					<Outlet />
				</main>
			</div>
		</div>
	);
};
