import { getThunkAndSlice } from "@src/store/getThunkAndSlice";

interface TItemTagsInitialState {
	tagIds: Array<number>;
}

const initialState = false;

export const { thunk: removeEventTag, slice: removeEventTagSlice } = getThunkAndSlice<TItemTagsInitialState, typeof initialState>(
	{
		initialState,
		method: "post",
		path: "/api/System/RemoveEventTags",
	},
);
