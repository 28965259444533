import helperStyles from "@src/components/helpers.module.scss";
import React from "react";

export const BarcodeIcon: React.FC = () => {
	return (
		<div className={helperStyles.iconContainer}>
			<svg width="14" height="10" viewBox="0 0 14 10" fill="none" xmlns="http://www.w3.org/2000/svg">
				<path
					d="M0.650391 9.08366V0.916992H1.81706V9.08366H0.650391ZM2.40039 9.08366V0.916992H3.56706V9.08366H2.40039ZM4.15039 9.08366V0.916992H4.73372V9.08366H4.15039ZM5.90039 9.08366V0.916992H7.06706V9.08366H5.90039ZM7.65039 9.08366V0.916992H9.40039V9.08366H7.65039ZM9.98372 9.08366V0.916992H10.5671V9.08366H9.98372ZM11.7337 9.08366V0.916992H13.4837V9.08366H11.7337Z"
					fill="#101828"
				/>
			</svg>
		</div>
	);
};
