import { yupResolver } from "@hookform/resolvers/yup";
import { FilterIcon, ResetIcon } from "@src/assets/general-icons";
import { Button, ButtonVariant } from "@src/components/button/Button";
import { Input } from "@src/components/input/Input";
import { eventActions, pricingActions, settingsActions } from "@src/store";
import { useAppDispatch, useAppSelector } from "@src/store/hooks";
import { HeaderFiltersProps, HeaderFiltersValidation } from "@src/validation/HeaderFiltersValidation";
import React, { useEffect, useRef, useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { useLocation, useSearchParams } from "react-router-dom";
import { AllFilters } from "../allFilters/AllFilters";
import { DateInput } from "../input/DateInput";
import { IModalHandle, Modal } from "../modal/Modal";
import { SelectYup } from "../select/SelectYup";
import { filtersEmptyValues } from "../utils/hookFormUtils";
import { MixpanelCategory, mixpanelTrack } from "../utils/mixPanelUtils";
import { getEventOptions } from "../utils/reactSelectUtils";
import styles from "./Header.module.scss";
import { inputItems } from "./input-items";
interface IOption {
	label: string;
	value: string;
}
export const Header: React.FC = () => {
	const [params, setParams] = useSearchParams();
	const companyName = useAppSelector((state) => state.settings.companyName);
	const dispatch = useAppDispatch();

	const { control, handleSubmit, reset, getValues, setValue } = useForm({
		mode: "onChange",
		resolver: yupResolver(HeaderFiltersValidation),
		defaultValues: {
			companyName: companyName ?? "",
			eventName: params.get("eventName") ?? "",
			eventHeadliner: params.get("eventHeadliner") ?? "",
			venue: params.get("venue") ?? "",
			startDate: params.get("startDate") ?? "",
			endDate: params.get("endDate") ?? "",
			smartSearch: params.get("smartSearch") ?? "",
			tags: params.get("tags") ?? "",
			category: params.get("category") ?? "",
			section: params.get("section") ?? "",
			row: params.get("row") ?? "",
			minQuantity: params.get("minQuantity") ?? "",
			daysSinceLastUpdate: params.get("daysSinceLastUpdate") ?? "",
			listingId: params.get("listingId") ?? "",
			eventId: params.get("eventId") ?? "",
			poId: params.get("poId") ?? "",
			poStartDate: params.get("poStartDate") ?? "",
			poEndDate: params.get("poEndDate") ?? "",
		},
	});
	const { pathname } = useLocation();
	const headerLabel = pathname === "/" ? "Pricing" : pathname.slice(1);

	useEffect(() => {
		if (!companyName) return;
		setValue("companyName", companyName);
	}, [companyName]);

	const onSubmit: SubmitHandler<HeaderFiltersProps> = (data) => {
		Object.entries(data).forEach(([k, v]) => {
			v ? params.set(k, String(v)) : params.delete(k);
		});
		params.delete("eventsPage");
		if (params.get("companyName")) {
			const companyName = params.get("companyName") || "";
			dispatch(settingsActions.setCompanyName(companyName));
			dispatch(eventActions.setEvent({ event: null }));
			params.delete("companyName");
		}
		setParams(params);

		const mixPanelData = Object.fromEntries(Object.entries(data).filter(([_, v]) => v));
		if (Object.keys(mixPanelData).length) {
			mixpanelTrack(MixpanelCategory.FILTERS_APPLIED, {
				data: mixPanelData,
			});
		}
	};

	const events = useAppSelector((state) => state.pricing.events.data.Events);

	const listCategories = useAppSelector((state) => state.pricing.listCategories);
	const listVenues = useAppSelector((state) => state.pricing.listVenues);
	const listHeadliners = useAppSelector((state) => state.pricing.listHeadliners);

	const [pageState, setPageState] = useState<{
		categoryOptions: Array<IOption>;
		headlinerOptions: Array<IOption>;
		venueOptions: Array<IOption>;
	}>({
		categoryOptions: getEventOptions(events, "Cat_Type"),
		headlinerOptions: getEventOptions(events, "Event_Headliner"),
		venueOptions: getEventOptions(events, "Venue_Name"),
	});

	const smartSearchOptions = [...pageState.categoryOptions, ...pageState.headlinerOptions, ...pageState.venueOptions];

	useEffect(() => {
		if (!listCategories.init) dispatch(pricingActions.listCategories({}));
		if (!listVenues.init) dispatch(pricingActions.listVenues({}));
		if (!listHeadliners.init) dispatch(pricingActions.listHeadliners({}));
	}, []);

	useEffect(() => {
		setPageState((prev) => ({
			...prev,
			categoryOptions: listCategories.data.map((x) => ({ label: x.Cat_Desc, value: String(x.Cat_ID) })),
			venueOptions: listVenues.data.map((x) => ({ label: x.Venue_Name, value: String(x.Venue_ID) })),
			headlinerOptions: listHeadliners.data.map((x) => ({ label: x.Event_Headliner, value: String(x.Event_Headliner_ID) })),
		}));
	}, [listCategories.data, listVenues.data, listHeadliners.data]);

	const inventory = useAppSelector((state) => state.pricing.eventInventoryAll.data);

	const mappedInputs = inputItems.map(({ id, name, placeholder, type, showType }) => {
		return (
			<div className={styles.inputWrapper} data-type={type} key={id}>
				<div className={styles.smartSearchWrapper}>
					{showType === "input" && (
						<Input wrapperStyle={{ width: "100%" }} type={type} control={control} placeholder={placeholder} name={name} />
					)}
					{showType === "select" && (
						<SelectYup name={name} placeholder={placeholder} control={control} options={smartSearchOptions} />
					)}
					{showType === "date" && (
						<DateInput wrapperStyle={{ minWidth: "100px" }} control={control} placeholder={placeholder} name={name} />
					)}
				</div>
			</div>
		);
	});

	const filterModalRef = useRef<IModalHandle>(null);
	const closeFilterModal = () => filterModalRef.current?.closeModal();
	const openFilterModal = () => filterModalRef.current?.openModal();

	const open = (e: React.MouseEvent) => {
		e.preventDefault();
		openFilterModal();
		mixpanelTrack(MixpanelCategory.FILTERS_BUTTON_CLICKED);
	};

	const allFiltersProps = {
		control,
		closeFilterModal,
		reset,
		getValues,
		setValue,
		categoryOptions: pageState.categoryOptions,
		headlinerOptions: pageState.headlinerOptions,
		venueOptions: pageState.venueOptions,
		inventory,
		smartSearchOptions,
	};

	const isAdmin = useAppSelector((state) => state.settings.isAdmin);

	const { data, loading } = useAppSelector((state) => state.pricing.companies);
	const companyOptions = data.map((x) => ({
		label: x,
		value: x,
	}));

	useEffect(() => {
		if (!isAdmin) return;
		dispatch(pricingActions.listCompanies({}));
	}, [isAdmin]);

	return (
		<header className={styles.main}>
			<div className={styles.container}>
				<div className={styles.left}>{headerLabel}</div>
				<form className={styles.form} onSubmit={handleSubmit(onSubmit)}>
					{isAdmin && (
						<div className={styles.companyhWrapper}>
							<SelectYup
								isLoading={loading}
								name="companyName"
								placeholder="Company name"
								control={control}
								options={companyOptions}
							/>
						</div>
					)}
					{mappedInputs}
					<div className={styles.buttonContainer}>
						<Button
							type="button"
							onClick={() => {
								setParams({});
								reset(filtersEmptyValues);
							}}
							variant={ButtonVariant.Quaternary}>
							<div className={styles.filterButton}>
								<ResetIcon />
							</div>
						</Button>
						<Modal
							ref={filterModalRef}
							customButton={
								<Button style={{ width: "100px" }} type="button" onClick={open} variant={ButtonVariant.Quaternary}>
									<div className={styles.filterButton}>
										<FilterIcon /> Filters {!params.size || <span>({params.size})</span>}
									</div>
								</Button>
							}>
							<AllFilters {...allFiltersProps} />
						</Modal>

						<Button style={{ width: "100px" }} type="submit">
							Search
						</Button>
					</div>
				</form>
			</div>
		</header>
	);
};
