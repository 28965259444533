export interface IDataState<T> {
	loading: boolean;
	init: boolean;
	version: number;
	data: T;
	error: string | null;
}
export const getInitialState = <T>(data: T): IDataState<T> => {
	return {
		loading: false,
		init: false,
		version: 0,
		error: null,
		data: data,
	};
};
export const fromType = <T>(obj = {}): T => obj as T;

export interface SortField {
	property: string;
	direction: string;
}
