import { createSelector } from "@reduxjs/toolkit";
import { getThunkAndSlice } from "@src/store/getThunkAndSlice";
import { RootState } from "@src/store/store";
import { fromType } from "../helpers";

export interface IPOSSettings {
	Allow_Price_Increase?: boolean | null;
	Allow_Price_Increase_Grouped?: boolean | null;
	Cycle?: string | null;
	Cycle_Grouped?: string | null;
	Amount_Under?: number | null;
	Amount_Under_Grouped?: number | null;
	Is_Amount_Over?: boolean | null;
	Is_Amount_Over_Grouped?: boolean | null;
	Is_Amount_Percent?: boolean | null;
	Is_Amount_Percent_Grouped?: boolean | null;
	Floor_Price_Percentage?: number | null;
	Floor_Price_Percentage_Grouped?: number | null;
	Ceiling_Price_Percentage?: number | null;
	Ceiling_Price_Percentage_Grouped?: number | null;
	Notifications?: boolean | null;
	Notifications_Grouped?: boolean | null;
	Exclude_Outliers_Percentage?: number | null;
	Exclude_Outliers_Percentage_Grouped?: number | null;
	Master_Rule_Type?: string | null;
	Active_Tiers?: number | null;
	Stagger_Amount?: number | null;
	Is_Stagger_Percent?: boolean | null;
	Compare_Against_Own_Inventory?: boolean | null;
	Compare_Against_Own_Inventory_Grouped?: boolean | null;
	Preview_Other_Consignor_Listings?: boolean | null;
	Obstructed?: boolean | null;
	Obstructed_Grouped?: boolean | null;
	Piggyback?: boolean | null;
	Piggyback_Grouped?: boolean | null;
	Senior_Youth?: boolean | null;
	Senior_Youth_Grouped?: boolean | null;
	Hard_Ticket?: boolean | null;
	Hard_Ticket_Grouped?: boolean | null;
	ETicket?: boolean | null;
	ETicket_Grouped?: boolean | null;
	Mobile_Ticket?: boolean | null;
	Mobile_Ticket_Grouped?: boolean | null;
	LMS?: boolean | null;
	LMS_Grouped?: boolean | null;
	Instant_Delivery?: boolean | null;
	Instant_Delivery_Grouped?: boolean | null;
	Custom_Split_Mapping: Array<number>;
	Custom_Split_Mapping_Grouped: Array<number>;
	Use_Default_Splits?: boolean | null;
	Use_Default_Splits_Grouped?: boolean | null;
}

const initialState = fromType<IPOSSettings | null>({});

export const { thunk: getPOSSettings, slice: getPOSSettingsSlice } = getThunkAndSlice<
	{
		userName: string;
	},
	typeof initialState
>({
	initialState,
	path: "/api/POS/GetPOSSettings",
});

const _selectPOSSettings = (state: RootState) => state.POS.POSSettings.data;
const _selectPOSSettingsLoading = (state: RootState) => state.POS.POSSettings.loading;
const _selectPOSSettingsInit = (state: RootState) => state.POS.POSSettings.init;
export const singleApSplitsMapping = (state: RootState) => state.POS.POSSettings.data?.Custom_Split_Mapping;

export const selectPOSSettingsData = createSelector(
	[_selectPOSSettings, _selectPOSSettingsLoading, _selectPOSSettingsInit],
	(POSSettings, POSSettingsLoading, POSSettingsInit) => ({ POSSettings, POSSettingsLoading, POSSettingsInit }),
);
