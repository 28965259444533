import helperStyles from "@src/components/helpers.module.scss";
import React from "react";

export const FanXchange = () => {
	return (
		<div className={helperStyles.iconContainer}>
			<svg
				width="100%"
				height="100%"
				viewBox="0 0 18 16"
				fill="none"
				xmlns="http://www.w3.org/2000/svg"
				xmlnsXlink="http://www.w3.org/1999/xlink">
				<rect x="0.589844" width="16.8205" height="16" fill="url(#pattern0_2772_184261)" />
				<defs>
					<pattern id="pattern0_2772_184261" patternContentUnits="objectBoundingBox" width="1" height="1">
						<use xlinkHref="#image0_2772_184261" transform="scale(0.0243902 0.025641)" />
					</pattern>
					<image
						id="image0_2772_184261"
						width="41"
						height="39"
						xlinkHref="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACkAAAAnCAYAAACSamGGAAAABmJLR0QA/wD/AP+gvaeTAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAB3RJTUUH4QMBEzUlGYV06gAABOhJREFUWMPVmV9sFFUUxn/3zmy7tLS2VEgpVi0SUUCtFNMEFfqAQTRgEAURBDUUi6iQqKgoCJJIFE0AE4ytIv9CUCOIjSmKGjDxgWihIWINEoIgf6SppS1ld7szc33oTC11a+9l10TO0+69357z5c4953xnViil+L+b5DIwO7r6GqGBCzBeCmNbmv6EDQzRJCmAw4BKEcHrAUePpJD7UF5E81p8C8xMkugVwFZglBZJIfrauLFcZChXM8DDwFFgSRIk1wD3aKM9B6maT083DPIKMOMSCT4HzNZPaxvVfHKSRLENIeYbBvsQuN3wN5OAVdpoIVEtxxfat82rlrLoTtS54+sQcpVBwBCwBcjXxI8ANpsQJNa6mKizxrp5FtK65RFEeja47Yv8E9K1a318ei+4HOAjIFvbsxvbgGBlaHIlsrAUaY8sR+RcDfEIwEKgxoDo3cDqXjBbgGEGPrerWMvjot8Q7JJyQNgSFPaYJahoE0irBbgf+N7AaQXwdA97rwP3Gvj6DuVNE6FMFSpbEazlSRDIwXchCkZBextAFHgM+N3A+WpgfLe1cuAlAx8HgKlI21GRRkR+cbDeXwKIcA6h0YtQzWeDDvgrMBFoNdAA64EC/3sp8LYBwVPAZBB/qLYGrJtmgLSDvaK/BYYbhZANqrOd1gFTgIhmoAJgIzAG+BzIMiA4HvgN5UG8DbukAtGns784nSStYQ9iXTcO1XSiowR02G5ggcGJjAO+AAbo5jEwB/gJYaEaT2ANn4osGNmDVLPDWCPnIrL6gxPtiqkCXjAg2lcTd8F/Uh3VJN6GyM3HKn4UrPSe9aR142TEgOEQv9Dd4ZvA2hTLxJeBncEX1d6KKCjBGjKhd9EbKluGiraAtLpvLfDvXCpsxUX1VQhwHUJjl+spczmoFLt4NupCYyL8k8BXSRJcCSztuqAiTdglc5EDi1MyPrQDsSRJRpOecbyT+3DqNiIy8hLh3/HrZzK2vPtJij65OLWVeKfr9EjG9yxDhLPBcxMRrEjRnVx+kSZVCiyb+N5Xeyfp1u9AnT0EoYxEavqpFGf3euC+ztNMy0KdqsU9UvMvJJ0o7v5KVGsD2OGumGeBZ/6DSTUN+LSjHQKhTFTTGdy6DeDGEpP0ju3BPbwLkVvYtTVWAG8ZBP4BmA60GUyN64ARKBeRV4h76GO8U/v/SVJFm3D2V0G4b9eWWA68a0CwxS9R23yiupYPfAkMR0gIZeLUvhcoskCng9dQj3NoOyJzQDCtPgRUGj6+OcCP/udqw1Za0KETVKHI7I97cDOq6WhnqkgAd+9ryIwc8ByAMuADQ4JLgU8StNL3DXwMBLaivCzSsmj/ZlGwfky6R3bhnTkQJMto/zJnGE6OK3rYmw/sNfB1B7Ab5V5J+/lg7Yx06tZ3iF07PBbYBfQzcFoDPNFLh5oI/Gzgs1SkZ21SDfWWU1sFqGaJG4dwehHK22QgVAP1PhOI94JrBaYZqHyw0iegqIrvmIt3Yh/W4qG/DBK5V+0EhhoQbAMe8F9g6dhZ4CAwVU8vKLDDt+KdLxLS/kxE3sirJy3zBsNEmQJsv4QCPs+vi/qvWf48+qKIrBms/KzWtecNC3wiDaDfYpWLNCRYmSRB/Bm92uQ0bb9L6JgDbEpR356l3ZWUwga+NpjsYikiec4gLuJy+PfhL5DTf+fdk4P2AAAAAElFTkSuQmCC"
					/>
				</defs>
			</svg>
		</div>
	);
};
