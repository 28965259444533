import { getThunkAndSlice } from "@src/store/getThunkAndSlice";

const initialState = new Array<{
	Common_Help_ID: number;
	Help_Desc: string;
	Help_Type: string;
}>();

export const { thunk: listCommonHelp, slice: listCommonHelpSlice } = getThunkAndSlice<Record<string, never>, typeof initialState>(
	{
		initialState,
		path: "/api/System/ListCommonHelp",
	},
);
