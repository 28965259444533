import { getThunkAndSlice } from "@src/store/getThunkAndSlice";

const initialState = new Array<{
	Cat_ID: number;
	Cat_Desc: string;
	Cat_Type: string;
}>();

export const { thunk: listCategories, slice: listCategoriesSlice } = getThunkAndSlice<Record<string, never>, typeof initialState>(
	{
		initialState,
		path: "/api/Pricing/ListCategories",
	},
);
