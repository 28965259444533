import { getThunkAndSlice } from "@src/store/getThunkAndSlice";

const initialState = {
	success: false,
	invoiceId: 0,
};

export const { thunk: createExternalInvoice, slice: createExternalInvoiceSlice } = getThunkAndSlice<
	{
		seatFrom: string;
		quantity: string;
		pricePerTicket: string;
		returnPlainText: string;
		listingId: string;
		companyName: string;
		deliveryType: string;
		customerName: string;
		email: string;
		SalePrice: string;
		marketplace: string;
	},
	typeof initialState
>({
	initialState,
	path: "/api/Sales/CreateExternalInvoice",
	method: "postWithFile",
});
