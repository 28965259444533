import { combineReducers, configureStore } from "@reduxjs/toolkit";
import { loginSlice } from "./account/loginSlice";
import mapSlice from "./map/mapSlice";
import { settingsSlice } from "./settingsSlice";

import { getEventMapSlice } from "./marketplace/seatgeek/getEventMapSlice";
import { getLowestComparablesSlice } from "./marketplace/seatgeek/getLowestComparables";

import { getMandatoryNotificationsSlice } from "./notifications/getMandatoryNotificationsSlice";
import { getMappingCountsSlice } from "./notifications/getMappingCountsSlice";

import { getPOSSettingsSlice } from "./pos/getPOSSettingsSlice";

import { listCategoriesSlice } from "./pricing/listCategoriesSlice";
import { listCompaniesSlice } from "./pricing/listCompaniesSlice";
import { listHeadlinersSlice } from "./pricing/listHeadlinersSlice";
import { listVenuesSlice } from "./pricing/listVenuesSlice";
import { queryEventInventoryAllSlice } from "./pricing/queryEventInventoryAllSlice";
import { queryEventsSlice } from "./pricing/queryEventsSlice";
import { queryInventorySlice } from "./pricing/queryInventorySlice";

import { listCustomersSlice } from "./sales/listCustomersSlice";

import { getActiveListingsSlice } from "./marketplace/seatgeek/getActiveListingsSlice";
import { getSoldListingsSlice } from "./marketplace/seatgeek/getSoldListingsSlice";
import { selectionSlice } from "./pricing/selectionSlice";
import { settingsSlice as pricingSettingsSlice } from "./pricing/settingsSlice";
import { listAllTagsSlice } from "./system/listAllTagsSlice";
import { listCommonHelpSlice } from "./system/listCommonHelpSlice";
import { listExportDestinationsSlice } from "./system/listExportDestinationsSlice";
import { listLocationsSlice } from "./system/listLocationsSlice";
import { viewItemHistorySlice } from "./system/ViewHistory";

import autopricerSlice from "@src/store/auto-pricer/autopricerSlice";
import { appendListingNotesSlice } from "./pricing/appendListingNotes";
import { disableFlashSaleSlice } from "./pricing/disableFlashSale";
import { getSharedSettings } from "./pricing/getSharedSettings";
import { holdTicketsSlice } from "./pricing/holdTicketsSlice";
import { listingsSharingSlice } from "./pricing/listingsSharing";
import { releaseListingsSlice } from "./pricing/releaseListings";
import { savedRulesSlice } from "./pricing/savedRulesSlice";
import { updateSplitListingSlice } from "./pricing/splitListings";
import { updateSplitListingAdvancedSlice } from "./pricing/splitListingsAdvanced";
import { updateFlashSaleSlice } from "./pricing/updateFlashSale";
import { updateListingCostsSlice } from "./pricing/updateListingCosts";
import { updateListingPositionSlice } from "./pricing/updateListingPosition";
import { updateListingPricesSlice } from "./pricing/updateListingPrices";
import { updateSchedulePriceSlice } from "./pricing/updateSchedulePrice";
import { getListingsItemsSlice, listingsItemsSlice } from "./purchasing/getListingsItemsSlice";
import { updatePurchaseInfoSlice } from "./purchasing/updatePurchaseInfo";
import { createExternalInvoiceSlice } from "./sales/createExternalInvoice";
import { listCategoriesSalesSlice } from "./sales/listCategoriesSlice";
import { listExchangesSlice } from "./sales/listExchanges";
import { listHeadlinersSalesSlice } from "./sales/listHeadlinersSlice";
import { listMarketplacesSlice } from "./sales/listMarketplacesSlice";
import { listSaleStatusesSlice } from "./sales/listSalesStatusesSlice";
import { listTagsSlice } from "./sales/listTagsSlice";
import { listVenuesSalesSlice } from "./sales/listVenueSlice";
import { querySalesSlice } from "./sales/querySalesSlice";
import { addEventTagsSlice } from "./system/addEventTagsSlice";
import { addItemTagsSlice } from "./system/addItemsTagsSlice";
import { attachPdfsSlice } from "./system/attachPdfs";
import { editTicketsSlice } from "./system/editTickets";
import { forceAttachPdfsSlice } from "./system/forceAttachPdfs";
import { getEventTagsSlice } from "./system/getEventTags";
import { getFileSlice } from "./system/getFile";
import { getSeatsSlice } from "./system/getGetSeats";
import { getItemPngSlice } from "./system/getItemPng";
import { getListingTagsSlice } from "./system/getListingTags";
import { removeEventTagSlice } from "./system/removeEventTag";
import { removeTagSlice } from "./system/removeTag";
import { splitPDFsSlice } from "./system/splitPDFs";
import { supportAttachmentRequestSlice } from "./system/supportAttachmentRequest";
import { getListingRulesSlice } from "./auto-pricer/getListingRulesSlice";
import { removeAutopricerSlice } from "./auto-pricer/removeAutopricerSlice";

const seatgeek = combineReducers({
	eventMap: getEventMapSlice.reducer,
	lowestComparable: getLowestComparablesSlice.reducer,
	activeListings: getActiveListingsSlice.reducer,
	soldListings: getSoldListingsSlice.reducer,
});

const marketplace = combineReducers({
	seatgeek,
});

const notification = combineReducers({
	mandatoryNotifications: getMandatoryNotificationsSlice.reducer,
	mappingCounts: getMappingCountsSlice.reducer,
});

const POS = combineReducers({
	POSSettings: getPOSSettingsSlice.reducer,
});

const pricing = combineReducers({
	categories: listCategoriesSlice.reducer,
	companies: listCompaniesSlice.reducer,
	headliners: listHeadlinersSlice.reducer,
	venues: listVenuesSlice.reducer,
	eventInventoryAll: queryEventInventoryAllSlice.reducer,
	events: queryEventsSlice.reducer,
	columns: queryEventsSlice.reducer,
	inventory: queryInventorySlice.reducer,
	selection: selectionSlice.reducer,
	settings: pricingSettingsSlice.reducer,
	holdTickets: holdTicketsSlice.reducer,
	savedRules: savedRulesSlice.reducer,
	sharingSettings: getSharedSettings.reducer,
	listingsSharing: listingsSharingSlice.reducer,
	listCategories: listCategoriesSlice.reducer,
	listVenues: listVenuesSlice.reducer,
	listHeadliners: listHeadlinersSlice.reducer,
	updateListingPrices: updateListingPricesSlice.reducer,
	updateListingPosition: updateListingPositionSlice.reducer,
	updateListingCosts: updateListingCostsSlice.reducer,
	schedulePrice: updateSchedulePriceSlice.reducer,
	flashSale: updateFlashSaleSlice.reducer,
	disableFlashSale: disableFlashSaleSlice.reducer,
	splitListings: updateSplitListingSlice.reducer,
	splitListingsAdvanced: updateSplitListingAdvancedSlice.reducer,
	appendListingNotes: appendListingNotesSlice.reducer,
	releaseListings: releaseListingsSlice.reducer,
});

const sales = combineReducers({
	customers: listCustomersSlice.reducer,
	exchanges: listExchangesSlice.reducer,
	createExternalInvoice: createExternalInvoiceSlice.reducer,
	querySales: querySalesSlice.reducer,
	listVenues: listVenuesSalesSlice.reducer,
	listCategoriesSales: listCategoriesSalesSlice.reducer,
	listHeadliners: listHeadlinersSalesSlice.reducer,
	listSaleStatuses: listSaleStatusesSlice.reducer,
	listMarketplaces: listMarketplacesSlice.reducer,
	listTags: listTagsSlice.reducer,
});

const purchasing = combineReducers({
	listingsItems: getListingsItemsSlice.reducer,
	updatePurchaseOrder: updatePurchaseInfoSlice.reducer,
	...listingsItemsSlice.reducer,
});

const system = combineReducers({
	allTags: listAllTagsSlice.reducer,
	commonHelp: listCommonHelpSlice.reducer,
	exportDestinations: listExportDestinationsSlice.reducer,
	locations: listLocationsSlice.reducer,
	viewItemHistory: viewItemHistorySlice.reducer,
	addItemTags: addItemTagsSlice.reducer,
	addEventTags: addEventTagsSlice.reducer,
	editTickets: editTicketsSlice.reducer,
	listingTags: getListingTagsSlice.reducer,
	eventTags: getEventTagsSlice.reducer,
	removeTag: removeTagSlice.reducer,
	removeEventTag: removeEventTagSlice.reducer,
	splitPDFs: splitPDFsSlice.reducer,
	getFile: getFileSlice.reducer,
	attachPdfs: attachPdfsSlice.reducer,
	supportAttachmentRequest: supportAttachmentRequestSlice.reducer,
	forceAttachPdfs: forceAttachPdfsSlice.reducer,
	getSeats: getSeatsSlice.reducer,
	getItemPng: getItemPngSlice.reducer,
});

const rootReducer = combineReducers({
	login: loginSlice.reducer,
	autopricer: autopricerSlice.reducer,
	autopricerRules: getListingRulesSlice.reducer,
	removeAutopricer: removeAutopricerSlice.reducer,
	marketplace,
	notification,
	POS,
	pricing,
	purchasing,
	sales,
	system,
	settings: settingsSlice.reducer,
	map: mapSlice.reducer,
});

export const store = configureStore({
	reducer: rootReducer,
	middleware: (getDefaultMiddleware) =>
		getDefaultMiddleware({
			serializableCheck: false,
		}),
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
