import { eventActions } from "@src/store";
import React, { MouseEvent } from "react";
import { MixpanelCategory, mixpanelTrack } from "../utils/mixPanelUtils";
import styles from "./EditInventory.module.scss";
import { InventoryActionEnum, ManageInventoryTabEnum } from "@src/store/pricing/selectionSlice";
import { useAppDispatch } from "@src/store/hooks";

export const EditInventory: React.FC<{
	itemId: number;
}> = ({ itemId }) => {
	const dispatch = useAppDispatch();

	const handleEditButtonClick = (e: MouseEvent<HTMLButtonElement>) => {
		dispatch(
			eventActions.setInventory({
				inventoryItemIds: [itemId],
				inventoryAction: InventoryActionEnum.Edit,
			}),
		);
		dispatch(eventActions.setManageInventoryTab(ManageInventoryTabEnum.ManageListing));
		e.stopPropagation();

		mixpanelTrack(MixpanelCategory.EDIT_INVENTORY_CLICKED, {
			data: itemId,
		});
	};

	return (
		<button tabIndex={0} className={styles.main} onClick={handleEditButtonClick}>
			Edit
		</button>
	);
};
