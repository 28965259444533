import helperStyles from "@src/components/helpers.module.scss";
import React from "react";

export interface IEyeSize {
	size?: string;
}

export const EyeHide: React.FC<IEyeSize> = ({ size }) => {
	return (
		<div style={{ justifyContent: "flex-start" }} className={helperStyles.iconContainer}>
			<svg
				width={size ?? "100%"}
				height={size ?? "100%"}
				viewBox="0 0 10 10"
				fill="none"
				xmlns="http://www.w3.org/2000/svg">
				<path
					d="M4.92273 3.34211L6.36364 4.83895V4.76316C6.36364 4.38627 6.21997 4.02482 5.96424 3.75832C5.70851 3.49182 5.36166 3.34211 5 3.34211H4.92273ZM2.96818 3.72105L3.67273 4.45526C3.65 4.55474 3.63636 4.65421 3.63636 4.76316C3.63636 5.14004 3.78003 5.50149 4.03576 5.76799C4.29149 6.03449 4.63834 6.18421 5 6.18421C5.1 6.18421 5.2 6.17 5.29545 6.14632L6 6.88053C5.69545 7.03684 5.35909 7.13158 5 7.13158C4.39724 7.13158 3.81916 6.88205 3.39294 6.43788C2.96672 5.99372 2.72727 5.3913 2.72727 4.76316C2.72727 4.38895 2.81818 4.03842 2.96818 3.72105ZM0.454545 1.10158L1.49091 2.18158L1.69545 2.39474C0.945455 3.01053 0.354545 3.81579 0 4.76316C0.786364 6.84263 2.72727 8.31579 5 8.31579C5.70455 8.31579 6.37727 8.17368 6.99091 7.91789L7.18636 8.11684L8.51364 9.5L9.09091 8.89842L1.03182 0.5M5 2.39474C5.60277 2.39474 6.18084 2.64427 6.60706 3.08843C7.03328 3.5326 7.27273 4.13501 7.27273 4.76316C7.27273 5.06632 7.21364 5.36 7.10909 5.62526L8.44091 7.01316C9.12273 6.42105 9.66818 5.64421 10 4.76316C9.21364 2.68368 7.27273 1.21053 5 1.21053C4.36364 1.21053 3.75455 1.32895 3.18182 1.54211L4.16818 2.56053C4.42727 2.45632 4.70455 2.39474 5 2.39474Z"
					fill="#101828"
				/>
			</svg>
		</div>
	);
};
