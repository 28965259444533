import helperStyles from "@src/components/helpers.module.scss";
import React from "react";

export const ArrowIcon: React.FC = () => {
	return (
		<div className={helperStyles.iconContainer}>
			<svg width="8" height="8" viewBox="0 0 8 8" fill="none" xmlns="http://www.w3.org/2000/svg">
				<path d="M2.79755 7.5L0 4.18191L0.699387 3.35239L2.79755 5.84096L7.30061 0.5L8 1.32952L2.79755 7.5Z" fill="white" />
			</svg>
		</div>
	);
};
