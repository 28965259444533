import { EyeHide, EyeShow, InputXicon } from "@src/assets/general-icons";
import React, { CSSProperties, forwardRef, useEffect, useImperativeHandle, useMemo, useRef, useState } from "react";
import { useController } from "react-hook-form";
import { cleanNumber, formatCurrency } from "../utils/numberUtils";
import { clx } from "../utils/stringUtils";
import { UNKNOWN } from "../vGrid/VGrid";
import styles from "./Input.module.scss";

export interface IInputProps {
	control?: UNKNOWN;
	name?: string;
	label?: string;
	controlSize?: "n" | "sm" | "xsm";
	currency?: boolean;
	wrapperStyle?: CSSProperties;
	wrapperClassName?: string;
	inputContainerClassName?: string;
	style?: CSSProperties;
	className?: string;
	disableErrorMessage?: boolean;
	value?: string | number;
	disableBorder?: boolean;
	disableXbutton?: boolean;
	type?: "text" | "number" | "checkbox" | "password" | "date";
	placeholder?: string;
	disabled?: boolean;
	inputPrefix?: React.ReactNode;
	onChange?: React.ChangeEventHandler<HTMLInputElement>;
	onBlur?: React.FocusEventHandler<HTMLInputElement>;
	onKeyDown?: React.KeyboardEventHandler<HTMLInputElement>;
	step?: string | number;
}
export interface IInputRef {
	focus: () => void;
	select: () => void;
}
export const Input = forwardRef<IInputRef, IInputProps>(
	(
		{
			label,
			controlSize = "n",
			currency,
			control,
			name = "",
			wrapperStyle,
			wrapperClassName = "",
			inputContainerClassName = "",
			style,
			disableErrorMessage,
			disableBorder = false,
			disableXbutton = false,
			value,
			type,
			placeholder,
			disabled,
			inputPrefix,
			className = "",
			onChange,
			onBlur,
			onKeyDown,
			step,
		},
		ref: React.Ref<IInputRef>,
	): React.ReactElement => {
		const [inputType, setInputType] = useState(type);
		const inputRef = useRef<HTMLInputElement>(null);
		const { field, fieldState } = control
			? useController({ name, control })
			: useMemo(() => {
				const fieldObj = {
					field: {
						value: value,
						name: name,
						onChange: (value?: UNKNOWN) => {
							fieldObj.field.value = value;
						},
						onBlur: () => {
							/** */
						},
						ref: () => {
							/** */
						},
					},
					fieldState: { error: undefined, isDirty: false, invalid: false },
				};
				return fieldObj;
			}, []);

		const toggleType = () => {
			setInputType(inputType === "password" ? "text" : "password");
		};

		const passwordIcon = inputType === "password" ? <EyeShow /> : <EyeHide />;

		useImperativeHandle(ref, () => {
			return {
				focus: () => {
					inputRef.current?.focus();
				},
				select: () => {
					inputRef.current?.select();
				},
			};
		});

		const [displayValue, setDisplayValue] = useState(`${field.value}`);

		const errorMessage = fieldState.error?.message;
		const { isDirty, invalid } = fieldState;

		const innerTernary = field.value && isDirty ? "success" : "none";
		const inputStatus = invalid ? "error" : innerTernary;

		useEffect(() => {
			if (!currency) return;
			setDisplayValue(formatCurrency(field.value as number));
		}, [field.value]);

		const handleBlur = (e: React.FocusEvent<HTMLInputElement>) => {
			if (currency) {
				const number = cleanNumber(e.target.value);
				const formattedValue = formatCurrency(number);

				setDisplayValue(formattedValue);
				field.onChange(number);
			}
			field.onBlur();
			onBlur?.(e);
		};
		const requiredProps: React.ComponentProps<"input"> = {
			ref: control
				? (el: HTMLInputElement) => {
					field.ref(el);
				}
				: inputRef,
			onKeyPress: (e: React.KeyboardEvent<HTMLInputElement>) => {
				if (!currency) return true;
				const target = e.target as HTMLInputElement;

				if (
					!"0123456789.$".includes(e.key) ||
					(e.key === "$" && target.value.includes("$")) ||
					(e.key === "." && target.value.includes("."))
				) {
					e.preventDefault();
					return false;
				}
			},
			onBlur: handleBlur,
			onChange: (e: React.ChangeEvent<HTMLInputElement>) => {
				onChange?.(e);
				if (currency) setDisplayValue(e.target.value);
				else if (type == "checkbox") field.onChange(e.target.checked);
				else field.onChange(e.target.value);
			},
			onKeyDown: onKeyDown,
			name: field.name,
			checked: typeof field.value == "boolean" ? field.value : false,
			value: currency ? displayValue : field.value,
			autoComplete: "off",
			placeholder: placeholder,
			type: inputType,
			style: style,
			disabled: disabled,
			step: step,
		};

		const resetFieldValue = () => {
			field.onChange("");
		};

		const showIconsContainer = (!disableXbutton && String(field.value)) || type === "password";

		return (
			<div className={clx({ [styles.main]: true, [wrapperClassName]: !!wrapperClassName })} style={wrapperStyle}>
				{label && (
					<div data-is-disabled={disabled} className={styles.label}>
						{label}
					</div>
				)}
				<div
					data-disable-border={disableBorder}
					data-input-status={inputStatus}
					className={clx({ [styles.inputContainer]: true, [inputContainerClassName]: !!inputContainerClassName })}>
					{inputPrefix ? (
						<div data-is-disabled={disabled} className={styles.inputPrefix}>
							{inputPrefix}
						</div>
					) : null}
					<input
						className={clx({
							[styles.input]: true,
							[styles.small]: controlSize == "sm",
							[styles.extraSmall]: controlSize == "xsm",
							[className]: !!className,
						})}
						{...requiredProps}
					/>
					{showIconsContainer ? (
						<div data-is-disabled={disabled} className={styles.inputIcons}>
							{type === "password" ? (
								<div aria-hidden onClick={toggleType}>
									{passwordIcon}
								</div>
							) : null}
							{!disableXbutton && String(field.value) ? (
								<div aria-hidden="true" onClick={resetFieldValue} className={styles.inputXicon}>
									<InputXicon />
								</div>
							) : null}
						</div>
					) : null}
				</div>
				{!disableErrorMessage && errorMessage && (
					<div className={styles.status} data-input-status={inputStatus}>
						{errorMessage}
					</div>
				)}
			</div>
		);
	},
);
