import styles from "@src/components/helpers.module.scss";
import React from "react";

export const AddOutlineIcon: React.FC<{ color?: string }> = ({ color = "#3C4250" }) => {
	return (
		<div className={styles.iconContainer} style={{ justifyContent: "flex-start", height: "100%" }}>
			<svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
				<g clipPath="url(#clip0_1174_12331)">
					<path
						d="M7.00241 13.1284C10.3852 13.1275 13.1262 10.3845 13.1247 7.00171C13.1233 3.61896 10.3798 0.877474 6.99707 0.878418C3.61433 0.879362 0.873266 3.62238 0.87474 7.00513C0.876214 10.3879 3.61967 13.1294 7.00241 13.1284Z"
						stroke={color}
						strokeOpacity="0.5"
						strokeLinecap="round"
						strokeLinejoin="round"
					/>
					<path
						d="M4.375 7.00415L9.62501 7.00269"
						stroke={color}
						strokeOpacity="0.5"
						strokeLinecap="round"
						strokeLinejoin="round"
					/>
					<path
						d="M6.99902 4.37842L7.00131 9.62842"
						stroke={color}
						strokeOpacity="0.5"
						strokeLinecap="round"
						strokeLinejoin="round"
					/>
				</g>
				<defs>
					<clipPath id="clip0_1174_12331">
						<rect
							width="14"
							height="14"
							fill="white"
							transform="matrix(1 -0.000279074 0.000435863 1 -0.00292969 0.00537109)"
						/>
					</clipPath>
				</defs>
			</svg>
		</div>
	);
};
