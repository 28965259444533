import styles from "@src/components/helpers.module.scss";
import React from "react";

export const ETicket = () => {
	return (
		<div className={styles.iconContainer}>
			<svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
				<path
					d="M6.26328 7.50215C6.14609 7.1584 6.14844 6.49453 6.21641 6.49453C6.41328 6.49453 6.39453 7.2873 6.26328 7.50215ZM6.22344 8.51621C6.04297 8.95019 5.81797 9.44648 5.55781 9.86328C5.98672 9.71289 6.47187 9.49375 7.03203 9.39277C6.73438 9.18652 6.44844 8.89004 6.22344 8.51621ZM4.01797 11.1975C4.01797 11.2146 4.32734 11.0814 4.83594 10.3338C4.67891 10.4691 4.15391 10.8602 4.01797 11.1975ZM7.8125 5.4375H11V12.4844C11 12.7701 10.7492 13 10.4375 13H2.5625C2.25078 13 2 12.7701 2 12.4844V2.51562C2 2.22988 2.25078 2 2.5625 2H7.25V4.92188C7.25 5.20547 7.50313 5.4375 7.8125 5.4375ZM7.625 9.12852C7.15625 8.86641 6.84453 8.50547 6.62422 7.97266C6.72969 7.5752 6.89609 6.97148 6.76953 6.59336C6.65938 5.96172 5.77578 6.02402 5.64922 6.44727C5.53203 6.84043 5.63984 7.39473 5.83906 8.10156C5.56719 8.69453 5.16641 9.48945 4.88281 9.94492C4.88047 9.94492 4.88047 9.94707 4.87813 9.94707C4.24297 10.2457 3.15313 10.9031 3.60078 11.408C3.73203 11.5562 3.97578 11.6229 4.10469 11.6229C4.52422 11.6229 4.94141 11.2361 5.53672 10.2951C6.14141 10.1125 6.80469 9.88477 7.38828 9.79668C7.89687 10.0502 8.49219 10.2156 8.88828 10.2156C9.57266 10.2156 9.61953 9.52812 9.35 9.2832C9.02422 8.99102 8.07734 9.0748 7.625 9.12852ZM10.8359 4.25586L8.53906 2.15039C8.43359 2.05371 8.29063 2 8.14062 2H8V4.75H11V4.61895C11 4.48359 10.9414 4.35254 10.8359 4.25586ZM9.09922 9.74082C9.19531 9.68281 9.04063 9.48516 8.09609 9.54746C8.96563 9.88691 9.09922 9.74082 9.09922 9.74082Z"
					fill="#101828"
				/>
			</svg>
		</div>
	);
};
