export const salesInputItems: Array<{
	id: number;
	name: "category" | "salesStartDate" | "salesEndDate" | "salesHeadliner" | "venue" | "eventStartDate" | "eventEndDate";
	type: "text" | "date";
	placeholder: string;
	showType: "input" | "select" | "date";
}> = [
	{
		id: 1,
		name: "category",
		type: "text",
		placeholder: "Category",
		showType: "input",
	},
	{
		id: 2,
		name: "salesStartDate",
		type: "date",
		placeholder: "Sales Start",
		showType: "date",
	},
	{
		id: 3,
		name: "salesEndDate",
		type: "date",
		placeholder: "Sales End",
		showType: "date",
	},
	{
		id: 4,
		name: "salesHeadliner",
		type: "text",
		placeholder: "Headliner",
		showType: "input",
	},
	{
		id: 4,
		name: "venue",
		type: "text",
		placeholder: "Venue",
		showType: "input",
	},
	{
		id: 6,
		name: "eventStartDate",
		type: "date",
		placeholder: "Events End",
		showType: "date",
	},
	{
		id: 7,
		name: "eventEndDate",
		type: "date",
		placeholder: "Events End",
		showType: "date",
	},
];
