import { getThunkAndSlice } from "@src/store/getThunkAndSlice";
import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { ApiProvider } from "../ApiProvider";

export interface ListingsItem {
	Item_ID: number;
	Listing_ID: number;
	Event_Name: string;
	Event_Date: Date;
	Event_Time: string;
	Venue_Name: string;
	Seat_Number: number;
	Section: string;
	Row: string;
	Barcode: string;
	Vendor_Ticket_ID: string;
	Item_Status_ID: number;
	IsPdfAttached: boolean;
	Is_Odd_Even: boolean;
	InHand: boolean;
	InHandDate?: Date;
}

export interface ListingsExtendedItem extends ListingsItem {
	On_Hold: boolean;
	Consignor_Hold: boolean;
	On_Hold_Comments: string;
}

const initialState = new Array<ListingsItem>();

export const {
	thunk: getListingsItems,
	slice: hiddenGetListingsItemsSlice,
	extraReducers: hiddenExtraReducers,
	pathKey: listingPathKey,
	getCacheKey,
} = getThunkAndSlice<
	{
		userName?: string;
		ListingIds: Array<number>;
	},
	typeof initialState
>({
	initialState,
	path: "/api/Purchasing/GetListingsItems",
	cacheId: "ListingIds",
});

export const getListingsItemsSlice = createSlice({
	name: hiddenGetListingsItemsSlice.name,
	initialState: hiddenGetListingsItemsSlice.getInitialState(),
	reducers: {
		updateListingItems: (state, action: PayloadAction<{ ListingIds: Array<number>; data: Array<ListingsItem> }>) => {
			state.data = action.payload.data;
			ApiProvider.default.setCache(
				listingPathKey,
				getCacheKey({ ListingIds: action.payload.ListingIds }, "ListingIds"),
				state.data,
			);
			state.version++;
		},
	},
	extraReducers: hiddenExtraReducers,
});

interface IListingItems {
	itemsHasChanges: boolean;
}

const listingItemsInitialState: IListingItems = {
	itemsHasChanges: false,
};

export const listingsItemsSlice = createSlice({
	name: "listingsItemsSlice",
	initialState: listingItemsInitialState,
	reducers: {
		setListingItemsHasChanges: (state, action: PayloadAction<{ itemsHasChanges: boolean }>) => {
			return {
				...state,
				...action.payload,
			};
		},
	},
});
