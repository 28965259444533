import { ExclamationIcon } from "@src/assets/general-icons";
import React from "react";
import ReactSwitch from "react-switch";
import { MyTooltip } from "../tooltip/Tooltip";
import { clx } from "../utils/stringUtils";
import styles from "./Switch.module.scss";

interface ISwitch {
	checked: boolean;
	onChange: (checked: boolean, event: React.SyntheticEvent<MouseEvent | KeyboardEvent> | MouseEvent, id: string) => void;
	label?: string;
	onColor?: string;
	offColor?: string;
	tooltip?: string;
	tooltipId?: string;
	isDisabled?: boolean;
}

export const Switch: React.FC<ISwitch> = ({
	checked,
	onChange,
	label,
	tooltip,
	tooltipId,
	isDisabled = false,
	onColor = "#FF8E58",
	offColor = "#cdcdcd",
}) => {
	const settings = {
		uncheckedIcon: false,
		height: 14,
		width: 29,
		checkedIcon: false,
		activeBoxShadow: "none",
		handleDiameter: 10,
		onColor,
		offColor,
		checked,
		onChange,
		disabled: isDisabled,
	};

	const id = (tooltipId ?? tooltip ?? "default").replace(" ", "-") + "switch-id";

	return label ? (
		<label className={clx(styles.main)}>
			<ReactSwitch {...settings} />
			<div className={clx({ [styles.label]: true, [styles.active]: checked })}>{label}</div>
			{tooltip ? (
				<MyTooltip
					id={id}
					popup={<div style={{ fontSize: "12px" }}>{tooltip}</div>}
					elementStyle={{ display: "flex" }}
					element={
						<div>
							<ExclamationIcon />
						</div>
					}
				/>
			) : null}
		</label>
	) : (
		<ReactSwitch {...settings} />
	);
};
