import { getThunkAndSlice } from "@src/store/getThunkAndSlice";
import { pricingActions } from "..";
import { RootState } from "@src/store/store";

const initialState = {
	added: true,
};

export const { thunk: addItemTags, slice: addItemTagsSlice } = getThunkAndSlice<
	{
		ItemIds: Array<number>;
		TagDescriptions: Array<string>;
	},
	typeof initialState
>({
	initialState,
	path: "/api/System/AddItemsTags",
	method: "post",
	onAfterRequest: async (data, params, state, dispatch) => {
		const rootState = state as RootState;
		const eventId = rootState.pricing.selection.event?.Event_ID;
		const inventoryAllState = rootState.pricing.eventInventoryAll.data;
		const newData = inventoryAllState.map((x) => {
			return params.ItemIds.some((i) => i === x.Item_ID) ? { ...x, Tags: params.TagDescriptions.join("|") } : x;
		});

		dispatch(pricingActions.updateInventoryAll({ data: newData, eventId }));

		return data;
	},
});
