import { getThunkAndSlice } from "@src/store/getThunkAndSlice";

interface TItemTagsInitialState {
	listingIds: Array<number>;
}

const initialState = new Array<{
	TagId: number;
	TagDesc: string;
}>();

export const { thunk: getListingTags, slice: getListingTagsSlice } = getThunkAndSlice<TItemTagsInitialState, typeof initialState>(
	{
		initialState,
		method: "post",
		path: "/api/System/GetListingsItemTags",
	},
);
