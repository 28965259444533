import React from "react";
import styles from "./Sidebar.module.scss";

interface ISidebarSectionProps {
	headline: string;
	children: React.ReactNode;
	isExpanded: boolean;
}

export const SidebarSection: React.FC<ISidebarSectionProps> = ({ headline, children, isExpanded }) => {
	return (
		<div className={styles.sectionContainer}>
			<div className={styles.sectionHeadline} data-is-expanded={isExpanded}>
				{headline}
			</div>
			<div className={styles.children}>{children}</div>
		</div>
	);
};
