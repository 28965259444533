import React from "react";
import styles from "@src/components/helpers.module.scss";
import { IIconActiveProps } from "./helpers";

export const PricingIcon: React.FC<IIconActiveProps> = ({ isActive }) => {
	return (
		<div className={styles.iconContainer}>
			<svg width="18" height="16" viewBox="0 0 18 16" fill="none" xmlns="http://www.w3.org/2000/svg">
				<g clipPath="url(#clip0_1130_2391)">
					<path
						d="M0 2V6.66667H0.607143C1.33025 6.66667 1.82143 7.206 1.82143 8C1.82143 8.794 1.33025 9.33333 0.607143 9.33333H0V14H17V9.33333H16.3929C15.6698 9.33333 15.1786 8.794 15.1786 8C15.1786 7.206 15.6698 6.66667 16.3929 6.66667H17V2H0ZM1.21429 3.33333H15.7857V5.45867C14.7445 5.758 13.9643 6.76333 13.9643 8C13.9643 9.23667 14.7445 10.242 15.7857 10.542V12.6667H1.21429V10.5413C2.25554 10.242 3.03571 9.23667 3.03571 8C3.03571 6.76333 2.25554 5.758 1.21429 5.458V3.33333Z"
						fill={isActive ? "var(--activeIcon)" : "var(--inactiveIcon)"}
					/>
					<path
						d="M6.33875 11L6.9075 8.78158L5 7.28947L7.52 7.09211L8.5 5L9.48 7.09211L12 7.28947L10.0925 8.78158L10.6612 11L8.5 9.82368L6.33875 11Z"
						fill={isActive ? "var(--activeIcon)" : "var(--inactiveIcon)"}
					/>
				</g>
				<defs>
					<clipPath id="clip0_1130_2391">
						<rect
							width="17"
							height="16"
							fill={isActive ? "var(--activeIcon)" : "var(--inactiveIcon)"}
							transform="translate(0.5)"
						/>
					</clipPath>
				</defs>
			</svg>
		</div>
	);
};
