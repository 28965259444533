import helperStyles from "@src/components/helpers.module.scss";
import React from "react";

export const DashIcon: React.FC = () => {
	return (
		<div className={helperStyles.iconContainer}>
			<svg width="8" height="2" viewBox="0 0 8 2" fill="none" xmlns="http://www.w3.org/2000/svg">
				<rect y="0.5" width="8" height="1" fill="white" />
			</svg>
		</div>
	);
};
