import { getThunkAndSlice } from "@src/store/getThunkAndSlice";
import { pricingActions } from "..";
import _ from "lodash";
import { RootState } from "@src/store/store";

const initialState = false;
export interface IInvoiceWithCost {
	Invoice_ID: number;
	Item_Cost: number;
}

export const { thunk: updateCostPerTicket, slice: updateCostPerTicketSlice } = getThunkAndSlice<
	{
		UpdatedCosts: Array<IInvoiceWithCost>;
	},
	typeof initialState
>({
	initialState,
	path: "/api/System/UpdateCostPerTicket",
	method: "post",
	onAfterRequest: async (data, params, state, dispatch) => {
		const rootState = state as RootState;
		const priceMap = new Map<number, number>();
		params.UpdatedCosts.forEach((param) => {
			priceMap.set(param.Invoice_ID, Number(param.Item_Cost));
		});
		const newData = rootState.pricing.eventInventoryAll.data.map((event) => {
			const newItemPrice = priceMap.get(event.Invoice_ID || 0);
			return {
				...event,
				Item_Cost: !_.isNil(newItemPrice) && !!event.Invoice_ID ? newItemPrice : event.Item_Cost,
			};
		});
		dispatch(pricingActions.updateInventoryAll({ data: newData }));
		return data;
	},
});
