export const inputItems: Array<{
	id: number;
	name: "eventName" | "eventHeadliner" | "venue" | "startDate" | "endDate" | "smartSearch";
	type: "text" | "date";
	placeholder: string;
	showType: "input" | "select" | "date";
}> = [
	{
		id: 1,
		name: "smartSearch",
		type: "text",
		placeholder: "Smart Search",
		showType: "select",
	},
	{
		id: 2,
		name: "eventName",
		type: "text",
		placeholder: "Event Name",
		showType: "input",
	},
	{
		id: 3,
		name: "eventHeadliner",
		type: "text",
		placeholder: "Headliner",
		showType: "input",
	},
	{
		id: 4,
		name: "venue",
		type: "text",
		placeholder: "Venue",
		showType: "input",
	},
	{
		id: 5,
		name: "startDate",
		type: "date",
		placeholder: "Start Date",
		showType: "date",
	},
	{
		id: 6,
		name: "endDate",
		type: "date",
		placeholder: "End Date",
		showType: "date",
	},
];
