import styles from "@src/components/helpers.module.scss";
import React from "react";

export const ThemeIcon: React.FC = () => {
	return (
		<div className={styles.iconContainer}>
			<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
				<path
					d="M15.1684 10.625C17.0336 10.625 18.675 9.54023 19.375 7.93281C18.8199 8.17422 18.168 8.26914 17.5242 8.26914C16.3192 8.26914 15.1636 7.79047 14.3116 6.93842C13.4595 6.08638 12.9809 4.93075 12.9809 3.72578C12.9809 3.08203 13.0758 2.43008 13.3172 1.875C11.7098 2.575 10.625 4.21641 10.625 6.08164C10.6247 6.45268 10.6698 6.82236 10.7594 7.18242"
					stroke="#8A8C91"
					strokeLinecap="round"
					strokeLinejoin="round"
				/>
				<path
					d="M3.53945 11.9863C3.67001 11.9661 3.79085 11.9051 3.88477 11.8122C3.97868 11.7193 4.04088 11.5991 4.0625 11.4688C4.53477 8.60039 6.62617 6.875 9.0625 6.875C11.3254 6.875 12.8367 8.34961 13.4453 9.91172C13.4844 10.0118 13.5489 10.1 13.6325 10.1677C13.7161 10.2353 13.8158 10.2799 13.9219 10.2973C15.875 10.6156 17.5 11.9199 17.5 14.1875C17.5 16.5078 15.6016 18.125 13.2812 18.125H4.14062C2.20703 18.125 0.625 17.1602 0.625 15.0312C0.625 13.1379 2.13555 12.2102 3.53945 11.9863Z"
					stroke="#8A8C91"
					strokeLinejoin="round"
				/>
			</svg>
		</div>
	);
};
