import { FillPaint, PointLike, VectorSource } from "mapbox-gl";
import { GeoJSONSource, MapboxPolygonFeature, PolygonFeature } from "@src/components/map-view/map.types";
import { FillLayer, MapboxMap, MapStyle } from "react-map-gl";
import turfUnion from "@turf/union";
import { featureCollection } from "@turf/helpers";

export type FeaturesBySection = Map<string, PolygonFeature>;

export const calculateBounds = (mapStyle: MapStyle): [number, number, number, number] | undefined => {
	const busterSource = mapStyle.sources["buster"] as VectorSource | undefined;

	const bounds = busterSource?.bounds;

	if (!bounds || bounds.length !== 4) return undefined;

	return bounds as [number, number, number, number];
};

export const isJustRepeatedLetters = (token: string) => new Set(token.split("")).size === 1;

export const capitalizeToken = (token: string) =>
	isJustRepeatedLetters(token) ? token.toUpperCase() : token.slice(0, 1).toUpperCase() + token.slice(1);

export const normalizeName = (name: string) => {
	const tokens = name.toLowerCase().replaceAll(/-/g, " ").trim().split(" ");

	return tokens.map(capitalizeToken).join(" ");
};

export const mergePolygonFeatures = (...features: Array<PolygonFeature>) => {
	if (!features.length) return undefined;

	return features.reduce(
		(acc: PolygonFeature, feature) =>
			turfUnion(featureCollection([acc, feature]), {
				properties: {
					...feature.properties,
				},
			}) as PolygonFeature,
		features[0],
	);
};

export const queryMapPolygonFeatures = (
	point: PointLike | undefined,
	map: MapboxMap,
	layer: "section" | "row",
	name?: string,
) => {
	const layers = [layer === "section" ? "map-shapes-section" : "map-shapes-row"];

	const filter = ["all", ["==", ["geometry-type"], "Polygon"]];

	if (name) filter.push(["==", ["get", "name"], name]);

	return map.queryRenderedFeatures(point, {
		layers,
		filter,
	}) as Array<MapboxPolygonFeature>;
};

export const groupFeaturesBySection = (mapboxSectionFeatures: Array<MapboxPolygonFeature>) => {
	return mapboxSectionFeatures.reduce<FeaturesBySection>((acc: FeaturesBySection, mapboxSectionFeature) => {
		const normalizedSectionName = normalizeName(mapboxSectionFeature.properties.name);

		const sectionFeature = acc.get(normalizedSectionName);

		if (!sectionFeature) {
			return acc.set(normalizedSectionName, mapboxSectionFeature);
		}

		const mergedSectionFeature = mergePolygonFeatures(sectionFeature, mapboxSectionFeature);

		return mergedSectionFeature ? acc.set(normalizedSectionName, mergedSectionFeature) : acc;
	}, new Map());
};

export const createFillLayer = (id: string, paint: FillPaint, features: PolygonFeature | Array<PolygonFeature>): FillLayer => {
	const source: GeoJSONSource = Array.isArray(features)
		? { type: "geojson", data: { type: "FeatureCollection", features } }
		: { type: "geojson", data: { id, type: "Feature", geometry: features.geometry, properties: features.properties } };

	return {
		id,
		type: "fill",
		paint,
		source,
	};
};
