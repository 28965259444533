import { LogoutIcon, SeparateIcon, StageFrontFullLogoIcon, StageFrontOnlyLogoIcon } from "@src/assets/general-icons";
import { useAuth } from "@src/routes/authProvider";
import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { MixpanelCategory, mixpanelTrack } from "../utils/mixPanelUtils";
import { IItem, mainItems, settings } from "./Items";
import styles from "./Sidebar.module.scss";
import { SidebarItem } from "./SidebarItem";
import { SidebarSection } from "./SidebarSection";

interface ISidebarProps {
	isExpanded: boolean;
	isHidden: boolean;
	toggleExpanded: () => void;
}

export const Sidebar: React.FC<ISidebarProps> = ({ isExpanded, isHidden, toggleExpanded }) => {
	let { pathname } = useLocation();
	const navigate = useNavigate();
	const { logout } = useAuth();

	const handleActiveItem = (label: string) => {
		if (pathname === "/") {
			pathname = "/pricing";
		}
		return label === pathname.slice(1);
	};

	const mapItemsToSidebar = (items: Array<IItem>) => {
		return items.map((item) => {
			const Icon = item.icon;
			const navigateToRoute = () => (item.navigateTo ? navigate(item.navigateTo) : null);
			const isActive = handleActiveItem(item.label);
			const sidebarTrack = () =>
				mixpanelTrack(MixpanelCategory.SIDEBAR_ITEMS_CLICKED, {
					data: item.label,
				});

			const props = {
				isActive,
				Icon,
				navigateToRoute,
				isExpanded,
				...item,
			};

			return <SidebarItem onClick={sidebarTrack} key={item.id} {...props} />;
		});
	};

	const mappedMainItems = mapItemsToSidebar(mainItems);
	const mappedSettings = mapItemsToSidebar(settings);

	return (
		<div className={styles.main} data-is-hidden={isHidden}>
			<div className={styles.top}>{isExpanded ? <StageFrontFullLogoIcon /> : <StageFrontOnlyLogoIcon />}</div>
			<button className={styles.toggleButton} onClick={toggleExpanded}>
				<SeparateIcon />
			</button>

			<SidebarSection headline="Main" isExpanded={isExpanded}>
				{mappedMainItems}
			</SidebarSection>
			<SidebarSection headline="Settings" isExpanded={isExpanded}>
				{mappedSettings}
			</SidebarSection>
			<div style={{ marginTop: "auto", display: "flex", justifyContent: "center" }}>
				<LogoutIcon onClick={logout}></LogoutIcon>
			</div>
		</div>
	);
};
