import helperStyles from "@src/components/helpers.module.scss";
import React from "react";

export const TicketIcon: React.FC = () => {
	return (
		<div className={helperStyles.iconContainer}>
			<svg width="14" height="10" viewBox="0 0 14 10" fill="none" xmlns="http://www.w3.org/2000/svg">
				<path
					d="M8.81836 9.375H0.943359V6.75C1.40749 6.75 1.85261 6.56563 2.1808 6.23744C2.50898 5.90925 2.69336 5.46413 2.69336 5C2.69336 4.53587 2.50898 4.09075 2.1808 3.76256C1.85261 3.43437 1.40749 3.25 0.943359 3.25V0.625H8.81836V2.8125H9.69336V0.625H13.1934V3.25C12.7292 3.25 12.2841 3.43437 11.9559 3.76256C11.6277 4.09075 11.4434 4.53587 11.4434 5C11.4434 5.46413 11.6277 5.90925 11.9559 6.23744C12.2841 6.56563 12.7292 6.75 13.1934 6.75V9.375H9.69336V7.1875H8.81836V9.375ZM8.81836 3.6875V6.3125H9.69336V3.6875H8.81836Z"
					fill="#101828"
				/>
			</svg>
		</div>
	);
};
