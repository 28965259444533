import helperStyles from "@src/components/helpers.module.scss";
import React from "react";

export const CollapseIcon: React.FC = () => {
	return (
		<div className={helperStyles.iconContainer} style={{ cursor: "pointer" }}>
			<svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
				<path
					d="M9.17755 11.5767C9.19033 11.6821 9.31977 11.7269 9.39488 11.6518L10.0932 10.9537L12.2297 13.0879C12.2538 13.1117 12.2862 13.125 12.32 13.125C12.3538 13.125 12.3863 13.1117 12.4103 13.0879L13.0879 12.4122C13.1117 12.3881 13.125 12.3557 13.125 12.3219C13.125 12.2881 13.1117 12.2557 13.0879 12.2317L10.9513 10.0959L11.6529 9.39464C11.6698 9.37766 11.6815 9.35628 11.6869 9.33294C11.6922 9.3096 11.6909 9.28522 11.6831 9.26259C11.6753 9.23995 11.6613 9.21997 11.6426 9.20491C11.624 9.18984 11.6015 9.18031 11.5777 9.17739L9.01775 8.87547C8.93625 8.86589 8.86594 8.93458 8.87552 9.01764L9.17755 11.5767Z"
					fill="#3C4250"
				/>
				<path
					d="M4.82245 11.5767C4.80967 11.6821 4.68023 11.7269 4.60512 11.6518L3.9068 10.9537L1.77026 13.0879C1.74624 13.1117 1.71379 13.125 1.67998 13.125C1.64616 13.125 1.61372 13.1117 1.58969 13.0879L0.912137 12.4122C0.888346 12.3881 0.875 12.3557 0.875 12.3219C0.875 12.2881 0.888346 12.2557 0.912137 12.2317L3.04867 10.0959L2.34714 9.39464C2.33025 9.37766 2.31845 9.35628 2.31311 9.33294C2.30776 9.3096 2.30907 9.28522 2.3169 9.26259C2.32472 9.23995 2.33874 9.21997 2.35737 9.20491C2.37599 9.18984 2.39847 9.18031 2.42225 9.17739L4.98225 8.87547C5.06375 8.86589 5.13406 8.93458 5.12448 9.01764L4.82245 11.5767Z"
					fill="#3C4250"
				/>
				<path
					d="M4.82245 2.42329C4.80967 2.31786 4.68023 2.27313 4.60512 2.34821L3.9068 3.04628L1.77026 0.912124C1.74624 0.888341 1.71379 0.875 1.67998 0.875C1.64616 0.875 1.61372 0.888341 1.58969 0.912124L0.912137 1.58783C0.888346 1.61185 0.875 1.64429 0.875 1.67809C0.875 1.71189 0.888346 1.74432 0.912137 1.76834L3.04867 3.9041L2.34714 4.60536C2.33025 4.62234 2.31845 4.64372 2.31311 4.66706C2.30776 4.6904 2.30907 4.71478 2.3169 4.73741C2.32472 4.76005 2.33874 4.78003 2.35737 4.79509C2.37599 4.81016 2.39847 4.81969 2.42225 4.82261L4.98225 5.12453C5.06375 5.13411 5.13406 5.06542 5.12448 4.98236L4.82245 2.42329Z"
					fill="#3C4250"
				/>
				<path
					d="M9.17755 2.42329C9.19033 2.31786 9.31977 2.27313 9.39488 2.34821L10.0932 3.04628L12.2297 0.912124C12.2538 0.888341 12.2862 0.875 12.32 0.875C12.3538 0.875 12.3863 0.888341 12.4103 0.912124L13.0879 1.58783C13.1117 1.61185 13.125 1.64429 13.125 1.67809C13.125 1.71189 13.1117 1.74432 13.0879 1.76834L10.9513 3.9041L11.6529 4.60536C11.6698 4.62234 11.6815 4.64372 11.6869 4.66706C11.6922 4.6904 11.6909 4.71478 11.6831 4.73741C11.6753 4.76005 11.6613 4.78003 11.6426 4.79509C11.624 4.81016 11.6015 4.81969 11.5777 4.82261L9.01775 5.12453C8.93625 5.13411 8.86594 5.06542 8.87552 4.98236L9.17755 2.42329Z"
					fill="#3C4250"
				/>
			</svg>
		</div>
	);
};
