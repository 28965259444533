import { getThunkAndSlice } from "@src/store/getThunkAndSlice";

const initialState = false;

export interface ITicketUpdate {
	Item_ID: number;
	Barcode: string;
	Vendor_Ticket_ID: string;
	InHand: boolean;
	InHandDate: string;
}

export const { thunk: editTickets, slice: editTicketsSlice } = getThunkAndSlice<
	{
		TicketUpdates: Array<ITicketUpdate>;
	},
	typeof initialState
>({
	initialState,
	path: "/api/System/EditTickets",
	method: "post",
});
