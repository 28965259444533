import React from "react";
import styles from "@src/components/helpers.module.scss";
import { IIconActiveProps } from "./helpers";

export const ReportsIcon: React.FC<IIconActiveProps> = ({ isActive }) => {
	return (
		<div className={styles.iconContainer}>
			<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
				<path
					d="M6.66667 7.49967C6.20643 7.49967 5.83333 7.87277 5.83333 8.33301C5.83333 8.79325 6.20643 9.16634 6.66667 9.16634H13.3333C13.7936 9.16634 14.1667 8.79325 14.1667 8.33301C14.1667 7.87277 13.7936 7.49967 13.3333 7.49967H6.66667Z"
					fill={isActive ? "var(--activeIcon)" : "var(--inactiveIcon)"}
				/>
				<path
					d="M6.66667 10.833C6.20643 10.833 5.83333 11.2061 5.83333 11.6663C5.83333 12.1266 6.20643 12.4997 6.66667 12.4997H10C10.4602 12.4997 10.8333 12.1266 10.8333 11.6663C10.8333 11.2061 10.4602 10.833 10 10.833H6.66667Z"
					fill={isActive ? "var(--activeIcon)" : "var(--inactiveIcon)"}
				/>
				<path
					d="M6.66667 14.1663C6.20643 14.1663 5.83333 14.5394 5.83333 14.9997C5.83333 15.4599 6.20643 15.833 6.66667 15.833H13.3333C13.7936 15.833 14.1667 15.4599 14.1667 14.9997C14.1667 14.5394 13.7936 14.1663 13.3333 14.1663H6.66667Z"
					fill={isActive ? "var(--activeIcon)" : "var(--inactiveIcon)"}
				/>
				<path
					fillRule="evenodd"
					clipRule="evenodd"
					d="M17.399 4.281C17.3319 4.11869 17.2335 3.97123 17.1093 3.84709L14.4863 1.22412C14.2365 0.974 13.8976 0.833319 13.5441 0.833008H3.83333C3.47971 0.833008 3.14057 0.973484 2.89052 1.22353C2.64048 1.47358 2.5 1.81272 2.5 2.16634V17.833C2.5 18.0081 2.53449 18.1815 2.60149 18.3433C2.6685 18.505 2.76671 18.652 2.89052 18.7758C3.01433 18.8996 3.16132 18.9978 3.32309 19.0648C3.48486 19.1319 3.65824 19.1663 3.83333 19.1663H16.1667C16.3418 19.1663 16.5151 19.1319 16.6769 19.0648C16.8387 18.9978 16.9857 18.8996 17.1095 18.7758C17.2333 18.652 17.3315 18.505 17.3985 18.3433C17.4655 18.1815 17.5 18.0081 17.5 17.833V4.7916L16.6667 4.79134L17.5 4.79338L17.5 4.7916C17.5002 4.61642 17.4659 4.44291 17.399 4.281ZM12.5 4.58301C12.5 5.27336 13.0596 5.83301 13.75 5.83301H15.8333V17.4997H4.16667V2.49967H12.5V4.58301ZM15.0715 4.16634L14.1667 3.26152V4.16634H15.0715Z"
					fill={isActive ? "var(--activeIcon)" : "var(--inactiveIcon)"}
				/>
			</svg>
		</div>
	);
};
