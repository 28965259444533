import * as yup from "yup";

export type SalesFiltersProps = yup.InferType<typeof SalesHeaderFiltersValidation>;

export const SalesHeaderFiltersValidation = yup.object().shape({
	companyName: yup.string().optional(),
	smartSearch: yup.string(),
	tags: yup.string(),
	category: yup.string(),
	salesHeadliner: yup.string(),
	venue: yup.string(),
	eventStartDate: yup.string(),
	eventEndDate: yup.string(),
	marketplace: yup.string(),
	paymentStartDate: yup.string(),
	paymentEndDate: yup.string(),
	salesStatus: yup.string(),
	salesStartDate: yup.string(),
	salesEndDate: yup.string(),

	section: yup.string(),
	row: yup.string(),
	minQuantity: yup.string(),

	invoiceId: yup.string(),
	eventId: yup.string(),
	buyerPOId: yup.string(),
});
