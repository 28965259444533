import helperStyles from "@src/components/helpers.module.scss";
import React from "react";

export const HiddenIcon: React.FC = () => {
	return (
		<div className={helperStyles.iconContainer}>
			<svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
				<path
					d="M7.81348 3.19106C7.54642 3.16217 7.27506 3.14562 7 3.1415C5.79804 3.14683 4.55206 3.4392 3.37268 3.99942C2.497 4.43251 1.644 5.04392 0.903198 5.79811C0.539373 6.18311 0.0750283 6.74057 0 7.33534C0.00886667 7.85056 0.561785 8.48649 0.903198 8.87259C1.59787 9.59716 2.42866 10.1911 3.37268 10.6713C3.40477 10.6869 3.43696 10.7022 3.46924 10.7174L2.59339 12.2469L3.78345 12.9503L10.2166 1.7499L9.07114 1.0498L7.81348 3.19106ZM10.5299 3.95498L9.65575 5.46998C10.0579 5.99243 10.2966 6.63671 10.2966 7.33534C10.2966 9.07667 8.82054 10.4884 6.99914 10.4884C6.9204 10.4884 6.84409 10.4799 6.76671 10.4747L6.18822 11.4762C6.45502 11.5048 6.72477 11.5256 6.99999 11.5292C8.20309 11.5238 9.44837 11.228 10.6265 10.6713C11.5021 10.2382 12.356 9.62678 13.0968 8.87259C13.4606 8.4876 13.9249 7.93013 14 7.33534C13.9911 6.82013 13.4382 6.1842 13.0968 5.7981C12.4021 5.07353 11.5705 4.47962 10.6265 3.99939C10.5946 3.98395 10.562 3.97005 10.5299 3.95498ZM6.99915 4.18228C7.07902 4.18228 7.15826 4.18548 7.23669 4.19082L6.55908 5.36403C5.60808 5.55684 4.89453 6.36597 4.89453 7.3345C4.89453 7.5778 4.93934 7.81072 5.02185 8.02662C5.02194 8.02687 5.02175 8.02724 5.02185 8.02749L4.34252 9.20412C3.93942 8.68118 3.70165 8.03478 3.70165 7.33533C3.70166 5.59402 5.17776 4.18227 6.99915 4.18228ZM8.97045 6.65602L7.44349 9.30153C8.38937 9.1051 9.09778 8.29966 9.09778 7.3345C9.09778 7.09564 9.05011 6.86854 8.97045 6.65602Z"
					fill="#101828"
				/>
			</svg>
		</div>
	);
};
