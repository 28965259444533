import { getThunkAndSlice } from "@src/store/getThunkAndSlice";
import { pricingActions } from "..";
import { RootState } from "@src/store/store";

const initialState = false;
export interface IListingToUpdate {
	NewSection: string;
	NewRow: string;
	ListingIds: Array<number>;
	SectionLocked: boolean;
	RowLocked: boolean;
	SeatLocked: boolean;
}
export const { thunk: updateListingPosition, slice: updateListingPositionSlice } = getThunkAndSlice<
	IListingToUpdate,
	typeof initialState
>({
	initialState,
	path: "/api/Pricing/UpdateListingPosition",
	method: "post",
	onAfterRequest: async (data, params, state, dispatch) => {
		const rootState = state as RootState;
		const listingIds = new Set<number>(params.ListingIds);

		const newData = rootState.pricing.eventInventoryAll.data.map((event) => {
			return listingIds.has(event.Listing_ID)
				? {
						...event,
						Section: params.NewSection,
						Row: params.NewRow,
						Section_Locked: event.Section_Locked,
						Row_Locked: event.Row_Locked,
						Seat_Locked: event.Seat_Locked,
					}
				: event;
		});
		dispatch(pricingActions.updateInventoryAll({ data: newData }));
		return data;
	},
});
