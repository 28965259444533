import React from "react";
import styles from "./AllFilters.module.scss";

interface IGridSection {
	headline: string;
	children: React.ReactNode;
}

export const GridSection: React.FC<IGridSection> = ({ headline, children }) => {
	return (
		<div>
			<div className={styles.gridContainerHeadline}>{headline}</div>
			<div className={styles.gridContainer}>{children}</div>
		</div>
	);
};
