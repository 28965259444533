import { getThunkAndSlice } from "@src/store/getThunkAndSlice";

const initialState = new Array<{
	Cust_ID: number;
	Name: string;
	Email_Address: string | null;
	Phone: string | null;
	Is_Exchange: boolean;
}>();

export const { thunk: listCustomers, slice: listCustomersSlice } = getThunkAndSlice<Record<string, never>, typeof initialState>({
	initialState,
	path: "/api/Sales/ListCustomers",
});
