import { getThunkAndSlice } from "@src/store/getThunkAndSlice";
import { pricingActions } from "..";
import { RootState } from "@src/store/store";

const initialState = false;

export const { thunk: disableFlashSale, slice: disableFlashSaleSlice } = getThunkAndSlice<Array<number>, typeof initialState>({
	initialState,
	path: "/api/Pricing/DisableFlashSale",
	method: "post",
	onAfterRequest: async (data, params, state, dispatch) => {
		const rootState = state as RootState;
		const eventId = rootState.pricing.selection.event?.Event_ID;
		const inventoryAllState = rootState.pricing.eventInventoryAll.data;
		const newData = inventoryAllState.map((x) => {
			return x.Listing_ID === params[0] ? { ...x, FlashSaleEnabled: null, FlashSaleDiscount: null } : x;
		});

		dispatch(pricingActions.updateInventoryAll({ data: newData, eventId }));

		return data;
	},
});
