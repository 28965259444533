import { createSlice } from "@reduxjs/toolkit";
import { ISection } from "@src/components/mapboxGL/helpers";

export interface MapSliceType {
	data: Array<ISection>;
	rawSections: Array<ISection["sectionName"]>;
}

const initialState: MapSliceType = {
	data: [],
	rawSections: [],
};

export const mapSlice = createSlice({
	name: "map",
	initialState,
	reducers: {
		toggleSection: (_state, action) => {
			const newData = action.payload;
			const rawSections = newData.map((item: ISection) => item.sectionName);

			const uniqueRawSections = rawSections.filter((i: ISection, ind: number, arr: Array<ISection>) => arr.indexOf(i) === ind);

			return {
				data: newData,
				rawSections: uniqueRawSections,
			};
		},
		resetSections: (state) => {
			state.data = [];
			state.rawSections = [];
		},
	},
});

export const { toggleSection, resetSections } = mapSlice.actions;
