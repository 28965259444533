// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.PricingHeader-module_form___LtAlr {
  display: flex;
  gap: 1.2rem;
  align-items: center;
  min-width: 0;
}
.PricingHeader-module_form___LtAlr .PricingHeader-module_buttonContainer___mFcdN {
  display: flex;
  gap: 1.2rem;
  align-items: center;
}
.PricingHeader-module_form___LtAlr .PricingHeader-module_buttonContainer___mFcdN .PricingHeader-module_filterButton___yb_lR {
  display: flex;
  gap: 0.4rem;
  align-items: center;
  justify-content: center;
}

.PricingHeader-module_inputWrapper___cj8CJ {
  width: 268px;
}
.PricingHeader-module_inputWrapper___cj8CJ[data-type=date] {
  width: 126px;
}
.PricingHeader-module_inputWrapper___cj8CJ .PricingHeader-module_smartSearchWrapper___AS2et {
  width: 100%;
}

.PricingHeader-module_companyhWrapper___VfZ2l {
  flex-shrink: 1;
  width: 268px;
}`, "",{"version":3,"sources":["webpack://./src/components/header/components/pricingHeader/PricingHeader.module.scss"],"names":[],"mappings":"AAAA;EACC,aAAA;EACA,WAAA;EACA,mBAAA;EACA,YAAA;AACD;AACC;EACC,aAAA;EACA,WAAA;EACA,mBAAA;AACF;AACE;EACC,aAAA;EACA,WAAA;EACA,mBAAA;EACA,uBAAA;AACH;;AAIA;EACC,YAAA;AADD;AAGC;EACC,YAAA;AADF;AAIC;EACC,WAAA;AAFF;;AAMA;EACC,cAAA;EACA,YAAA;AAHD","sourcesContent":[".form {\r\n\tdisplay: flex;\r\n\tgap: 1.2rem;\r\n\talign-items: center;\r\n\tmin-width: 0;\r\n\r\n\t.buttonContainer {\r\n\t\tdisplay: flex;\r\n\t\tgap: 1.2rem;\r\n\t\talign-items: center;\r\n\r\n\t\t.filterButton {\r\n\t\t\tdisplay: flex;\r\n\t\t\tgap: 0.4rem;\r\n\t\t\talign-items: center;\r\n\t\t\tjustify-content: center;\r\n\t\t}\r\n\t}\r\n}\r\n\r\n.inputWrapper {\r\n\twidth: 268px;\r\n\r\n\t&[data-type=\"date\"] {\r\n\t\twidth: 126px;\r\n\t}\r\n\r\n\t.smartSearchWrapper {\r\n\t\twidth: 100%;\r\n\t}\r\n}\r\n\r\n.companyhWrapper {\r\n\tflex-shrink: 1;\r\n\twidth: 268px;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"form": `PricingHeader-module_form___LtAlr`,
	"buttonContainer": `PricingHeader-module_buttonContainer___mFcdN`,
	"filterButton": `PricingHeader-module_filterButton___yb_lR`,
	"inputWrapper": `PricingHeader-module_inputWrapper___cj8CJ`,
	"smartSearchWrapper": `PricingHeader-module_smartSearchWrapper___AS2et`,
	"companyhWrapper": `PricingHeader-module_companyhWrapper___VfZ2l`
};
export default ___CSS_LOADER_EXPORT___;
