import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AxiosError } from "axios";
import { ApiProvider } from "../ApiProvider";

interface Marketplace {
	Exchange_Name: string;
	Customer_ID: number;
	Show_On_Invoice: boolean;
	Supports_Electronic_Transfer: boolean;
	Requires_Transfer_Proof: boolean;
	ExplanationTip: string;
}

interface ListMarketplacesState {
	marketplaces: Array<Marketplace>;
	loading: boolean;
	error: string | null;
}

const initialState: ListMarketplacesState = {
	marketplaces: [],
	loading: false,
	error: null,
};

export const getListMarketplaces = createAsyncThunk<Array<Marketplace>, undefined, { rejectValue: string }>(
	"listMarketplaces/getListMarketplaces",
	async (_, { rejectWithValue }) => {
		try {
			const response = await ApiProvider.default.get<Array<Marketplace>>("/api/Sales/ListExchanges");
			return response;
		} catch (error) {
			if (error instanceof AxiosError) return rejectWithValue(error.message);
			return rejectWithValue("Unknown error");
		}
	},
);

export const listMarketplacesSlice = createSlice({
	name: "listMarketplaces",
	initialState,
	reducers: {},
	extraReducers: (builder) => {
		builder
			.addCase(getListMarketplaces.pending, (state) => {
				state.loading = true;
				state.error = null;
			})
			.addCase(getListMarketplaces.fulfilled, (state, action: PayloadAction<Array<Marketplace>>) => {
				state.loading = false;
				state.marketplaces = action.payload;
			})
			.addCase(getListMarketplaces.rejected, (state, action) => {
				state.loading = false;
				state.error = action.payload ?? "Failed to fetch marketplaces";
			});
	},
});
