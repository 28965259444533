import helperStyles from "@src/components/helpers.module.scss";
import React from "react";

export const OkIcon: React.FC<{ color?: string }> = ({ color = "white" }) => {
	return (
		<div className={helperStyles.iconContainer}>
			<svg color={color} width="12" height="9" viewBox="0 0 12 9" fill="none" xmlns="http://www.w3.org/2000/svg">
				<path
					d="M10.6663 1.5L4.24967 7.91667L1.33301 5"
					stroke={color}
					stroke-opacity="0.96"
					stroke-width="1.66667"
					stroke-linecap="round"
					stroke-linejoin="round"
				/>
			</svg>
		</div>
	);
};
