import { Loader } from "@src/components/loader/Loader";
import React, { forwardRef, MouseEventHandler, useEffect, useImperativeHandle, useState } from "react";
import { clx } from "../utils/stringUtils";
import styles from "./Button.module.scss";

export enum ButtonVariant {
	Primary = "primary",
	Secondary = "secondary",
	Tertiary = "tertiary",
	Quaternary = "quaternary",
	Quinary = "quinary",
}

export interface IButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
	children: React.ReactNode;
	style?: React.CSSProperties;
	controlSize?: "m" | "sm" | "xsm";
	isLoading?: boolean;
	variant?: ButtonVariant;
	className?: string;
	onClick?: MouseEventHandler<HTMLButtonElement>;
	disabled?: boolean;
	type?: "button" | "submit" | "reset";
}
export interface ButtonRef {
	setLoading: (loading: boolean) => void;
}
export const Button = forwardRef<ButtonRef, IButtonProps>(
	(
		{
			children,
			style,
			controlSize = "m",
			isLoading = false,
			variant = ButtonVariant.Primary,
			className = "",
			onClick,
			disabled,
			type = "button",
			...rest
		},
		ref,
	) => {
		const [state, setState] = useState({
			isLoading: isLoading,
		});

		useEffect(() => {
			setState({ isLoading });
		}, [isLoading]);

		const loaderColor = variant === ButtonVariant.Primary || variant === ButtonVariant.Secondary ? "white" : "black";

		useImperativeHandle(ref, () => ({
			setLoading: (isLoading: boolean) => setState((x) => ({ ...x, isLoading })),
		}));

		return (
			<button
				style={style}
				onClick={(e) => {
					if (state.isLoading) return;
					onClick?.(e);
				}}
				disabled={disabled}
				type={type}
				{...rest}
				className={clx({
					[styles.buttonMainStyle]: true,
					[styles[variant]]: true,
					[className]: true,
					[styles.m]: controlSize === "m",
					[styles.sm]: controlSize === "sm",
					[styles.xsm]: controlSize === "xsm",
				})}>
				{state.isLoading ? <Loader controlSize={controlSize} color={loaderColor} /> : children}
			</button>
		);
	},
);
