import { getThunkAndSlice } from "@src/store/getThunkAndSlice";

export interface IItemHistory {
	ItemId: number;
	ListingId: number;
	Section: string;
	Row: string;
	Seat: string;
	UserName: string;
	Activity: string;
	ActivityType: string;
	CreatedDate: Date;
}

const initialState = new Array<IItemHistory>();

export const { thunk: viewItemHistory, slice: viewItemHistorySlice } = getThunkAndSlice<
	{ itemIds: Array<number> },
	typeof initialState
>({
	initialState: initialState,
	path: "/api/System/ViewItemHistory",
});
