import { fromType } from "@src/store/helpers";
import { getThunkAndSlice } from "@src/store/getThunkAndSlice";

interface TicketGroupSeats {
	HighSeat: string;
	LowSeat: string;
	Row: string;
	Section: string;
}

interface IdDescription {
	Id: number;
	Description: string;
}
interface NearTerm {
	NearTermDeliveryMethod: IdDescription;
	NearTermDisplay: IdDescription;
}
interface Money {
	CurrencyCode: string;
	Value: number;
}
interface TicketGroupUnitPrice {
	FacePrice: Money; // Assuming Money is already defined
	RetailPrice: Money;
	WholesalePrice: Money;
}

interface TicketGroup {
	AvailableQuantity: number;
	DeliveryMethods: Array<string>;
	EventId: number;
	ExchangeTicketGroupId: number;
	Mine: boolean;
	OnHandDate: Date;
	Notes: string;
	PurchasableQuantities: Array<number>;
	Seats: TicketGroupSeats;
	NearTerm: NearTerm;
	UnitPrice: TicketGroupUnitPrice;
	TicketGroupType: IdDescription;
}

interface GetTicketGroupsResponse {
	TicketGroups: Array<TicketGroup>;
	TotalCount: number;
}

const initialState = fromType<GetTicketGroupsResponse>({
	TicketGroups: [],
	TotalCount: 0,
});

export const { thunk: getEventListings, slice: getEventListingsSlice } = getThunkAndSlice<
	{
		eventId: number;
	},
	typeof initialState
>({
	initialState,
	path: (params) => `/api/Marketplace/SeatGeek/Event/${params.eventId}/Listings`,
});
