import { getThunkAndSlice } from "@src/store/getThunkAndSlice";
import { pricingActions } from "..";
import _ from "lodash";
import { RootState } from "@src/store/store";

const initialState = false;
export interface IListingToUpdate {
	CostUpdates: Array<{
		cost: number;
		listing_id: number;
	}>;
	CostLocked: boolean;
}
export const { thunk: updateListingCosts, slice: updateListingCostsSlice } = getThunkAndSlice<
	IListingToUpdate,
	typeof initialState
>({
	initialState,
	path: "/api/Pricing/UpdateListingCosts",
	method: "post",
	onAfterRequest: async (data, params, state, dispatch) => {
		const rootState = state as RootState;
		const costMap = new Map<number, number>();
		params.CostUpdates.forEach((param) => {
			costMap.set(param.listing_id, Number(param.cost));
		});
		const newData = rootState.pricing.eventInventoryAll.data.map((event) => {
			const newItemCost = costMap.get(event.Listing_ID);
			return {
				...event,
				Item_Cost: !_.isNil(newItemCost) ? newItemCost : event.Item_Cost,
			};
		});
		dispatch(pricingActions.updateInventoryAll({ data: newData }));
		return data;
	},
});
