import helperStyles from "@src/components/helpers.module.scss";
import React from "react";

export const PenIcon: React.FC = () => {
	return (
		<div className={helperStyles.iconContainer}>
			<svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
				<path
					d="M12.2429 4.11737C12.4704 3.88987 12.4704 3.51071 12.2429 3.29487L10.8779 1.92987C10.6621 1.70237 10.2829 1.70237 10.0554 1.92987L8.98211 2.99737L11.1696 5.18487M1.91211 10.0732V12.2607H4.09961L10.5513 5.80321L8.36378 3.61571L1.91211 10.0732Z"
					fill="#6E8AC1"
				/>
			</svg>
		</div>
	);
};
