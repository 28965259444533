// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.GridSection-module_root___v0L_d .GridSection-module_gridContainerHeadline___OQnwU {
  font-size: 1.2rem;
  padding-bottom: 8px;
  font-weight: 500;
}
.GridSection-module_root___v0L_d .GridSection-module_gridContainer___tw4nK {
  display: grid;
  grid-template-columns: repeat(var(--number-of-items), 1fr);
  gap: 16px;
}`, "",{"version":3,"sources":["webpack://./src/components/filtersModal/GridSection.module.scss"],"names":[],"mappings":"AACC;EACC,iBAAA;EACA,mBAAA;EACA,gBAAA;AAAF;AAGC;EACC,aAAA;EACA,0DAAA;EACA,SAAA;AADF","sourcesContent":[".root {\r\n\t.gridContainerHeadline {\r\n\t\tfont-size: 1.2rem;\r\n\t\tpadding-bottom: 8px;\r\n\t\tfont-weight: 500;\r\n\t}\r\n\r\n\t.gridContainer {\r\n\t\tdisplay: grid;\r\n\t\tgrid-template-columns: repeat(var(--number-of-items), 1fr);\r\n\t\tgap: 16px;\r\n\t}\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": `GridSection-module_root___v0L_d`,
	"gridContainerHeadline": `GridSection-module_gridContainerHeadline___OQnwU`,
	"gridContainer": `GridSection-module_gridContainer___tw4nK`
};
export default ___CSS_LOADER_EXPORT___;
