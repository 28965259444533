import { getThunkAndSlice } from "@src/store/getThunkAndSlice";
import { pricingActions } from "..";
import { RootState } from "@src/store/store";

const initialState = false;

export const { thunk: removeAutopricer, slice: removeAutopricerSlice } = getThunkAndSlice<
	{ listingIds: Array<number> },
	typeof initialState
>({
	initialState,
	path: "api/AutoPricer/RemoveListingRules",
	method: "delete",
	onAfterRequest: async (data, params, state, dispatch) => {
		const rootState = state as RootState;
		const eventId = rootState.pricing.selection.event?.Event_ID;
		const inventoryAllState = rootState.pricing.eventInventoryAll.data;
		const newData = inventoryAllState.map((x) => {
			return x.Listing_ID === params.listingIds[0]
				? { ...x, Is_AutoPriced: false, Is_At_Floor: false, Is_At_Ceiling: false, Is_Error: false, Is_No_Comparables: false }
				: x;
		});

		dispatch(pricingActions.updateInventoryAll({ data: newData, eventId }));

		return data;
	},
});
