import helperStyles from "@src/components/helpers.module.scss";
import React from "react";

export const SeatGeek: React.FC = () => {
	return (
		<div className={helperStyles.iconContainer}>
			<svg xmlns="http://www.w3.org/2000/svg" viewBox="-320.5 497 64 64" width="100%" height="100%" fill="#1673e6">
				<path d="M-309.52 509.864s8.027-5.266 21.02-5.266 21.02 5.266 21.02 5.266l-5.266 27.912h-31.53zm0 34.076s8.177 3.746 21.02 3.746 21.02-3.746 21.02-3.746v5.95s-8.326 3.5-21.02 3.5-21.02-3.5-21.02-3.5zm-.48-3.852v-9.932c0-1.605-1.306-2.9-2.9-2.9h-7.6v5.266h4.902v7.6H-310zm43 0v-9.932c0-1.605 1.306-2.9 2.9-2.9h7.6v5.266h-4.902v7.6H-267z" />
			</svg>
		</div>
	);
};
