export const formatCurrency = (number: number) => {
	number = Number(number) || 0;
	const formatter = new Intl.NumberFormat("en-US", {
		style: "currency",
		currency: "USD",
		minimumFractionDigits: 2,
	});

	return formatter.format(number);
};

export const cleanNumber = (text: string) => {
	const numericValue = parseFloat(text.replace(/[^0-9.]/g, ""));
	return numericValue;
};

export const fixDecimals = (num: number, numberOfDecimals: number | undefined = 2) => {
	return +num.toFixed(numberOfDecimals);
};
