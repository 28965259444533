import styles from "@src/components/helpers.module.scss";
import React from "react";

export const WarningIcon: React.FC = () => {
	return (
		<div className={styles.iconContainer} style={{ justifyContent: "flex-start", height: "100%" }}>
			<svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
				<path d="M14.0003 0.666992C6.64033 0.666992 0.666992 6.64033 0.666992 14.0003C0.666992 21.3603 6.64033 27.3337 14.0003 27.3337C21.3603 27.3337 27.3337 21.3603 27.3337 14.0003C27.3337 6.64033 21.3603 0.666992 14.0003 0.666992ZM15.3337 20.667H12.667V18.0003H15.3337V20.667ZM15.3337 15.3337H12.667V7.33366H15.3337V15.3337Z" fill="#D60D25" />
			</svg>
		</div>
	);
};
