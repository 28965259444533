import React from "react";
import styles from "@src/components/helpers.module.scss";

export const PartiallyMatched: React.FC = () => {
	return (
		<div className={styles.iconContainer}>
			<svg width="12" height="11" viewBox="0 0 12 11" fill="none" xmlns="http://www.w3.org/2000/svg">
				<path
					d="M11.28 9.41234L6.61977 0.758047C6.28945 0.144453 5.40953 0.144453 5.07895 0.758047L0.419024 9.41234C0.347305 9.54555 0.311348 9.69508 0.314663 9.84632C0.317979 9.99757 0.360453 10.1454 0.437941 10.2753C0.515428 10.4053 0.625281 10.5129 0.756777 10.5877C0.888272 10.6625 1.03692 10.7019 1.1882 10.7021H10.5094C10.6608 10.7022 10.8096 10.6629 10.9413 10.5882C11.073 10.5135 11.183 10.4059 11.2607 10.2759C11.3383 10.1459 11.3809 9.99803 11.3843 9.84667C11.3877 9.6953 11.3517 9.54565 11.28 9.41234ZM5.84949 9.3623C5.74133 9.3623 5.6356 9.33023 5.54567 9.27014C5.45573 9.21005 5.38564 9.12464 5.34425 9.02471C5.30285 8.92478 5.29202 8.81482 5.31313 8.70874C5.33423 8.60266 5.38631 8.50521 5.46279 8.42873C5.53928 8.35225 5.63672 8.30016 5.7428 8.27906C5.84889 8.25796 5.95884 8.26879 6.05877 8.31018C6.1587 8.35157 6.24411 8.42167 6.3042 8.5116C6.36429 8.60153 6.39637 8.70727 6.39637 8.81543C6.39637 8.96047 6.33875 9.09957 6.23619 9.20213C6.13363 9.30469 5.99453 9.3623 5.84949 9.3623ZM6.4434 3.86211L6.28645 7.19805C6.28645 7.31408 6.24035 7.42536 6.1583 7.50741C6.07626 7.58945 5.96498 7.63555 5.84895 7.63555C5.73291 7.63555 5.62163 7.58945 5.53959 7.50741C5.45754 7.42536 5.41145 7.31408 5.41145 7.19805L5.25449 3.86348C5.25097 3.78379 5.26352 3.70422 5.2914 3.62949C5.31928 3.55476 5.36192 3.48641 5.41678 3.42851C5.47165 3.37062 5.5376 3.32436 5.61072 3.29249C5.68384 3.26063 5.76263 3.24381 5.84238 3.24305H5.84813C5.92843 3.24301 6.0079 3.25923 6.08176 3.29075C6.15561 3.32227 6.22232 3.36843 6.27785 3.42643C6.33338 3.48444 6.37658 3.5531 6.40484 3.62826C6.43311 3.70342 6.44585 3.78353 6.44231 3.86375L6.4434 3.86211Z"
					fill="#F8A905"
				/>
			</svg>
		</div>
	);
};
