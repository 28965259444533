import helperStyles from "@src/components/helpers.module.scss";
import React from "react";

export const AttachIcon: React.FC = () => {
	return (
		<div className={helperStyles.iconContainer}>
			<svg width="9" height="11" viewBox="0 0 9 11" fill="none" xmlns="http://www.w3.org/2000/svg">
				<path
					d="M6.5352 5.14125V2.44858H7.11854V5.14125H6.5352ZM3.3047 8.3245C3.12893 8.21989 2.98776 8.07717 2.8812 7.89633C2.77465 7.71511 2.72137 7.51464 2.72137 7.29492V2.44858H3.3047V8.3245ZM3.9767 10.75C3.09354 10.75 2.3457 10.4399 1.7332 9.81958C1.1207 9.19931 0.814453 8.44739 0.814453 7.56383V2.47133C0.814453 1.85417 1.0264 1.32975 1.45029 0.898083C1.87534 0.466028 2.39645 0.25 3.01362 0.25C3.63079 0.25 4.15151 0.465833 4.57579 0.8975C5.00006 1.32917 5.2122 1.85378 5.2122 2.47133V6.66667H4.62887V2.46025C4.62654 2.00681 4.47059 1.62239 4.16104 1.307C3.85148 0.991611 3.46881 0.833722 3.01304 0.833333C2.56076 0.833333 2.17848 0.992972 1.8662 1.31225C1.55354 1.63153 1.3972 2.01789 1.3972 2.47133V7.56383C1.39526 8.28444 1.64473 8.8983 2.14562 9.40542C2.6469 9.91292 3.25745 10.1667 3.97729 10.1667C4.19351 10.1667 4.39884 10.1426 4.59329 10.0943C4.78773 10.0461 4.97148 9.97378 5.14454 9.87733V10.5248C4.96254 10.6026 4.77354 10.6596 4.57754 10.6957C4.38115 10.7319 4.18087 10.75 3.9767 10.75ZM6.5352 10.1667V8.64067H5.00979V8.05733H6.5352V6.5325H7.11854V8.05792H8.64454V8.64125H7.11854V10.1667H6.5352Z"
					fill="black"
				/>
			</svg>
		</div>
	);
};
