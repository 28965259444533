export const WHITE = "#FFFFFF";
export const QUICK_SILVER_05 = "rgba(160, 159, 167, 0.5)";
export const GAINSBORO = "#dddfe1";
export const SECTION = "#FFF";
export const tableBorder = "#DDDFE1";
export const BLACK = "#000000";
export const APPLE = "#83B643";
export const CADMIUM_ORANGE = "#EB8D35";
export const COLADON_BLUE = "#0178AE";
export const DR_WHITE = "#F9FAFB";
export const MELANCHOLIC_SEA = "#54778e";
export const BLUE_METAL = "#5b646f";
