import helperStyles from "@src/components/helpers.module.scss";
import React from "react";

export const ShareIcon: React.FC = () => {
	return (
		<div className={helperStyles.iconContainer}>
			<svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
				<path
					d="M9.56836 8.38033C9.12503 8.38033 8.72836 8.55533 8.42503 8.82949L4.26586 6.40866C4.29503 6.27449 4.31836 6.14033 4.31836 6.00033C4.31836 5.86033 4.29503 5.72616 4.26586 5.59199L8.37836 3.19449C8.69336 3.48616 9.10753 3.66699 9.56836 3.66699C10.5367 3.66699 11.3184 2.88533 11.3184 1.91699C11.3184 0.948659 10.5367 0.166992 9.56836 0.166992C8.60003 0.166992 7.81836 0.948659 7.81836 1.91699C7.81836 2.05699 7.84169 2.19116 7.87086 2.32533L3.75836 4.72283C3.44336 4.43116 3.02919 4.25033 2.56836 4.25033C1.60003 4.25033 0.818359 5.03199 0.818359 6.00033C0.818359 6.96866 1.60003 7.75033 2.56836 7.75033C3.02919 7.75033 3.44336 7.56949 3.75836 7.27782L7.91169 9.70449C7.88253 9.82699 7.86503 9.95533 7.86503 10.0837C7.86503 11.0228 8.62919 11.787 9.56836 11.787C10.5075 11.787 11.2717 11.0228 11.2717 10.0837C11.2717 9.14449 10.5075 8.38033 9.56836 8.38033Z"
					fill="#101828"
				/>
			</svg>
		</div>
	);
};
