import { getThunkAndSlice } from "@src/store/getThunkAndSlice";
import { pricingActions } from "..";
import { RootState } from "@src/store/store";

const initialState = false;
interface IListingToUpdate {
	Account_Name?: string;
	Credit_Card?: string;
	PO_ID: string;
	Purchaser_Name?: string;
	Vendor?: string;
}

export const { thunk: updatePurchaseInfo, slice: updatePurchaseInfoSlice } = getThunkAndSlice<
	IListingToUpdate,
	typeof initialState
>({
	initialState,
	path: "/api/Purchasing/EditPurchaseInfo",
	method: "post",
	onAfterRequest: async (data, params, state, dispatch) => {
		const rootState = state as RootState;
		const eventId = rootState.pricing.selection.event?.Event_ID;
		const inventoryAllState = rootState.pricing.eventInventoryAll.data;
		const { Credit_Card, PO_ID, Account_Name, Purchaser_Name, Vendor } = params;
		const newData = inventoryAllState.map((x) => {
			return x.PO_ID === +PO_ID ? { ...x, Credit_Card, Account_Name, Purchaser_Name, Vendor } : x;
		});

		dispatch(pricingActions.updateInventoryAll({ data: newData, eventId }));

		return data;
	},
});
