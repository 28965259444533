import { getThunkAndSlice } from "@src/store/getThunkAndSlice";

const initialState = {
	added: true,
};

export const { thunk: addEventTags, slice: addEventTagsSlice } = getThunkAndSlice<
	{
		EventIds: Array<number>;
		TagDescriptions: Array<string>;
	},
	typeof initialState
>({
	initialState,
	path: "/api/System/AddEventsTags",
	method: "post",
});
