import { getThunkAndSlice } from "@src/store/getThunkAndSlice";

const initialState = new Array<{
	Rule_ID: number;
	Rule_Name: string;
}>();

export const { thunk: listSavedRules, slice: listSavedRulesSlice } = getThunkAndSlice<
	{
		venueId: number;
	},
	typeof initialState
>({
	initialState,
	path: "/api/AutoPricer/ListSavedRules",
});
