import helperStyles from "@src/components/helpers.module.scss";
import React from "react";

export const PlusIcon = () => {
	return (
		<div className={helperStyles.iconContainer}>
			<svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
				<g clipPath="url(#clip0_1130_22718)">
					<path
						d="M7 0.875C5.38165 0.894636 3.83511 1.54625 2.69068 2.69068C1.54625 3.83511 0.894636 5.38165 0.875 7C0.894636 8.61835 1.54625 10.1649 2.69068 11.3093C3.83511 12.4537 5.38165 13.1054 7 13.125C8.61835 13.1054 10.1649 12.4537 11.3093 11.3093C12.4537 10.1649 13.1054 8.61835 13.125 7C13.1054 5.38165 12.4537 3.83511 11.3093 2.69068C10.1649 1.54625 8.61835 0.894636 7 0.875ZM10.5 7.4375H7.4375V10.5H6.5625V7.4375H3.5V6.5625H6.5625V3.5H7.4375V6.5625H10.5V7.4375Z"
						fill="#3C4250"
					/>
				</g>
				<defs>
					<clipPath id="clip0_1130_22718">
						<rect width="14" height="14" fill="white" />
					</clipPath>
				</defs>
			</svg>
		</div>
	);
};
