import { HeaderFiltersProps } from "@src/validation/HeaderFiltersValidation";

export const filtersEmptyValues: HeaderFiltersProps = {
	eventName: "",
	eventHeadliner: "",
	venue: "",
	startDate: "",
	endDate: "",
	smartSearch: "",
	tags: "",
	category: "",
	section: "",
	row: "",
	minQuantity: "",
	daysSinceLastUpdate: "",
	listingId: "",
	eventId: "",
	poId: "",
	poStartDate: "",
	poEndDate: "",
};
