import { SortField, fromType } from "@src/store/helpers";
import { getThunkAndSlice } from "@src/store/getThunkAndSlice";

export interface SGInventory {
	Event_Headliner_ID: number;
	Event_Headliner: string;
	SeatGeek_Event_ID: number;
	Is_Odd_Even: boolean;
	Event_ID: number;
	Event_Name: string;
	Event_Date: Date;
	Event_Time: string;
	Cat_ID: number;
	Cat_Desc: string;
	Cat_Type: string;
	Venue_ID: number;
	Venue_Name: string;
	SeatGeek_Listing_ID?: number | null;
	Listing_ID: number;
	Section: string;
	Row: string;
	Quantity: number;
	Seats: string;
	First_Seat: number;
	Last_Seat: number;
	Item_Cost: number;
	Item_Price: number;
	Item_Face: number;
	SG_Price: number;
	SG_eDelivery: boolean;
	Company_Name: string;
	Is_AutoPriced: boolean;
	Is_At_Floor: boolean;
	Is_At_Ceiling?: boolean | null;
	Is_No_Comparables: boolean;
	Floor_Price?: number | null;
	Ceiling_Price?: number | null;
	Allow_Price_Increase?: boolean | null;
	Is_Error: boolean;
	Minutes_Per_Cycle?: number | null;
	AutoPricer_User: string;
	Last_Checked_Date?: Date | null;
	Last_AutoPriced_Date?: Date | null;
	Num_Comparables?: number | null;
	MasterRuleId?: number | null;
	MasterOrder?: number | null;
	MasterRuleName: string;
	Compare_Against_Own_Inventory?: boolean | null;
	Compare_To_Everything?: boolean | null;
	Exclude_Outliers?: number | null;
	Internal_Notes: string;
	External_Notes: string;
	Is_Scheduled_Pricing?: boolean | null;
	Active_Tiers?: number | null;
}

export interface SGPagedInventory {
	itemCount: number;
	Inventory: Array<SGInventory>;
}
const initialState = fromType<SGPagedInventory>({ itemCount: 0, Inventory: [] });

export const { thunk: queryInventory, slice: queryInventorySlice } = getThunkAndSlice<
	{
		EventFromDate?: Date | null;
		EventToDate?: Date | null;
		CategoryId?: number | null;
		EventHeadlinerId?: number | null;
		EventId?: number | null;
		VenueId?: number | null;
		ListingId?: number | null;
		IsAutoPriced?: boolean | null;
		IsAtFloor?: boolean | null;
		IsAtCeiling?: boolean | null;
		IsNoComparables?: boolean | null;
		IsError?: boolean | null;
		Sections?: string | null;
		Row?: string | null;
		Quantity?: number | null;
		AutoPricerUser?: string | null;
		CompanyName?: string | null;
		OrderByColumn?: Array<SortField> | null;
		StartIndex?: number | null;
		Limit?: number | null;
		ForceRefresh?: boolean | null;
	},
	typeof initialState
>({
	initialState,
	path: "/api/AutoPricer/QueryInventory",
	method: "post",
});
