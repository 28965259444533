import React from "react";
import styles from "@src/components/helpers.module.scss";

export const SeparateIcon: React.FC = () => {
	return (
		<div className={styles.iconContainer}>
			<svg width="100%" height="100%" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
				<path
					d="M5.8049 5.13807C6.06525 4.87772 6.06525 4.45561 5.8049 4.19526C5.54455 3.93491 5.12244 3.93491 4.86209 4.19526L1.52876 7.5286C1.26841 7.78894 1.26841 8.21106 1.52876 8.4714L4.86209 11.8047C5.12244 12.0651 5.54455 12.0651 5.8049 11.8047C6.06525 11.5444 6.06525 11.1223 5.8049 10.8619L2.94297 8L5.8049 5.13807Z"
					fill="#C0C0C0"
				/>
				<path
					d="M11.1382 4.19526C10.8779 3.93491 10.4558 3.93491 10.1954 4.19526C9.93507 4.45561 9.93507 4.87772 10.1954 5.13807L13.0574 8L10.1954 10.8619C9.93507 11.1223 9.93507 11.5444 10.1954 11.8047C10.4558 12.0651 10.8779 12.0651 11.1382 11.8047L14.4716 8.4714C14.7319 8.21106 14.7319 7.78894 14.4716 7.5286L11.1382 4.19526Z"
					fill="#C0C0C0"
				/>
			</svg>
		</div>
	);
};
