import { populateMarketplaceIcons } from "@src/components/utils/marketplaceUtils";
import { extractMarketplaceData } from "@src/store/pricing/settingsSlice";
import React from "react";
import styles from "../Inventory.module.scss";

interface TMarketplaces {
	data: ReturnType<typeof extractMarketplaceData>;
}

export const Marketplaces: React.FC<TMarketplaces> = ({ data }) => {
	const icons = populateMarketplaceIcons(data);

	return (
		<div className={styles.marketplaceContainer}>
			<div className={styles.marketplaceIcons}>{icons}</div>
		</div>
	);
};
