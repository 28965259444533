import helperStyles from "@src/components/helpers.module.scss";
import React from "react";

export const ArrowUpIcon: React.FC = () => {
	return (
		<div className={helperStyles.iconContainer}>
			<svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
				<path d="M2 9.5L7 4.5L12 9.5" stroke="#667085" strokeWidth="1.66667" strokeLinecap="round" strokeLinejoin="round" />
			</svg>
		</div>
	);
};
