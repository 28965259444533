import { getThunkAndSlice } from "@src/store/getThunkAndSlice";

const initialState: {
	fileNames: Record<string, string>;
	originalFileName: string;
	success: boolean;
} = {
	fileNames: {},
	originalFileName: "",
	success: false,
};

export const { thunk: splitPDFs, slice: splitPDFsSlice } = getThunkAndSlice<
	{
		ListingId: number;
		attachmentFile: File;
	},
	typeof initialState
>({
	initialState,
	path: "/api/System/SplitPDFs",
	method: "postWithFile",
});
