import { getThunkAndSlice } from "@src/store/getThunkAndSlice";

interface SGLowestComparable {
	SeatGeekId: string;
	SeatGeekListingId: number;
	Section: string;
	Row: string;
	Quantity: number;
	Price: number;
	IsIgnored: boolean;
	IsOwnInventory: boolean;
	IsOutlier: boolean;
}
const initialState = new Array<SGLowestComparable>();

interface ListingIgnore {
	SeatGeekId: string;
	SeatGeekListingId?: number | null;
}
interface SuggestedSection {
	ListingID: number;
	SectionName: string;
}

interface SGRules {
	CompareToEverything: boolean;
	FloorPrice: number;
	CeilingPrice?: number | null;
	TicketSplit: string;
	TraitExclusions: string;
	RowRangeStart: string;
	RowRangeEnd: string;
	AllRows: boolean;
	Position: number;
	AmountUnder: number;
	AllowPriceIncrease: boolean;
	MinutesPerCycle: number;
	Notes: string;
	Sections: Array<string>;
	Rows: Array<string>;
	Ignored_Listings: Array<ListingIgnore>;
	FloorNotification?: boolean | null;
	CeilingNotification?: boolean | null;
	NoComparablesNotification?: boolean | null;
	CompareAgainstOwnInventory: boolean;
	MasterRuleId?: number | null;
	MasterOrder?: number | null;
	Exclude_Outliers?: number | null;
	Delivery_Exclusions: string;
	PctUnder?: number | null;
	UseDefaultSplits?: boolean | null;
	SuggestedSection: string;
	SuggestedSections: Array<SuggestedSection>;
}

interface SGListingRules extends SGRules {
	ListingId: number;
	SeatGeekListingId: number;
}

interface SFListing {
	ListingID: number;
	SeatGeekListingId?: number | null;
	Price: number;
	Row: string;
	Section: string;
	Quantity: number;
	CompanyName: string;
}

export const { thunk: getLowestComparables, slice: getLowestComparablesSlice } = getThunkAndSlice<
	{
		eventId: number;
		rules: SGListingRules;
		listingId?: number | null;
		sf_Listing: SFListing;
		consignorInventory: Array<SFListing>;
	},
	typeof initialState
>({
	initialState,
	path: "/api/Marketplace/SeatGeek/GetLowestComparables",
	method: "post",
});
