import { createSelector } from "@reduxjs/toolkit";
import { getThunkAndSlice } from "@src/store/getThunkAndSlice";
import { fromType } from "@src/store/helpers";
import { RootState } from "@src/store/store";

export interface ActiveListing {
	bp: number;
	dm: "electronic" | "shipped" | "willcall" | "local" | "venue";
	idl: boolean;
	ihd: string;
	q: number;
	r: string;
	s: string;
	st: "pdf" | "mobile" | "paper" | "paperless";
	bo?: boolean;
	ds: number;
	id: string;
	m: string;
	pn: string | null;
	sp: Array<number>;
	sro: boolean;
	wa: boolean;
	sglid: number;
}

export interface EventListing extends ActiveListing {
	mtc: number;
}

interface Venue {
	Address: string;
	City: string;
	Country: string;
	Id: number;
	Name: string;
	State: string;
}

export interface IEvent {
	Category: string;
	DateTbd: boolean;
	DateTimeUtc: string;
	Id: number;
	Name: string;
	Url: string;
	Venue: Venue;
}
export const IEventEmpty: IEvent = {
	Category: "",
	DateTbd: false,
	DateTimeUtc: "",
	Id: 0,
	Name: "",
	Url: "",
	Venue: {
		Address: "",
		City: "",
		Country: "",
		Id: 0,
		Name: "",
		State: "",
	},
};

export enum MatchStatus {
	UNMATCHED = 0,
	USERS_LISTING = 1,
	OTHER_CONSIGNOR_LISTING = 2,
}

export interface ActiveSeatGeekListing {
	oldSeatGeekId: string;
	seatGeekListingId: number;
	section: string;
	row: string;
	quantity: number;
	price: number;
	match: MatchStatus;
	splits: Array<number>;
	instantDelivery: boolean;
	stockType?: ActiveListing["st"];
}

export interface EventListingsResponse {
	Event: IEvent;
	Listings: Array<EventListing>;
	RefreshedAtUtc: string;
}
const initialState = fromType<EventListingsResponse>();
export const { thunk: getActiveListings, slice: getActiveListingsSlice } = getThunkAndSlice<
	{
		eventId: number;
	},
	typeof initialState
>({
	initialState,
	path: ({ eventId }) => `/api/Marketplace/SeatGeek/Event/${eventId}/Listings`,
	cacheId: "eventId",
});

const _selectActiveListings = (state: RootState) => state.marketplace.seatgeek.activeListings.data.Listings;

export const selectActiveListings = createSelector(
	[_selectActiveListings],
	(activeListings) =>
		activeListings?.map<ActiveSeatGeekListing>((eventListing) => ({
			oldSeatGeekId: eventListing.id,
			seatGeekListingId: eventListing.sglid,
			section: eventListing.s,
			row: eventListing.r.length > 0 ? eventListing.r : eventListing.s,
			quantity: eventListing.q,
			price: eventListing.bp,
			match: eventListing.mtc,
			splits: eventListing.sp,
			deliveryMethod: eventListing.dm,
			instantDelivery: eventListing.idl,
			stockType: eventListing.st,
		})) ?? [],
);
