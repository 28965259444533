import React from "react";
import styles from "./ApStatus.module.scss";
import { EventInventoryRecord } from "@src/store/pricing/queryEventInventoryAllSlice";

interface IApStatusType {
	row: EventInventoryRecord;
}

export const ApStatus: React.FC<IApStatusType> = ({ row }) => {
	const options = [
		{ text: "Hit Ceiling", color: "#54C339" },
		{ text: "Hit Floor", color: "#FF2D5F" },
		{ text: "Active", color: "#3F7BF3" },
		{ text: "", color: "" },
	];

	let item = options[3];
	const hasActiveCriteria = row.Is_Error || row.Is_No_Comparables || row.Is_AutoPriced;

	if (hasActiveCriteria && !row.Is_At_Ceiling && !row.Is_At_Ceiling) item = options[2];
	if (row.Is_At_Floor) item = options[1];
	if (row.Is_At_Ceiling) item = options[0];

	return item.text ? (
		<div className={styles.main} style={{ color: item.color }}>
			<div className={styles.circle} />
			<div className={styles.text}>{item.text}</div>
		</div>
	) : null;
};
