import React from "react";
import styles from "@src/components/helpers.module.scss";
import { IIconActiveProps } from "./helpers";

export const NotificationIcon: React.FC<IIconActiveProps> = ({ isActive }) => {
	return (
		<div className={styles.iconContainer}>
			<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
				<path
					fillRule="evenodd"
					clipRule="evenodd"
					d="M9.99843 0.832031C8.43357 0.832031 6.94449 1.49548 5.85467 2.6579C4.76732 3.81774 4.1651 5.38031 4.1651 6.9987C4.1651 9.99575 3.56384 11.8628 3.00648 12.9527C2.727 13.4993 2.45489 13.8577 2.26601 14.0703C2.17136 14.1769 2.09701 14.2475 2.05225 14.2873C2.02986 14.3072 2.01483 14.3194 2.00833 14.3246L2.00587 14.3265C1.7173 14.5379 1.59551 14.9105 1.70458 15.2521C1.81486 15.5976 2.13584 15.832 2.49843 15.832H17.4984C17.861 15.832 18.182 15.5976 18.2923 15.2521C18.4014 14.9105 18.2796 14.5379 17.991 14.3265L17.9885 14.3246C17.982 14.3194 17.967 14.3072 17.9446 14.2873C17.8999 14.2475 17.8255 14.1769 17.7309 14.0703C17.542 13.8577 17.2699 13.4993 16.9904 12.9527C16.433 11.8628 15.8318 9.99575 15.8318 6.9987C15.8318 5.38031 15.2296 3.81775 14.1422 2.65791C13.0526 1.49674 11.5627 0.832031 9.99843 0.832031ZM2.00587 14.3265L2.00833 14.3246L2.00587 14.3265ZM2.00833 14.3246L2.00511 14.3271L2.00833 14.3246ZM15.5065 13.7116C15.5897 13.8743 15.6733 14.0254 15.7563 14.1654H4.24057C4.32352 14.0254 4.40716 13.8743 4.49038 13.7116C5.18303 12.3571 5.83177 10.2241 5.83177 6.9987C5.83177 5.78876 6.28289 4.63799 7.07053 3.79783C7.85737 2.95858 8.91163 2.4987 9.99843 2.4987C11.0841 2.4987 12.1392 2.95916 12.9263 3.79782L14.2165 8.52455C14.383 10.9148 14.9295 12.5833 15.5065 13.7116ZM14.2165 8.52455C14.1832 8.04556 14.1651 7.53711 14.1651 6.9987C14.1651 5.78888 13.7138 4.63795 12.9263 3.79782L14.2165 8.52455Z"
					fill={isActive ? "var(--activeIcon)" : "var(--inactiveIcon)"}
				/>
				<path
					d="M9.27796 17.0812C9.04737 16.6829 8.53755 16.5469 8.13924 16.7775C7.74094 17.0081 7.60498 17.5179 7.83557 17.9162C8.05512 18.2954 8.37051 18.6103 8.75013 18.8292C9.12975 19.048 9.56024 19.1632 9.99843 19.1632C10.4366 19.1632 10.8671 19.048 11.2467 18.8292C11.6264 18.6103 11.9417 18.2954 12.1613 17.9162C12.3919 17.5179 12.2559 17.0081 11.8576 16.7775C11.4593 16.5469 10.9495 16.6829 10.7189 17.0812C10.6458 17.2075 10.5407 17.3124 10.4143 17.3853C10.2878 17.4582 10.1444 17.4966 9.99843 17.4966C9.85246 17.4966 9.70906 17.4582 9.58261 17.3853C9.45615 17.3124 9.35109 17.2075 9.27796 17.0812Z"
					fill={isActive ? "var(--activeIcon)" : "var(--inactiveIcon)"}
				/>
			</svg>
		</div>
	);
};
