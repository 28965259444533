import styles from "@src/components/helpers.module.scss";
import React from "react";

export const DeleteIcon = () => {
	return (
		<div className={styles.iconContainer}>
			<svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
				<path
					d="M4.46318 9.75035H5.21318V3.00035H4.46318V9.75035ZM7.00118 9.75035H7.75118V3.00035H7.00118V9.75035ZM1.60718 12.0004V1.50035H0.857178V0.750351H3.85718V0.172852H8.35718V0.750351H11.3572V1.50035H10.6072V12.0004H1.60718Z"
					fill="#54778E"
				/>
			</svg>
		</div>
	);
};
