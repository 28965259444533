import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AxiosError } from "axios";
import { ApiProvider } from "../ApiProvider";
import { getInitialState } from "../helpers";

export interface IMarkups {
	Listing_ID: number;
	Export_Destination_ID: number;
	AllowedSplits: number;
	Visibility: "SHOW" | "HIDE";
}

export interface IInitialState {
	ids: Array<number>;
	allowedSplits: number;
	visibility: boolean;
}

const initialState = getInitialState<IInitialState>({
	ids: [],
	allowedSplits: -1,
	visibility: false,
});

export const getListingSharedSettings = createAsyncThunk<Array<IMarkups>, Array<number>>(
	"getSharedSettings/getListingSharedSettings",
	async (payload, { rejectWithValue }) => {
		try {
			return await ApiProvider.default.post<Array<IMarkups>>("/api/pricing/getsharedsettings", { listingIds: payload });
		} catch (error) {
			if (error instanceof AxiosError) return rejectWithValue(error.message);
			else return rejectWithValue("Unknown error");
		}
	},
);

export const getSharedSettings = createSlice({
	name: "getSharedSettings",
	initialState,
	reducers: {
		resetAll: (state) => {
			state.data.ids = [];
			state.data.allowedSplits = -1;
			state.data.visibility = false;
		},
		setSavedRuleIds: (state, action: PayloadAction<Array<number>>) => {
			state.data.ids = action.payload;
		},
		editSplits: (state, action) => {
			state.data.allowedSplits = action.payload;
		},
		toggleVisibility: (state) => {
			state.data.visibility = !state.data.visibility;
		},
		toggleRule: (state, action: PayloadAction<number>) => {
			const currentData = state.data.ids;
			const alreadyExists = currentData.includes(action.payload);
			if (alreadyExists) {
				state.data.ids = currentData.filter((i) => i !== action.payload);
			} else {
				state.data.ids.push(action.payload);
			}
		},
	},
	extraReducers: (builder) => {
		builder
			.addCase(getListingSharedSettings.pending, (state) => {
				state.loading = true;
				state.data.ids = [];
				state.data.allowedSplits = -1;
				state.data.visibility = false;
			})
			.addCase(getListingSharedSettings.fulfilled, (state, action) => {
				state.data.ids = action.payload.map((x) => x.Export_Destination_ID);
				state.data.visibility = action.payload[0]?.Visibility === "HIDE";
				state.data.allowedSplits = action.payload[0]?.AllowedSplits;
				state.loading = false;
				state.version++;
				state.init = true;
				state.error = null;
			})
			.addCase(getListingSharedSettings.rejected, (state, action) => {
				state.data.ids = [];
				state.data.allowedSplits = -1;
				state.data.visibility = false;
				state.loading = false;
				state.error = `${action.payload}`;
			});
	},
});
