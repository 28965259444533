import { getThunkAndSlice } from "@src/store/getThunkAndSlice";
import { pricingActions } from "..";
import _ from "lodash";
import { RootState } from "@src/store/store";

const initialState = false;
export interface IListingToUpdate {
	listing_id: number;
	price: number;
	face: null;
}
export const { thunk: updateListingPrices, slice: updateListingPricesSlice } = getThunkAndSlice<
	Array<IListingToUpdate>,
	typeof initialState
>({
	initialState,
	path: "/api/Pricing/UpdateListingPrices",
	method: "post",
	onAfterRequest: async (data, params, state, dispatch) => {
		const rootState = state as RootState;
		const eventId = rootState.pricing.selection.event?.Event_ID;
		const priceMap = new Map<number, number>();

		params.forEach((param) => {
			priceMap.set(param.listing_id, Number(param.price));
		});

		const newData = rootState.pricing.eventInventoryAll.data.map((event) => {
			const newItemPrice = priceMap.get(event.Listing_ID);
			return {
				...event,
				Item_Price: !_.isNil(newItemPrice) && !event.Invoice_ID ? newItemPrice : event.Item_Price,
			};
		});

		dispatch(pricingActions.updateInventoryAll({ data: newData, eventId }));

		return data;
	},
});
