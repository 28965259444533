import { fromType } from "@src/store/helpers";
import { getThunkAndSlice } from "@src/store/getThunkAndSlice";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface MatchedSection {
	SectionName: string;
	MatchStatus: number;
}

export interface MapDataDTO {
	MapDataJSON: string;
	MatchedSections: Array<MatchedSection>;
}
const initialState = fromType<MapDataDTO>({
	MapDataJSON: "",
	MatchedSections: [],
});
export const {
	thunk: getEventMap,
	slice: hiddenGetEventMapSlice,
	extraReducers: hiddenExtraReducers
} = getThunkAndSlice<
	{
		eventId: number;
		venueName: string;
		getMatchedSections: boolean;
	},
	typeof initialState
>({
	initialState,
	path: "/api/Marketplace/SeatGeek/Event/Map",
	cacheId: "eventId",
});
export const getEventMapSlice = createSlice({
	name: hiddenGetEventMapSlice.name,
	initialState: hiddenGetEventMapSlice.getInitialState(),
	reducers: {
		updateEventMap: (state, action: PayloadAction<MapDataDTO>) => {
			state.data = action.payload;
			state.version++;
		},
		reset(state) {
			state.data = initialState;
			state.version++;
		}
	},
	extraReducers: hiddenExtraReducers,
});