import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AxiosError } from "axios";
import { ApiProvider } from "../ApiProvider";

interface Headliner {
	Event_Headliner_ID: number;
	Event_Headliner: string;
	Cat_ID: number;
	Cat_Desc: string | null;
}

interface ListHeadlinersState {
	headliners: Array<Headliner>;
	loading: boolean;
	error: string | null;
}

const initialState: ListHeadlinersState = {
	headliners: [],
	loading: false,
	error: null,
};

export const getListHeadlinersSales = createAsyncThunk<Array<Headliner>, undefined, { rejectValue: string }>(
	"listHeadliners/getListHeadliners",
	async (_, { rejectWithValue }) => {
		try {
			const response = await ApiProvider.default.get<Array<Headliner>>("/api/Sales/ListHeadliners");
			return response;
		} catch (error) {
			if (error instanceof AxiosError) return rejectWithValue(error.message);
			return rejectWithValue("Unknown error");
		}
	},
);

export const listHeadlinersSalesSlice = createSlice({
	name: "listHeadlinersSales",
	initialState,
	reducers: {},
	extraReducers: (builder) => {
		builder
			.addCase(getListHeadlinersSales.pending, (state) => {
				state.loading = true;
				state.error = null;
			})
			.addCase(getListHeadlinersSales.fulfilled, (state, action: PayloadAction<Array<Headliner>>) => {
				state.loading = false;
				state.headliners = action.payload;
			})
			.addCase(getListHeadlinersSales.rejected, (state, action) => {
				state.loading = false;
				state.error = action.payload ?? "Failed to fetch headliners";
			});
	},
});
