import styles from "@src/components/helpers.module.scss";
import React from "react";

interface ISortIcon {
	isDesc?: boolean;
}

export const SortAscIcon: React.FC<ISortIcon> = ({ isDesc }) => {
	return (
		<div className={styles.iconContainer}>
			<svg width="13" height="12" viewBox="0 0 13 12" fill="none" xmlns="http://www.w3.org/2000/svg">
				<path
					d="M10.177 5.43973C10.2506 5.43965 10.3226 5.41789 10.384 5.37716C10.4453 5.33643 10.4934 5.27853 10.522 5.21069C10.5507 5.14285 10.5587 5.06807 10.5452 4.99568C10.5316 4.92329 10.497 4.8565 10.4458 4.80363L8.44504 2.74183C8.41008 2.70586 8.36825 2.67727 8.32204 2.65775C8.27583 2.63823 8.22617 2.62818 8.176 2.6282C8.12584 2.62822 8.07619 2.6383 8.02999 2.65785C7.98379 2.6774 7.94198 2.70602 7.90704 2.74202L5.90776 4.80522C5.85652 4.85812 5.82199 4.92493 5.80848 4.99733C5.79497 5.06973 5.80308 5.1445 5.83179 5.21232C5.86051 5.28014 5.90855 5.33801 5.96994 5.3787C6.03133 5.41939 6.10334 5.4411 6.17698 5.44112L7.80198 5.44056L7.80323 9.00306C7.80326 9.10251 7.8428 9.19788 7.91315 9.26818C7.98351 9.33849 8.0789 9.37796 8.17836 9.37793C8.27781 9.37789 8.37318 9.33835 8.44349 9.268C8.51379 9.19765 8.55326 9.10225 8.55323 9.0028L8.55198 5.4403L10.177 5.43973Z"
					fill={!isDesc ? "black" : "gray"}
				/>
				<path
					d="M6.17738 6.5661C6.25102 6.56613 6.32303 6.58784 6.38442 6.62853C6.44581 6.66922 6.49386 6.72708 6.52257 6.7949C6.55128 6.86272 6.55939 6.93749 6.54588 7.00989C6.53237 7.08229 6.49784 7.14911 6.4466 7.20201L4.44732 9.2652C4.41238 9.3012 4.37057 9.32982 4.32437 9.34937C4.27818 9.36892 4.22852 9.37901 4.17836 9.37902C4.12819 9.37904 4.07853 9.36899 4.03232 9.34948C3.98611 9.32996 3.94428 9.30136 3.90932 9.26539L1.9086 7.20359C1.85732 7.15073 1.82275 7.08393 1.80919 7.01154C1.79562 6.93916 1.80368 6.86438 1.83235 6.79654C1.86101 6.7287 1.90902 6.6708 1.97038 6.63007C2.03174 6.58934 2.10373 6.56757 2.17738 6.5675L3.80238 6.56693L3.80113 3.00443C3.8011 2.90497 3.84058 2.80958 3.91088 2.73923C3.98118 2.66888 4.07655 2.62933 4.176 2.6293C4.27546 2.62926 4.37086 2.66874 4.44121 2.73904C4.51156 2.80934 4.5511 2.90471 4.55113 3.00417L4.55238 6.56667L6.17738 6.5661Z"
					fill={isDesc ? "black" : "gray"}
				/>
			</svg>
		</div>
	);
};
