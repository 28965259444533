import helperStyles from "@src/components/helpers.module.scss";
import React from "react";

export const PasteIcon: React.FC = () => {
	return (
		<div className={helperStyles.iconContainer}>
			<svg width="10" height="13" viewBox="0 0 10 11" fill="none" xmlns="http://www.w3.org/2000/svg">
				<path
					d="M7.625 2.12488H9.8125V12.1874H0.1875V2.12488H2.375V2.99988H7.625V2.12488ZM1.9375 6.49988H8.0625V5.62488H1.9375V6.49988ZM1.9375 9.99988H8.0625V9.12488H1.9375V9.99988ZM3.25 2.12488V0.812378H6.75V2.12488H3.25Z"
					fill="#3C4250"
				/>
			</svg>
		</div>
	);
};
