import { ApiProvider } from "@src/store/ApiProvider";
import { getThunkAndSlice } from "@src/store/getThunkAndSlice";

const initialState = {
	token: ""
};
export const { thunk: login, slice: loginSlice } = getThunkAndSlice<
	{
		UserName: string;
		Password: string;
	},
	typeof initialState
>({
	initialState,
	path: "/Account/Jwt_Login",
	method: "post",
	onBeforeRequest: () => {
		const token = localStorage.getItem("token");
		if (token) {
			ApiProvider.default.setToken(token);
			return { token };
		}
	},
	onData: (data) => {
		ApiProvider.default.setToken(data.token);
	},
});
