import { getThunkAndSlice } from "@src/store/getThunkAndSlice";

interface Item {
	Item_ID: number;
	PDF_FilePath: string;
	Remove_Barcodes: boolean;
}
const initialState = false;

export const { thunk: deletePdfs, slice: deletePdfsSlice } = getThunkAndSlice<Array<Item>, typeof initialState>({
	initialState,
	path: "/api/System/DeletePdfs",
	method: "delete",
});
