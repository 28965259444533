import {
	excludeMethodItems,
	TExcludeMethodItem,
} from "@src/pages/home/children/pricing/components/manage-inventory/autopricer/ui/excludeMethodItems";
import { TGroupName } from "@src/pages/home/children/pricing/components/manage-inventory/autopricer/ui/ExcludeMethods";
import { ApiProvider } from "@src/store/ApiProvider";
import { ComparableItem, INACTIVE_LISTING_ID, ListingsDictionary } from "@src/store/auto-pricer/autopricerSlice";
import { getMinNumber, getUniqueArrayValues, mapDictionaryKeysToValues } from "@src/utils/array.utils";
import _ from "lodash";
import { isJustRepeatedLetters } from "../map-view/map.utils";
import { getArrayFromString } from "./arrayUtils";

export interface IRule {
	Rule_ID: 8977;
	Rule_Name: string;
}

export interface IIgnoredListing {
	SeatGeekId: string;
	SeatGeekListingId: string | null;
}

export interface ISingleRule {
	RuleId: number;
	RuleName: string;
	VenueId: number;
	CompareToEverything: boolean;
	FloorPrice: number;
	CeilingPrice: number;
	TicketSplit: string;
	TraitExclusions: string;
	RowRangeStart: string | null;
	RowRangeEnd: string | null;
	AllRows: boolean;
	Position: number;
	AmountUnder: number;
	AllowPriceIncrease: boolean;
	MinutesPerCycle: number;
	Notes: string | null;
	Zones: Array<string>;
	Sections: Array<string>;
	Rows: Array<string>;
	IgnoredListings: unknown;
	Ignored_Listings: Array<IIgnoredListing>;
	FloorNotification: boolean;
	CeilingNotification: boolean;
	NoComparablesNotification: boolean;
	CompareAgainstOwnInventory: boolean;
	MasterRuleId: number | null;
	MasterOrder: unknown;
	Exclude_Outliers: number;
	Delivery_Exclusions: string;
	PctUnder: unknown;
	UseDefaultSplits: boolean;
	SuggestedSection: unknown;
	TicketSplits: string;
}

export const fetchAllVenueRules = async (venueId: number) => {
	try {
		const data = await ApiProvider.default.get<Array<IRule>>(`api/AutoPricer/ListSavedRules?venueId=${venueId}`);
		return { data, error: null };
	} catch (error) {
		return { data: null, error };
	}
};

export const getSingleVenueRule = async (ruleId: number) => {
	try {
		const data = await ApiProvider.default.get<ISingleRule>(`/api/AutoPricer/GetSavedRules?ruleId=${ruleId}`);
		return { data, error: null };
	} catch (error) {
		return { data: null, error };
	}
};

export const deleteRule = async (ruleId: number) => {
	try {
		const data = await ApiProvider.default.delete("api/AutoPricer/DeleteSavedRules", { ruleId });
		return { data, error: null };
	} catch (error) {
		return { data: null, error };
	}
};

const rowSpecificCompare = (a: string, b: string) => {
	const isAJustRepeatedLetters = isJustRepeatedLetters(a);
	const isBJustRepeatedLetters = isJustRepeatedLetters(b);

	// A Before AA
	if (isAJustRepeatedLetters && isBJustRepeatedLetters && a.length !== b.length) {
		return a.length - b.length;
	}

	return a.localeCompare(b);
};

export const comparableNamesCompareFunction = (a: string, b: string) => {
	const isANumeric = /^\d+$/.test(a);
	const isBNumeric = /^\d+$/.test(b);

	switch (true) {
		case isANumeric && isBNumeric:
			return +a - +b;
		case !isANumeric && !isBNumeric:
			return rowSpecificCompare(a, b);
		default:
			return isANumeric ? -1 : 1;
	}
};

export const comparableItemsCompareFunction = (a: ComparableItem, b: ComparableItem) =>
	comparableNamesCompareFunction(a.name, b.name);

export const getComparableItemsFromDictionary = (dictionary: ListingsDictionary) =>
	Array.from(Object.entries(dictionary)).map<ComparableItem>(([key, listings]) => {
		const activeListings = listings.filter(({ seatGeekListingId }) => seatGeekListingId !== INACTIVE_LISTING_ID);

		const numberOfListings = activeListings.length;

		const numberOfTickets = activeListings.reduce((acc, { quantity }) => acc + quantity, 0);

		const minPrice = getMinNumber(activeListings.map(({ price }) => price));

		return {
			name: key,
			listings: numberOfListings,
			tickets: numberOfTickets,
			minPrice,
		};
	});

export const getSections = (dictionary: ListingsDictionary) => Array.from(Object.keys(dictionary));

export const getRows = (dictionary: ListingsDictionary, selectedSections?: Array<string>) =>
	getUniqueArrayValues(
		Array.from(selectedSections ? mapDictionaryKeysToValues(dictionary, selectedSections) : Object.values(dictionary))
			.flat()
			.map(({ row }) => row),
	);

export const loadExcludeMethods = (type: TGroupName, data: string) => {
	const groupedItems = _.groupBy(excludeMethodItems, "group");
	const target = groupedItems[type];
	const dataToArray = getArrayFromString(data);
	return target.filter((x) => x.keys.some((y) => dataToArray.includes(y)));
};

export const sendExcludeMethods = (data: Array<TExcludeMethodItem>) => {
	return data.map((x) => x.keys).join(",");
};
