import mixpanel, { Dict } from "mixpanel-browser";

const disableMixpanel = true;
const disableApiRequests = false;
const mixPanelToken = process.env.MIXPANEL_TOKEN;

export enum MixpanelCategory {
	// General
	API_REQUEST_TRIGGERED = "Portal API request",
	API_ERROR = "Portal API request error",
	SIDEBAR_ITEMS_CLICKED = "Portal Sidebar clicked",
	ERROR_429 = "429: Too many requests",
	APP_SWITCH = "Portal app switch clicked",

	// Filters
	FILTERS_BUTTON_CLICKED = "Portal Filters button clicked",
	FILTERS_APPLIED = "Portal Filters applied",
	SAVED_FILTERS = "Portal Saved filters",
	COPY_URL_CLICKED = "Portal Copy Url clicked",
	FILTERS_SWITCH_CLICKED = "Portal Filters switch clicked",

	// Event
	EVENT_CLICKED = "Portal Event clicked",
	CUSTOMIZE_CLICKED = "Portal Customize button clicked",
	EXPAND_EVENTS_CLICKED = "Portal Expand Events clicked",
	NEXT_7_DAYS_CLICKED = "Portal Next 7 days clicked",

	// Inventory
	INVENTORY_ACTIONS = "Portal Inventory actions",
	TAG_BUTTON_CLICKED = "Portal tag button clicked",
	EDIT_INVENTORY_CLICKED = "Portal Edit inventory clicked",
	AUTO_PRICER_CLICKED = "Portal Auto pricer clicked",
	SHARKING_OPTIONS_CLICKED = "Portal Sharking options clicked",

	// Map
	MAP_INTERACTION = "Portal Map section picked",
	MAP_TAB_CLICKED = "Portal Map FS/C Tab clicked",
	MAP_RESET_CLICKED = "Portal Map Reset button clicked",
	MAP_CONTROLS_CLICKED = "Portal Map Controls clicked",

	// Active
	ACTIVE_TAB_CLICKED = "Portal Active Charts tab clicked",
	ACTIVE_FETCH_BUTTON_CLICKED = "Portal Active fetch button clicked",
	ACTIVE_FILTERS_CLICKED = "Portal Active  filters clicked",

	// Sold
	SOLD_TAB_CLICKED = "Portal Sold Charts tab clicked",
	SOLD_FETCH_BUTTON_CLICKED = "Portal Sold fetch button clicked",
	SOLD_FILTERS_CLICKED = "Portal Sold filters clicked",
}

if (mixPanelToken) {
	mixpanel.init(mixPanelToken, {
		debug: false,
		track_pageview: true,
		persistence: "localStorage",
	});
} else {
	console.log(
		"%cMixpanel token missing, tracking not available",
		"background-color:#4f44e0;border-radius:8px;padding: 2px 10px",
	);
}

export const mixpanelTrack = (event: MixpanelCategory, data?: Dict) => {
	if (!mixPanelToken || !event || disableMixpanel) return;
	if (event === MixpanelCategory.API_REQUEST_TRIGGERED && disableApiRequests) return;

	mixpanel.track(event, data);
};
