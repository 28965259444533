import { getThunkAndSlice } from "@src/store/getThunkAndSlice";

const initialState = false;
interface IListingToUpdate {
	alterAmount: number;
	blackoutEnd: null;
	blackoutStart: null;
	endDateTime: Date | string;
	endPrice: number;
	listingIds: Array<number>;
	priceCycleMinutes: number;
	startDateTime: Date | string;
	startPrice: number;
}

export const { thunk: updateSchedulePrice, slice: updateSchedulePriceSlice } = getThunkAndSlice<
	IListingToUpdate,
	typeof initialState
>({
	initialState,
	path: "/api/Pricing/ScheduleListingPricing",
	method: "post",
});
