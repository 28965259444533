import styles from "@src/components/helpers.module.scss";
import React from "react";

export const StageFrontOnlyLogoIcon: React.FC = () => {
	return (
		<div data-disable-maxheight="true" className={styles.iconContainer}>
			<svg width="50" height="32" viewBox="0 0 60 32" fill="none" xmlns="http://www.w3.org/2000/svg">
				<path
					fillRule="evenodd"
					clipRule="evenodd"
					d="M30.0038 6.03516C35.5068 6.03516 39.9686 10.497 39.9686 15.9999C39.9686 21.5029 35.5068 25.9647 30.0038 25.9647C24.5009 25.9647 20.0391 21.5029 20.0391 15.9999C20.0391 10.497 24.5009 6.03516 30.0038 6.03516Z"
					fill="#EC6728"
				/>
				<path
					fillRule="evenodd"
					clipRule="evenodd"
					d="M8.4593 31.1427C3.51057 27.1797 0.481934 21.8549 0.481934 15.9996C0.481934 10.1442 3.51057 4.81543 8.4593 0.856445C4.8685 5.03713 2.73066 10.2947 2.73066 15.9996C2.73066 21.7045 4.8685 26.962 8.4593 31.1427ZM51.541 0.856445C56.4858 4.81939 59.5183 10.1442 59.5183 15.9996C59.5183 21.8549 56.4858 27.1837 51.541 31.1427C55.1318 26.962 57.2696 21.7045 57.2696 15.9996C57.2696 10.2947 55.1318 5.03713 51.541 0.856445Z"
					fill="white"
				/>
				<path
					fillRule="evenodd"
					clipRule="evenodd"
					d="M13.7287 28.0154C9.99146 24.872 7.70312 20.6478 7.70312 15.9999C7.70312 11.3521 9.99146 7.12781 13.7287 3.98438C11.0168 7.302 9.39758 11.4709 9.39758 15.9999C9.39758 20.529 11.0129 24.6978 13.7287 28.0154ZM46.2795 3.98438C50.0168 7.12781 52.3051 11.3521 52.3051 15.9999C52.3051 20.6478 50.0168 24.872 46.2795 28.0154C48.9914 24.6978 50.6106 20.529 50.6106 15.9999C50.6106 11.4709 48.9954 7.302 46.2795 3.98438Z"
					fill="white"
				/>
				<path
					fillRule="evenodd"
					clipRule="evenodd"
					d="M17.9097 24.9273C15.1345 22.5915 13.4321 19.4521 13.4321 15.9999C13.4321 12.5476 15.1345 9.40812 17.9097 7.07232C15.8946 9.53877 14.6911 12.6347 14.6911 15.9999C14.6911 19.365 15.8906 22.4609 17.9097 24.9273ZM42.0991 7.06836C44.8783 9.40416 46.5767 12.5437 46.5767 15.9959C46.5767 19.4481 44.8744 22.5876 42.0991 24.9234C44.1142 22.4569 45.3178 19.361 45.3178 15.9959C45.3178 12.6308 44.1182 9.53085 42.0991 7.06836Z"
					fill="white"
				/>
			</svg>
		</div>
	);
};
