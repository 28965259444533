import { getThunkAndSlice } from "@src/store/getThunkAndSlice";
import { SGInventory } from "./queryInventorySlice";

const initialState = new Array<SGInventory>();

export const { thunk: queryInventorySeason, slice: queryInventorySeasonSlice } = getThunkAndSlice<
	{
		listingId: number;
	},
	typeof initialState
>({
	initialState,
	path: "/api/AutoPricer/QueryInventorySeason",
});
