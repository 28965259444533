import { getThunkAndSlice } from "@src/store/getThunkAndSlice";

const initialState = new Array<string>();

export const { thunk: listUsers, slice: listUsersSlice } = getThunkAndSlice<
	{
		userName: string;
	},
	typeof initialState
>({
	initialState,
	path: "/api/AutoPricer/ListUsers",
});
