import helperStyles from "@src/components/helpers.module.scss";
import React from "react";

interface ILinkOut {
	isActive: boolean;
	onClick: (e: React.MouseEvent<HTMLDivElement>) => void;
}

export const LinkOutIcon: React.FC<ILinkOut> = ({ isActive, onClick }) => {
	return (
		<div className={`${helperStyles.iconContainer} ${helperStyles.linkOut}`} data-is-active={isActive} onClick={onClick}>
			<svg width="8" height="8" viewBox="0 0 8 8" fill="none" xmlns="http://www.w3.org/2000/svg">
				<path
					d="M5.5 0.75H7.25V2.5M6.875 1.125L5 3M4.25 1.25H2C1.80109 1.25 1.61032 1.32902 1.46967 1.46967C1.32902 1.61032 1.25 1.80109 1.25 2V6C1.25 6.19891 1.32902 6.38968 1.46967 6.53033C1.61032 6.67098 1.80109 6.75 2 6.75H6C6.19891 6.75 6.38968 6.67098 6.53033 6.53033C6.67098 6.38968 6.75 6.19891 6.75 6V3.75"
					strokeLinecap="round"
					strokeLinejoin="round"
				/>
			</svg>
		</div>
	);
};
