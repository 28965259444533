import { fromType } from "@src/store/helpers";
import { getThunkAndSlice } from "@src/store/getThunkAndSlice";

interface MatchedSection {
	SectionName: string;
	MatchStatus: number;
}
interface MapDataDTO {
	MapDataJSON: object;
	MatchedSections: Array<MatchedSection>;
}

const initialState = fromType<MapDataDTO>({
	MapDataJSON: {},
	MatchedSections: [],
});

export const { thunk: getSections, slice: getSectionsSlice } = getThunkAndSlice<
	{
		eventId: number;
		venueName: string;
		getMatchedSections: boolean;
	},
	typeof initialState
>({
	initialState,
	path: "/api/Marketplace/SeatGeek/GetSections",
	method: "post",
});
