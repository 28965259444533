import { getThunkAndSlice } from "@src/store/getThunkAndSlice";

const initialState = new Array<{
	Tag: string;
}>();

export const { thunk: listAllTags, slice: listAllTagsSlice } = getThunkAndSlice<Record<string, never>, typeof initialState>({
	initialState,
	path: "/api/System/ListAllTags",
});
