import React, { forwardRef, useImperativeHandle, useState } from "react";
import { createPortal } from "react-dom";

interface IModalProps {
	children: React.ReactNode;
	customButton?: React.ReactNode;
	buttonText?: string;
}

export interface IModalHandle {
	openModal: () => void;
	closeModal: () => void;
}

import { eventActions } from "@src/store";
import styles from "./Modal.module.scss";
import { useAppDispatch } from "@src/store/hooks";

const ModalFRRF: React.ForwardRefRenderFunction<IModalHandle, IModalProps> = (
	{ children, customButton, buttonText = "Open modal" },
	ref,
) => {
	const [isOpen, setIsOpen] = useState(false);
	const dispatch = useAppDispatch();

	const openModal = () => setIsOpen(true);
	const closeModal = () => {
		dispatch(eventActions.showComingSoon({ showComingSoon: false }));
		setIsOpen(false);
	};

	useImperativeHandle(ref, () => ({
		openModal,
		closeModal,
	}));

	return (
		<>
			{!buttonText && (
				<span aria-hidden="true" className={styles.openContainer} onClick={openModal}>
					{buttonText}
				</span>
			)}
			{customButton}
			{isOpen &&
				createPortal(
					<div className={styles.overlay}>
						<div className={styles.content}>{children}</div>
					</div>,
					document.querySelector("#modal") as HTMLElement,
				)}
		</>
	);
};

export const Modal = forwardRef(ModalFRRF);
