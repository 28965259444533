import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { getThunkAndSlice } from "@src/store/getThunkAndSlice";
import { SortField } from "@src/store/helpers";

export interface EventRecord {
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	[key: string]: any;
	Cat_ID: number;
	Event_ID: number;
	Event_Name: string;
	Event_Headliner_ID: number;
	Event_Headliner: string;
	Event_Date: Date;
	Event_Time: string;
	Venue_ID: number;
	Venue_Name: string;
	Venue_City: string;
	Venue_State: string;
	Total_Open: number;
	Open_Cost: number;
	Total_Cost: number;
	Total_Sold: number;
	Total_Unshared: number;
	Total_Unpriced: number;
	Total_Reserved: number;
	Cat_Type: string;
	SeatGeek_Event_ID: number;
	StubHub_Event_ID: bigint;
	StubHubInt_Event_ID: bigint;
	Tags: string | null;
}
export interface PagedEvents {
	itemCount: number;
	Events: Array<EventRecord>;
}

const initialState = {
	itemCount: 0,
	Events: new Array<EventRecord>(),
};
export interface IQueryEventParams {
	EventFromDate?: Date | null;
	EventToDate?: Date | null;
	POFromDate?: Date | null;
	POToDate?: Date | null;
	CategoryId?: number | null;
	EventHeadlinerId?: number | null;
	EventId?: number | null;
	VenueId?: number | null;
	DaysSinceLastUpdate?: number | null;
	CompanyName?: string | null;
	ListingId?: number | null;
	SearchString?: string | null;
	POId?: number | null;
	OrderByColumn?: Array<SortField> | null;
	StartIndex?: number | null;
	Limit?: number | null;
	ForceRefresh?: boolean | null;
	ShowSold?: boolean | null;
	SearchTag?: string | null;
}
export const {
	thunk: queryEvents,
	slice: hiddenQueryEventsSlice,
	extraReducers: hiddenExtraReducers,
} = getThunkAndSlice<IQueryEventParams, typeof initialState>({
	initialState,
	path: "/api/Pricing/QueryEvents",
	method: "post",
});

export const queryEventsSlice = createSlice({
	name: hiddenQueryEventsSlice.name,
	initialState: hiddenQueryEventsSlice.getInitialState(),
	reducers: {
		updateEventItemsTags: (state, action: PayloadAction<{ itemIds: Array<number>; tags: Array<string> }>) => {
			const { itemIds, tags } = action.payload;
			const newEvents = state.data.Events.map((x) => {
				if (itemIds.includes(x.Event_ID)) {
					return {
						...x,
						Tags: tags.join("|"),
					};
				}
				return x;
			});
			state.data.Events = newEvents;
			state.version++;
		},
		reset: (state) => {
			state.data = {
				itemCount: 0,
				Events: new Array<EventRecord>(),
			};
		},
	},
	extraReducers: hiddenExtraReducers,
});
