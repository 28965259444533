import helperStyles from "@src/components/helpers.module.scss";
import React from "react";

export const ArrowRightIcon: React.FC = () => {
	return (
		<div className={helperStyles.iconContainer}>
			<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
				<path
					d="M3.33301 8.00016H12.6663M12.6663 8.00016L7.99967 3.3335M12.6663 8.00016L7.99967 12.6668"
					stroke="#667085"
					strokeWidth="1.67"
					strokeLinecap="round"
					strokeLinejoin="round"
				/>
			</svg>
		</div>
	);
};
