import { getThunkAndSlice } from "@src/store/getThunkAndSlice";
const initialState = new Array<{
	ListingId: number;
	StubHubListingId: number;
}>();
export const { thunk: getListingRules, slice: getListingRulesSlice } = getThunkAndSlice<
	{
		listingId: number;
	},
	typeof initialState
>({
	initialState,
	path: "/api/AutoPricer/GetListingRules",
});
