import React from "react";
import styles from "@src/components/helpers.module.scss";

export const FilterIcon: React.FC = () => {
	return (
		<div className={styles.iconContainer}>
			<svg width="15" height="14" viewBox="0 0 15 14" fill="none" xmlns="http://www.w3.org/2000/svg">
				<path
					d="M8.66699 7V11.5967C8.69032 11.7717 8.63199 11.9583 8.49782 12.0808C8.44385 12.1349 8.37975 12.1778 8.30918 12.2071C8.23862 12.2364 8.16297 12.2514 8.08657 12.2514C8.01017 12.2514 7.93452 12.2364 7.86396 12.2071C7.79339 12.1778 7.72929 12.1349 7.67532 12.0808L6.50282 10.9083C6.43924 10.8461 6.39089 10.77 6.36154 10.6861C6.3322 10.6021 6.32265 10.5124 6.33365 10.4242V7H6.31615L2.95615 2.695C2.86143 2.57339 2.81868 2.41923 2.83726 2.26621C2.85585 2.11318 2.93424 1.97374 3.05532 1.87833C3.16615 1.79667 3.28865 1.75 3.41699 1.75H11.5837C11.712 1.75 11.8345 1.79667 11.9453 1.87833C12.0664 1.97374 12.1448 2.11318 12.1634 2.26621C12.182 2.41923 12.1392 2.57339 12.0445 2.695L8.68449 7H8.66699Z"
					fill="#1F1F1F"
				/>
			</svg>
		</div>
	);
};
