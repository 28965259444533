import styles from "@src/components/helpers.module.scss";
import React, { useState } from "react";

interface ILogoutIconType {
	onClick: () => void;
}

export const LogoutIcon: React.FC<ILogoutIconType> = ({ onClick }) => {
	const [fill, setFill] = useState("var(--inactiveIcon)");
	const handleLeave = () => setFill("var(--inactiveIcon)");
	const handleEnter = () => setFill("var(--activeIconRed)");

	return (
		<div
			className={styles.iconContainer}
			onMouseEnter={handleEnter}
			onMouseLeave={handleLeave}
			style={{ cursor: "pointer" }}
			onClick={onClick}
		>
			<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
				<path
					d="M15.8333 17.5C16.2917 17.5 16.6839 17.3369 17.01 17.0108C17.3361 16.6847 17.4994 16.2922 17.5 15.8333V4.16667C17.5 3.70833 17.3367 3.31611 17.01 2.99C16.6833 2.66389 16.2911 2.50056 15.8333 2.5H10V4.16667H15.8333V15.8333H10V17.5H15.8333ZM6.66667 14.1667L7.8125 12.9583L5.6875 10.8333H12.5V9.16667H5.6875L7.8125 7.04167L6.66667 5.83333L2.5 10L6.66667 14.1667Z"
					fill={fill}
				/>
			</svg>
		</div>
	);
};
