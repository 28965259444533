import { GridSection } from "@src/components/filtersModal/GridSection";
import { DateInput } from "@src/components/input/DateInput";
import { Input } from "@src/components/input/Input";
import { SelectYup } from "@src/components/select/SelectYup";
import { SalesFiltersProps } from "@src/validation/SalesHeaderFiltersValidation";
import React from "react";
import { Control, UseFormReset, UseFormSetValue } from "react-hook-form";
import styles from "./SalesFilters.module.scss";

interface ICategoriesGeneric {
	label: string;
	value: string;
}

interface IAllOPtions {
	categoryOptions: Array<ICategoriesGeneric>;
	headlinerOptions: Array<ICategoriesGeneric>;
	venueOptions: Array<ICategoriesGeneric>;
	smartSearchOptions: Array<ICategoriesGeneric>;
	marketplaceOptions: Array<ICategoriesGeneric>;
	saleStatusesOptions: Array<ICategoriesGeneric>;
	tagOptions: Array<ICategoriesGeneric>;
}

interface IAllFilters extends IAllOPtions {
	control: Control;
	setValue: UseFormSetValue<SalesFiltersProps>;
	reset: UseFormReset<SalesFiltersProps>;
}

export const SalesFilters: React.FC<IAllFilters> = (props) => {
	const {
		control,
		categoryOptions,
		headlinerOptions,
		venueOptions,
		tagOptions,
		smartSearchOptions,
		marketplaceOptions,
		saleStatusesOptions,
	} = props;

	return (
		<div className={styles.root}>
			<div className={styles.content}>
				<div className={styles.inner}>
					<GridSection headline="Event">
						<SelectYup name="smartSearch" placeholder="Smart search" control={control} options={smartSearchOptions} />
						<SelectYup name="tags" placeholder="Tags" control={control} options={tagOptions} />
					</GridSection>
					<GridSection>
						<SelectYup name="category" placeholder="Category" control={control} options={categoryOptions} />
						<SelectYup name="eventHeadliner" placeholder="Headliner" control={control} options={headlinerOptions} />
						<SelectYup name="venue" placeholder="Venue" control={control} options={venueOptions} />
						<div className={styles.subItems}>
							<DateInput control={control} name="eventStartDate" placeholder="Event Start" />
							<DateInput control={control} name="eventEndndDate" placeholder="Event End" />
						</div>
					</GridSection>

					<GridSection headline="Sales">
						<SelectYup name="marketplace" placeholder="Choose Marketplace" control={control} options={marketplaceOptions} />
						<div className={styles.subItems}>
							<DateInput control={control} name="paymentStartDate" placeholder="Payment Start" />
							<DateInput control={control} name="paymentEndndDate" placeholder="Payment End" />
						</div>
						<SelectYup
							name="salesStatus"
							placeholder="Choose Sales Status"
							control={control}
							options={saleStatusesOptions}
						/>
						<div className={styles.subItems}>
							<DateInput control={control} name="salesStartDate" placeholder="Sales Start" />
							<DateInput control={control} name="salesEndndDate" placeholder="Sales End" />
						</div>
					</GridSection>

					<GridSection headline="Ticket">
						<Input control={control} name="section" placeholder="Section" />
						<Input control={control} name="row" placeholder="Row" />
						<Input control={control} type="number" name="minQuantity" placeholder="Min. Quantity" />
					</GridSection>

					<GridSection headline="Advanced">
						<Input name="invoiceId" placeholder="Invoice ID" control={control} />
						<Input name="eventId" placeholder="Event ID" control={control} />
						<Input name="buyerPOId" placeholder="Buyer PO ID" control={control} />
					</GridSection>
				</div>
			</div>
		</div>
	);
};
