import { getThunkAndSlice } from "@src/store/getThunkAndSlice";

const initialState = new Array<{
	Event_Headliner_ID: number;
	Event_Headliner: string;
	Cat_ID: number;
	Cat_Desc: string | null;
}>();

export const { thunk: listHeadliners, slice: listHeadlinersSlice } = getThunkAndSlice<
	{
		eventFromDate?: Date;
		eventToDate?: Date;
		categoryID?: number;
	},
	typeof initialState
>({
	initialState,
	path: "/api/Pricing/ListHeadliners",
});
