import styles from "@src/components/helpers.module.scss";
import React from "react";

export const MagnifyingGlassIcon = () => {
	return (
		<div className={styles.iconContainer}>
			<svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
				<path
					d="M12.75 12.75L9.25 9.25M2.25 6.33333C2.25 6.86956 2.35562 7.40054 2.56083 7.89596C2.76603 8.39137 3.06681 8.84151 3.44598 9.22069C3.82515 9.59986 4.2753 9.90063 4.77071 10.1058C5.26612 10.311 5.7971 10.4167 6.33333 10.4167C6.86956 10.4167 7.40054 10.311 7.89596 10.1058C8.39137 9.90063 8.84151 9.59986 9.22069 9.22069C9.59986 8.84151 9.90063 8.39137 10.1058 7.89596C10.311 7.40054 10.4167 6.86956 10.4167 6.33333C10.4167 5.7971 10.311 5.26612 10.1058 4.77071C9.90063 4.2753 9.59986 3.82515 9.22069 3.44598C8.84151 3.06681 8.39137 2.76603 7.89596 2.56083C7.40054 2.35562 6.86956 2.25 6.33333 2.25C5.7971 2.25 5.26612 2.35562 4.77071 2.56083C4.2753 2.76603 3.82515 3.06681 3.44598 3.44598C3.06681 3.82515 2.76603 4.2753 2.56083 4.77071C2.35562 5.26612 2.25 5.7971 2.25 6.33333Z"
					stroke="#101828"
					strokeLinecap="round"
					strokeLinejoin="round"
				/>
			</svg>
		</div>
	);
};
