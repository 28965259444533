import helperStyles from "@src/components/helpers.module.scss";
import React from "react";

export const TicketEvolution = () => {
	return (
		<div className={helperStyles.iconContainer}>
			<svg
				width="100%"
				height="100%"
				viewBox="0 0 22 15"
				fill="none"
				xmlns="http://www.w3.org/2000/svg"
				xmlnsXlink="http://www.w3.org/1999/xlink">
				<rect x="0.5" width="21" height="15" fill="url(#pattern0_2797_88203)" />
				<defs>
					<pattern id="pattern0_2797_88203" patternContentUnits="objectBoundingBox" width="1" height="1">
						<use xlinkHref="#image0_2797_88203" transform="matrix(0.0170068 0 0 0.0238095 -0.0102041 0)" />
					</pattern>
					<image
						id="image0_2797_88203"
						width="60"
						height="42"
						xlinkHref="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADwAAAAqCAYAAADvczj0AAAABmJLR0QA/wD/AP+gvaeTAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAB3RJTUUH4QMCEgg25KS4UwAABClJREFUaN7tmr9vGmcYxz/HL59wCZiKoESNwItBQophaQekCBg6VsnQTkRNNrbWfwHZOiZRF6QOSWWmZGiUbBkwqsTSxWRAglRqsdxaoSjoLFSLmho6vHfmIEfAhiKI+Uon8XLP+95973me98fzPBJzhm7B4wLCRvekaD0/5hh+wG9wS5HmkHAM2Blye0uK1h+M8cF2hxDOW5hXuDbBdb3X/vMFtJV0t+DJS9F68T097wN+PDdg1df7t7oNgGluCVudUP9ZvOjbX+Dal2CWXcBOt+C5p2qyzzK6Bc8OcAfXJjgCoq9KVMP8aliPZgXMMvhuw8FzF61aGkh3Cx7Np3s+67khyO5tGw61GIQBlFfQVuDaV9B6A83X0FZip/cdAXE1K4LsSWvBCQP8vQe/fg+yVyW4ITTfqsGxAr/9MJTo1Ak3QsEwoPerqrtUrhrIDS4ZirtULp7pYa2auM6BaWr4PhDTtYtAxEDup4F1Ng/EZ2UkpilqNzbwd7gRCt4bkHtksKmIqVqfCSwTEvUDd4BvhoikG6Hg16q2w0M2AwC7jVDwIfAY3s4vYZVAegyZURp0qePk51rDGo66Xf74t33u/p9YrNglaf5NWsNJt0uz05moP7MmnMhmwoCiM7GqaopVIJxLpvKJbCYGFHPJlDKTt/N+Dp3W5OOseA01/Ejna3rCRSCcyGa09i3g2UwIy97pa1jV7E2D5UIjHx5opxPZjFg/v3vAosFisGEYhbC6eYizgDBxwXDqw7nSPuuXnfz+16Gh4NrqinDuVZl1z6XFJzyX2NsWJ6RJ4b+9IISdm2D3TSF64loQwrIXbM4pzFQrC0K49nLqJn3hZukl4SXhD3XjMdFhRJK4arFM1H+hCNskiStmy9Kkl4Q/FB8+AY4mCPHYTSbMi0T4qNPhdfv43P03rDYcJtPSpC/e8dAIZhmc1+FSAEyyyCP/U4PjQzh8NXLvbdEf8GWr+fSgP4iPZJs4wFjf9TazJE1kkuZx12H3p/DxZyJ1+uZlf0LNERC5YQ9atcD7CUf8lwG44lo9+6QjSWxYbf+vZq9+AeYVfUq0ioieHgKbNCsxmhUXjoA4He0/McwwTmrSCiI9EhshV2V0uiWvjtc7rWvxZLtPkN1/qo11d7CiRy2BSNOsfHuaOD94rs5Ucm/SyiVTcc4WgcwDa7lkKu8ulYvuUjmOiFUb4Rmw5i6V14F1hsez4+5SOd6XJz7aE/6qRTwOXmhkI0blS1K0rkjR+hawRasmTN7uE1ez0pPTfiSymR3d1/UjAvAx9SVvAo8RmcJ4Lpl654GNUHCX/th2FYi4S2VFJ2NUUlR0l8oRnaZiDC9bio9Tq6UWtxhZXV/Z0hbDUy0P1VTLj+qHMMJd+isAFD1ZAHeprDRCwVuDcgPjFIdZ3LiFaeq7GLmQ8h/gAUhrfxN92QAAAABJRU5ErkJggg=="
					/>
				</defs>
			</svg>
		</div>
	);
};
