import { fromType, SortField } from "@src/store/helpers";
import { getThunkAndSlice } from "@src/store/getThunkAndSlice";

export interface QuerySalesParamsDto {
	SaleFromDate?: Date;
	SaleToDate?: Date;
	EventFromDate?: Date;
	EventToDate?: Date;
	PaymentFromDate?: Date;
	PaymentToDate?: Date;
	BuyerPOID?: string;
	CategoryId?: number;
	EventHeadlinerId?: number;
	VenueId?: number;
	InvoiceId?: number;
	InvoiceIds?: Array<number>;
	EventId?: number;
	CompanyName?: string;
	ExchangeName?: string;
	Sections?: string;
	Rows?: string;
	Quantity?: number;
	SaleStatus?: string;
	OrderByColumn?: Array<SortField>;
	StartIndex?: number;
	Limit?: number;
	ForceRefresh?: boolean;
	SearchString?: string;
	SearchTag?: string;
}
enum PdfsAttached {
	None = 0,
	Some = 1,
	All = 2,
}
export interface SalesInfo {
	Invoice_ID: number;
	Sale_Date: Date;
	Completed_Date?: Date;
	Item_Price: number;
	Exchange_Name: string;
	Cat_Desc: string;
	Event_Headliner: string;
	Event_ID: number;
	Event_Name: string;
	Event_Date?: Date;
	Event_Time: string;
	Venue_Name: string;
	Venue_City: string;
	Venue_State: string;
	Listing_ID?: number;
	Section: string;
	Row: string;
	Seat_From?: number;
	Seat_Thru?: number;
	Quantity: number;
	Num_PDFs: number;
	Num_Barcodes: number;
	PdfsAttached: PdfsAttached;
	InHand?: boolean;
	InHandDate?: Date;
	Invoice_Amount: number;
	Payment_Amount?: number;
	Commission_Amount?: number;
	Commission_Rate?: number;
	Payment_Date?: Date;
	Payment_Type: string;
	Payment_Details: string;
	Payment_Notes: string;
	Sale_Status: string;
	Company_Name: string;
	Sale_Type: string;
	Internal_Notes: string;
	External_Notes: string;
	Airbill_User: string;
	Airbill_Date?: Date;
	Is_eDelivery: boolean;
	ROI: number;
	ROIPercent: number;
	Shipping_Comments: string;
	Total_Cost: number;
	PO_ID: number;
	PO_Date?: Date;
	Buyer_PO_ID: string;
	Cost_Locked?: boolean;
	Event_Tags: string;
	Item_Tags: string;
	Cancelled_Date?: Date;
	Automation_Notes: string;
	Tickets_Available_Date?: Date;
}
interface PagedSalesInfo {
	itemCount: number;
	SalesInfo: Array<SalesInfo>;
}

const initialState = fromType<PagedSalesInfo>({
	itemCount: 0,
	SalesInfo: [],
});

export const { thunk: querySales, slice: querySalesSlice } = getThunkAndSlice<QuerySalesParamsDto, typeof initialState>({
	initialState,
	path: "/api/Sales/QuerySales",
	method: "post",
});
