export const stringUpperCase = (string: string) => {
	if (!string) return "No string";
	return string[0].toUpperCase() + string.slice(1);
};

interface ClassnameOverload {
	(...styles: Array<string | undefined>): string;
	(styles: Record<string, boolean>): string;
}
export const clx: ClassnameOverload = (...args: Array<string | undefined> | [Record<string, boolean>]): string => {
	if (typeof args[0] === "object" && !Array.isArray(args[0]) && args[0] !== null) {
		const argObject = args[0];
		return Object.keys(argObject)
			.filter((key) => argObject[key])
			.join(" ");
	} else if (Array.isArray(args)) {
		const classes = args as Array<string | undefined>;
		return classes.filter(Boolean).join(" ");
	}
	return "";
};
