import React from "react";

export const FolderIcon: React.FC = () => {
	return (
		<svg width="53" height="45" viewBox="0 0 53 45" fill="none" xmlns="http://www.w3.org/2000/svg">
			<g opacity="0.8">
				<path
					fillRule="evenodd"
					clipRule="evenodd"
					d="M48.1665 5.30957H6.33955C4.72779 5.30957 3.42139 6.61597 3.42139 8.22773V14.064H48.1665V5.30957Z"
					fill="#CED1D9"
				/>
				<path
					fillRule="evenodd"
					clipRule="evenodd"
					d="M48.1666 3.66816C48.1666 2.0564 46.8602 0.75 45.2485 0.75H29.9972C28.6013 0.75 27.3115 1.49798 26.6189 2.71007C25.4702 4.72165 23.8486 7.55904 23.8486 7.55904H48.1666V3.66816Z"
					fill="#CED1D9"
				/>
				<path d="M48.1665 8.04297H3.42139V9.85518H48.1665V8.04297Z" fill="#B9BBBD" />
				<path
					fillRule="evenodd"
					clipRule="evenodd"
					d="M51.4427 13.6592C51.5974 12.5445 51.2617 11.4171 50.5235 10.5689C49.7842 9.71972 48.7142 9.23242 47.5888 9.23242H3.9992C2.8738 9.23242 1.80381 9.71972 1.06454 10.5689C0.32621 11.4171 -0.00933919 12.5445 0.145362 13.6592C1.13178 20.76 3.04609 34.5415 3.92823 40.8953C4.19577 42.8183 5.83958 44.2502 7.78206 44.2502H43.8057C45.7482 44.2502 47.3921 42.8183 47.6597 40.8953C48.542 34.5415 50.4563 20.76 51.4425 13.6592H51.4427Z"
					fill="#DCE8F4"
				/>
				<path
					fillRule="evenodd"
					clipRule="evenodd"
					d="M0.472196 16.0131C0.545189 15.2029 0.869999 14.4286 1.41379 13.804C2.15212 12.9558 3.22211 12.4685 4.34751 12.4685H47.2405C48.3659 12.4685 49.4359 12.9558 50.1743 13.804C50.718 14.4285 51.0429 15.2027 51.1159 16.0131L51.4427 13.6591C51.5974 12.5445 51.2617 11.4171 50.5235 10.5689C49.7842 9.71972 48.7142 9.23242 47.5888 9.23242H3.9992C2.8738 9.23242 1.80381 9.71972 1.06454 10.5689C0.32621 11.4171 -0.00933919 12.5445 0.145362 13.6591L0.472196 16.0131Z"
					fill="#E9F3FC"
				/>
				<path
					fillRule="evenodd"
					clipRule="evenodd"
					d="M21.4178 34.549C21.4178 33.4751 20.5471 32.6035 19.4723 32.6035H11.6906C10.6167 32.6035 9.74512 33.4751 9.74512 34.549V36.4944C9.74512 37.5692 10.6167 38.4398 11.6906 38.4398H19.4723C20.5471 38.4398 21.4178 37.5692 21.4178 36.4944V34.549Z"
					fill="#CED1D9"
				/>
				<path
					fillRule="evenodd"
					clipRule="evenodd"
					d="M36.0075 16.2744C30.3706 16.2744 25.7939 20.851 25.7939 26.488C25.7939 32.1249 30.3706 36.7015 36.0075 36.7015C41.6444 36.7015 46.2211 32.1249 46.2211 26.488C46.2211 20.851 41.6444 16.2744 36.0075 16.2744ZM36.0075 19.1926C40.0336 19.1926 43.3029 22.4618 43.3029 26.488C43.3029 30.5141 40.0336 33.7834 36.0075 33.7834C31.9814 33.7834 28.7121 30.5141 28.7121 26.488C28.7121 22.4618 31.9814 19.1926 36.0075 19.1926Z"
					fill="#CED1D9"
				/>
				<path
					fillRule="evenodd"
					clipRule="evenodd"
					d="M41.3281 33.5406L50.4016 42.6142C50.9716 43.1841 51.8957 43.1841 52.4657 42.6142C53.0347 42.0452 53.0347 41.1201 52.4657 40.5511L43.3921 31.4775C42.8222 30.9085 41.8981 30.9085 41.3281 31.4775C40.7591 32.0465 40.7591 32.9716 41.3281 33.5406Z"
					fill="#CED1D9"
				/>
			</g>
		</svg>
	);
};
