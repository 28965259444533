import { getThunkAndSlice } from "@src/store/getThunkAndSlice";

const initialState = false;

export interface AttachedTicket {
	FileName: string;
	ItemId: number;
}

export const { thunk: supportAttachmentRequest, slice: supportAttachmentRequestSlice } = getThunkAndSlice<
	{
		description: string;
		subject: string;
		originalFileName: string;
		attachedTickets: Array<AttachedTicket>;
		newFileName: string;
		email: string;
		cc_emails: Array<string>;
		PDFCount: number;
		status: number;
		priority: number;
	},
	typeof initialState
>({
	initialState,
	path: "/api/System/SupportAttachmentRequest",
	method: "post",
});
