import { XIcon } from "@src/assets/general-icons";
import { systemActions } from "@src/store";
import React, { CSSProperties, useEffect, useMemo, useRef, useState } from "react";
import Select, { SelectInstance, SingleValue } from "react-select";
import { Button, ButtonVariant } from "../button/Button";
import { editCustomComponents, OptionType, tagConfig } from "../utils/reactSelectUtils";
import styles from "./EditTag.module.scss";
import { useAppDispatch, useAppSelector } from "@src/store/hooks";

export const EditTag: React.FC<{
	initialTags: Array<string>;
	onChange?: (tags: Array<string>) => void;
	selectedTagsStyle?: CSSProperties;
	type: "Inventory" | "Event";
	listingId?: number;
	disableControls?: boolean;
}> = ({ initialTags = [], selectedTagsStyle, listingId, type, disableControls = false, onChange }) => {
	const [input, setInput] = useState("");

	const allTags = useAppSelector((state) => state.system.allTags);
	const dispatch = useAppDispatch();

	const tagOptions = useMemo(
		() =>
			allTags.data.map((item) => ({
				value: item.Tag,
				label: item.Tag,
			})),
		[allTags.version],
	);

	const filteredOptions = tagOptions.filter((i) => !i.label.includes("www.ticketmaster.com/event"));

	useEffect(() => {
		if (allTags.init || allTags.loading || !input) return;

		const timeout = setTimeout(() => {
			dispatch(systemActions.listAllTags({}));
		}, 500);

		return () => {
			clearTimeout(timeout);
		};
	}, [dispatch, input]);

	const [tags, setTags] = useState(initialTags);
	const [selected, setSelected] = useState<string>();
	const selectRef = useRef<SelectInstance<OptionType>>(null);

	const addTag = () => {
		if (!selected) return;
		const tagAlreadyExists = tags.includes(selected);
		if (!tagAlreadyExists) {
			const newTags = [...tags, selected];
			setTags(newTags);
			onChange?.(newTags);
		}
		selectRef.current?.clearValue();
	};

	const deleteTag = async (tagName: string) => {
		if (!listingId) return;
		try {
			let allItemTags;
			if (type === "Inventory")
				allItemTags = await dispatch(systemActions.getListingTags({ listingIds: [listingId] })).unwrap();
			else allItemTags = await dispatch(systemActions.getEventTags({ eventIds: [listingId] })).unwrap();
			const tag = allItemTags.find((x) => x.TagDesc === tagName);
			return tag?.TagId;
		} catch (_error) {
			return false;
		}
	};

	const removeTag = async (name: string) => {
		const idOfRemovedTag = await deleteTag(name);
		if (!idOfRemovedTag) return;

		const response =
			type === "Inventory"
				? await dispatch(systemActions.removeTag({ tagIds: [idOfRemovedTag] }))
				: await dispatch(systemActions.removeEventTag({ tagIds: [idOfRemovedTag] }));
		if (response.meta.requestStatus === "fulfilled") {
			const newTags = tags.filter((item) => item !== name);
			setTags(newTags);
			onChange?.(newTags);
		}
	};
	const handleChange = (option: SingleValue<OptionType>) => setSelected(option?.value);

	const mappedTags = tags.map((item) => (
		<div className={styles.singleTag} key={item}>
			<div>{item}</div>
			<div aria-hidden="true" style={{ cursor: "pointer" }} onClick={() => removeTag(item)}>
				<XIcon color="#3C4250" size={8} />
			</div>
		</div>
	));

	return (
		<div className={styles.main}>
			{disableControls ? null : (
				<React.Fragment>
					<div className={styles.headline}>Tag name</div>
					<div className={styles.search}>
						<Select
							maxMenuHeight={185}
							ref={selectRef}
							isMulti={false}
							styles={tagConfig}
							onInputChange={setInput}
							onChange={handleChange}
							placeholder="Start typing"
							isLoading={allTags.loading}
							isClearable={true}
							isSearchable={true}
							options={filteredOptions}
							components={editCustomComponents}
						/>
						<Button controlSize="xsm" onClick={addTag} variant={ButtonVariant.Primary}>
							Add
						</Button>
					</div>
				</React.Fragment>
			)}
			<div style={selectedTagsStyle} className={styles.selectedTags}>
				{mappedTags}
			</div>
		</div>
	);
};
