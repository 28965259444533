import React from "react";
import { Control, FieldValues, Path, useController } from "react-hook-form";
import Select from "react-select";
import { filtersConfig, OptionType } from "../utils/reactSelectUtils";

interface SelectYupProps<T extends FieldValues> {
	control: Control<T>;
	name: Path<T>;
	options: Array<OptionType>;
	placeholder?: string;
	isLoading?: boolean;
}

export const SelectYup = <T extends FieldValues>({ options, name, control, placeholder, isLoading }: SelectYupProps<T>) => {
	const { field, fieldState } = useController({ name, control });
	const fieldValue = field?.value;
	const value = fieldValue ? { label: fieldValue, value: fieldValue } : null;

	const { isDirty, invalid } = fieldState;

	const innerTernary = field.value && isDirty ? "success" : "none";
	const inputStatus = invalid ? "error" : innerTernary;
	const customStyles = filtersConfig(inputStatus, "m");

	return (
		<Select
			isClearable
			isLoading={isLoading ?? false}
			options={options}
			placeholder={placeholder}
			onChange={(data) => {
				// this looks strange but it needs to be like this in order for
				// the "x" button on react-select to have any effect when clicked
				field.onChange(data?.value ?? "");
			}}
			value={value}
			isMulti={false}
			styles={customStyles}
			components={{ IndicatorSeparator: null }}
		/>
	);
};
