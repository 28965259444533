import styles from "@src/components/helpers.module.scss";
import React from "react";

export const TagIcon: React.FC = () => {
	return (
		<div className={styles.iconContainer} style={{ justifyContent: "flex-start", height: "100%" }}>
			<svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
				<path
					d="M6.63448 10.5333L11.4359 5.7307C11.4574 5.70912 11.4676 5.67992 11.4651 5.65072L11.1413 1.82436C11.1324 1.72533 11.055 1.64789 10.956 1.63901L7.1296 1.31654C7.1004 1.31401 7.06993 1.32416 7.04962 1.34574L2.24825 6.14711C2.22935 6.1662 2.21875 6.19198 2.21875 6.21884C2.21875 6.2457 2.22935 6.27148 2.24825 6.29057L6.49103 10.5333C6.53038 10.574 6.59513 10.574 6.63448 10.5333ZM8.1922 3.76166C8.4296 3.52426 8.81554 3.52426 9.05421 3.76166C9.29161 3.99906 9.29161 4.385 9.05421 4.62367C8.81681 4.86108 8.43087 4.86108 8.1922 4.62367C7.9548 4.38627 7.9548 4.00033 8.1922 3.76166ZM11.7951 7.35317L11.2924 6.8517C11.2733 6.8328 11.2475 6.8222 11.2207 6.8222C11.1938 6.8222 11.168 6.8328 11.1489 6.8517L6.55323 11.4385L3.53683 8.42973C3.51774 8.41083 3.49196 8.40022 3.4651 8.40022C3.43824 8.40022 3.41246 8.41083 3.39337 8.42973L2.89064 8.93119C2.87174 8.95028 2.86113 8.97606 2.86113 9.00292C2.86113 9.02978 2.87174 9.05556 2.89064 9.07465L5.97814 12.1571L6.48087 12.6585C6.52023 12.6979 6.58497 12.6979 6.62433 12.6585L11.7951 7.49662C11.8345 7.45727 11.8345 7.39252 11.7951 7.35317Z"
					fill="black"
				/>
			</svg>
		</div>
	);
};
