import React from "react";
import styles from "@src/components/helpers.module.scss";
import { IIconActiveProps } from "./helpers";

export const DashboardIcon: React.FC<IIconActiveProps> = ({ isActive }) => {
	return (
		<div className={styles.iconContainer}>
			<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
				<path
					d="M3.95801 4.79134C3.95801 4.57033 4.04581 4.35837 4.20209 4.20209C4.35837 4.04581 4.57033 3.95801 4.79134 3.95801H7.70801C7.92902 3.95801 8.14098 4.04581 8.29726 4.20209C8.45354 4.35837 8.54134 4.57033 8.54134 4.79134V7.70801C8.54134 7.92902 8.45354 8.14098 8.29726 8.29726C8.14098 8.45354 7.92902 8.54134 7.70801 8.54134H4.79134C4.57033 8.54134 4.35837 8.45354 4.20209 8.29726C4.04581 8.14098 3.95801 7.92902 3.95801 7.70801V4.79134Z"
					stroke={isActive ? "var(--activeIcon)" : "var(--inactiveIcon)"}
					strokeWidth="1.5"
					strokeLinecap="round"
					strokeLinejoin="round"
				/>
				<path
					d="M3.95801 12.2913C3.95801 12.0703 4.04581 11.8584 4.20209 11.7021C4.35837 11.5458 4.57033 11.458 4.79134 11.458H7.70801C7.92902 11.458 8.14098 11.5458 8.29726 11.7021C8.45354 11.8584 8.54134 12.0703 8.54134 12.2913V15.208C8.54134 15.429 8.45354 15.641 8.29726 15.7973C8.14098 15.9535 7.92902 16.0413 7.70801 16.0413H4.79134C4.57033 16.0413 4.35837 15.9535 4.20209 15.7973C4.04581 15.641 3.95801 15.429 3.95801 15.208V12.2913Z"
					stroke={isActive ? "var(--activeIcon)" : "var(--inactiveIcon)"}
					strokeWidth="1.5"
					strokeLinecap="round"
					strokeLinejoin="round"
				/>
				<path
					d="M11.458 4.79134C11.458 4.57033 11.5458 4.35837 11.7021 4.20209C11.8584 4.04581 12.0703 3.95801 12.2913 3.95801H15.208C15.429 3.95801 15.641 4.04581 15.7973 4.20209C15.9535 4.35837 16.0413 4.57033 16.0413 4.79134V7.70801C16.0413 7.92902 15.9535 8.14098 15.7973 8.29726C15.641 8.45354 15.429 8.54134 15.208 8.54134H12.2913C12.0703 8.54134 11.8584 8.45354 11.7021 8.29726C11.5458 8.14098 11.458 7.92902 11.458 7.70801V4.79134Z"
					stroke={isActive ? "var(--activeIcon)" : "var(--inactiveIcon)"}
					strokeWidth="1.5"
					strokeLinecap="round"
					strokeLinejoin="round"
				/>
				<path
					d="M11.458 12.2913C11.458 12.0703 11.5458 11.8584 11.7021 11.7021C11.8584 11.5458 12.0703 11.458 12.2913 11.458H15.208C15.429 11.458 15.641 11.5458 15.7973 11.7021C15.9535 11.8584 16.0413 12.0703 16.0413 12.2913V15.208C16.0413 15.429 15.9535 15.641 15.7973 15.7973C15.641 15.9535 15.429 16.0413 15.208 16.0413H12.2913C12.0703 16.0413 11.8584 15.9535 11.7021 15.7973C11.5458 15.641 11.458 15.429 11.458 15.208V12.2913Z"
					stroke={isActive ? "var(--activeIcon)" : "var(--inactiveIcon)"}
					strokeWidth="1.5"
					strokeLinecap="round"
					strokeLinejoin="round"
				/>
			</svg>
		</div>
	);
};
