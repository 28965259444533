import styles from "@src/components/helpers.module.scss";
import React from "react";

export const SeniorIcon = () => {
	return (
		<div className={styles.iconContainer}>
			<svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
				<path
					d="M6.08592 3.77292C6.47966 3.77292 6.85728 3.61651 7.1357 3.33809C7.41411 3.05967 7.57053 2.68206 7.57053 2.28831C7.57053 1.89457 7.41411 1.51696 7.1357 1.23854C6.85728 0.960124 6.47966 0.803711 6.08592 0.803711C5.69218 0.803711 5.31457 0.960124 5.03615 1.23854C4.75773 1.51696 4.60132 1.89457 4.60132 2.28831C4.60132 2.68206 4.75773 3.05967 5.03615 3.33809C5.31457 3.61651 5.69218 3.77292 6.08592 3.77292ZM10.3748 7.73186C10.1123 7.73186 9.86054 7.83614 9.67493 8.02175C9.48932 8.20736 9.38504 8.45911 9.38504 8.7216V8.83476C9.38504 8.92226 9.4198 9.00617 9.48167 9.06804C9.54354 9.12991 9.62746 9.16467 9.71495 9.16467C9.80245 9.16467 9.88637 9.12991 9.94824 9.06804C10.0101 9.00617 10.0449 8.92226 10.0449 8.83476V8.7216C10.0449 8.6341 10.0796 8.55019 10.1415 8.48832C10.2034 8.42645 10.2873 8.39169 10.3748 8.39169C10.4623 8.39169 10.5462 8.42645 10.6081 8.48832C10.6699 8.55019 10.7047 8.6341 10.7047 8.7216V13.6525C10.7047 13.74 10.7394 13.8239 10.8013 13.8857C10.8632 13.9476 10.9471 13.9824 11.0346 13.9824C11.1221 13.9824 11.206 13.9476 11.2679 13.8857C11.3298 13.8239 11.3645 13.74 11.3645 13.6525V8.7216C11.3645 8.45911 11.2602 8.20736 11.0746 8.02175C10.889 7.83614 10.6373 7.73186 10.3748 7.73186ZM8.62921 6.14136L8.06539 5.89524L8.5916 6.11859L8.62921 6.14136ZM9.03369 6.49172C8.91883 6.3537 8.78221 6.23535 8.62921 6.14136L10.7047 7.04829V7.01661V7.01727C10.736 7.38018 10.4556 7.6992 10.0782 7.72988C9.70407 7.75958 9.37482 7.49433 9.33885 7.13571V7.13505L9.33391 7.10668C9.32395 7.05828 9.31128 7.01048 9.29597 6.9635C9.2381 6.79153 9.14921 6.63163 9.03369 6.49172Z"
					fill="#101828"
				/>
				<path
					fillRule="evenodd"
					clipRule="evenodd"
					d="M10.7048 7.0164L8.59175 6.11904C8.42631 6.02286 8.24955 5.94761 8.06554 5.89503V13.3405C8.06548 13.5097 8.00037 13.6725 7.88368 13.7951C7.76699 13.9177 7.60764 13.9908 7.43859 13.9992C7.26953 14.0077 7.10371 13.9508 6.97539 13.8404C6.84708 13.73 6.7661 13.5746 6.74919 13.4061L6.41928 10.107C6.41709 10.0852 6.41599 10.0633 6.41598 10.0414H5.75616C5.75616 10.0634 5.75506 10.0852 5.75286 10.107L5.42295 13.4061C5.40604 13.5746 5.32506 13.73 5.19675 13.8404C5.06844 13.9508 4.90261 14.0077 4.73356 13.9992C4.5645 13.9908 4.40515 13.9177 4.28846 13.7951C4.17177 13.6725 4.10666 13.5097 4.1066 13.3405V8.69499C3.55268 8.62142 3.08717 8.39873 2.74208 8.05991C2.54414 7.86629 2.38757 7.63448 2.28186 7.37855C2.17616 7.12262 2.12351 6.8479 2.12713 6.57102C2.12994 6.295 2.18736 6.02226 2.29607 5.76854C2.40479 5.51481 2.56265 5.28511 2.76056 5.09269C3.18614 4.67733 3.79285 4.43286 4.52064 4.43286H6.9356C8.57625 4.43286 9.55642 5.00097 10.1093 5.673C10.3271 5.93644 10.4942 6.23789 10.6022 6.56211C10.6504 6.70727 10.688 6.85672 10.7042 7.00848V7.01343L10.7048 7.01541V7.0164ZM3.72093 7.13583C3.80572 7.21897 3.92911 7.29947 4.1066 7.34961V5.80859C3.94164 5.85874 3.82156 5.93693 3.73611 6.02007C3.66114 6.09356 3.60153 6.18122 3.56075 6.27796C3.51997 6.37469 3.49883 6.47858 3.49857 6.58356C3.49659 6.7947 3.57511 6.99265 3.72093 7.13583Z"
					fill="#101828"
				/>
			</svg>
		</div>
	);
};
