import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { AxiosError } from "axios";
import { toast } from "sonner";
import { ApiProvider } from "../ApiProvider";
import { getInitialState } from "../helpers";

interface ISaveRule {
	AllowedSplits: number;
	Visibility: string;
	Markups: Array<{
		Export_Destination_ID: number;
	}>;
	RuleName: string;
}

interface IMarkups {
	Export_Destination_ID: number;
	RuleId?: number;
}

export interface ISavedRules {
	Is_System?: boolean;
	AllowedSplits: number;
	Visibility: string;
	RuleName: string;
	RuleId?: number;
	Markups: Array<IMarkups>;
	Last_Modified_By_User?: string;
	Last_Modified_Date?: Date;
}

const initialState = getInitialState<Array<ISavedRules>>([]);

export const getSavedRules = createAsyncThunk<Array<ISavedRules>>("savedRule/getSavedRules", async (_, { rejectWithValue }) => {
	try {
		return await ApiProvider.default.get<Array<ISavedRules>>("/api/Pricing/GetSavedSharingRules");
	} catch (error) {
		if (error instanceof AxiosError) return rejectWithValue(error.message);
		else return rejectWithValue("Unknown error");
	}
});

export const saveRule = createAsyncThunk<ISaveRule, ISaveRule>("savedRule/saveRule", async (payload, { rejectWithValue }) => {
	try {
		await ApiProvider.default.post<Array<ISavedRules>>("/api/Pricing/SaveSharingRule", payload);
		toast.success("Rule successfully saved");
		return payload;
	} catch (error) {
		if (error instanceof AxiosError) return rejectWithValue(error.message);
		else return rejectWithValue("Unknown error");
	}
});

export const deleteRule = createAsyncThunk<{ RuleId: number }, { RuleId: number }>(
	"savedRule/deleteRule",
	async (payload, { rejectWithValue }) => {
		try {
			await ApiProvider.default.delete<unknown>("/api/Pricing/DeleteSavedSharingRule", payload);
			toast.success("Rule deleted successfully");
			return payload;
		} catch (error) {
			if (error instanceof AxiosError) return rejectWithValue(error.message);
			else return rejectWithValue("Unknown error");
		}
	},
);

export const savedRulesSlice = createSlice({
	name: "savedRule",
	initialState,
	reducers: {},
	extraReducers: (builder) => {
		builder
			.addCase(getSavedRules.pending, (state) => {
				state.loading = true;
			})
			.addCase(getSavedRules.fulfilled, (state, action) => {
				state.data = action.payload;
				state.loading = false;
				state.version++;
				state.init = true;
				state.error = null;
			})
			.addCase(getSavedRules.rejected, (state, action) => {
				state.loading = false;
				state.error = `${action.payload}`;
			})
			.addCase(saveRule.fulfilled, (state, action) => {
				state.data.push(action.payload);
			})
			.addCase(deleteRule.fulfilled, (state, action) => {
				const data = state.data;
				const filteredData = data.filter((x) => x.RuleId !== action.payload.RuleId);
				state.data = filteredData;
			});
	},
});
