import helperStyles from "@src/components/helpers.module.scss";
import React from "react";

export const DotsIcon: React.FC = () => {
	return (
		<div className={helperStyles.iconContainer}>
			<svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
				<g clipPath="url(#clip0_1103_26786)">
					<path
						fillRule="evenodd"
						clipRule="evenodd"
						d="M1.75 5.25C2.21413 5.25 2.65925 5.43437 2.98744 5.76256C3.31563 6.09075 3.5 6.53587 3.5 7C3.5 7.46413 3.31563 7.90925 2.98744 8.23744C2.65925 8.56563 2.21413 8.75 1.75 8.75C1.28587 8.75 0.840752 8.56563 0.512563 8.23744C0.184374 7.90925 0 7.46413 0 7C0 6.53587 0.184374 6.09075 0.512563 5.76256C0.840752 5.43437 1.28587 5.25 1.75 5.25ZM12.25 5.25C12.7141 5.25 13.1592 5.43437 13.4874 5.76256C13.8156 6.09075 14 6.53587 14 7C14 7.46413 13.8156 7.90925 13.4874 8.23744C13.1592 8.56563 12.7141 8.75 12.25 8.75C11.7859 8.75 11.3408 8.56563 11.0126 8.23744C10.6844 7.90925 10.5 7.46413 10.5 7C10.5 6.53587 10.6844 6.09075 11.0126 5.76256C11.3408 5.43437 11.7859 5.25 12.25 5.25ZM7.1582 5.25C7.62233 5.25 8.06745 5.43437 8.39564 5.76256C8.72383 6.09075 8.9082 6.53587 8.9082 7C8.9082 7.46413 8.72383 7.90925 8.39564 8.23744C8.06745 8.56563 7.62233 8.75 7.1582 8.75C6.69407 8.75 6.24895 8.56563 5.92076 8.23744C5.59257 7.90925 5.4082 7.46413 5.4082 7C5.4082 6.53587 5.59257 6.09075 5.92076 5.76256C6.24895 5.43437 6.69407 5.25 7.1582 5.25Z"
						fill="#3C4250"
					/>
				</g>
				<defs>
					<clipPath id="clip0_1103_26786">
						<rect width="14" height="14" fill="white" />
					</clipPath>
				</defs>
			</svg>
		</div>
	);
};
