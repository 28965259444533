import { getThunkAndSlice } from "@src/store/getThunkAndSlice";

interface TItemTagsInitialState {
	itemId: number;
}

const initialState = "";

export const { thunk: getItemPng, slice: getItemPngSlice } = getThunkAndSlice<TItemTagsInitialState, typeof initialState>({
	initialState,
	path: "/api/System/GetItemPng",
	method: "getFile"
});
