import { getThunkAndSlice } from "@src/store/getThunkAndSlice";

const initialState = new Array<{
	Exchange_Name: string;
	Customer_ID: number | null;
	Show_On_Invoice: boolean;
	Supports_Electronic_Transfer: boolean;
	Requires_Transfer_Proof: boolean;
	ExplanationTip: string;
}>();

export const { thunk: listExchanges, slice: listExchangesSlice } = getThunkAndSlice<Record<string, never>, typeof initialState>({
	initialState,
	path: "/api/Sales/ListExchanges",
});
