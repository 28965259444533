export const intersection = <T>(arr1: Array<T>, arr2: T | Array<T>): Array<T> =>
	Array.isArray(arr2) ? arr1.filter((value) => arr2.includes(value)) : arr1.filter((value) => value === arr2);

export const difference = <T>(arr1: Array<T>, arr2: T | Array<T>): Array<T> =>
	Array.isArray(arr2) ? arr1.filter((value) => !arr2.includes(value)) : arr1.filter((value) => value !== arr2);

export const union = <T>(arr1: Array<T>, arr2: T | Array<T>): Array<T> =>
	Array.isArray(arr2) ? [...new Set([...arr1, ...arr2])] : [...new Set([...arr1, arr2])];

export const getMinNumber = (arr: Array<number>) => (arr.length > 0 ? arr.reduce((a, b) => Math.min(a, b), Infinity) : 0);

export const getUniqueArrayValues = <T>(arr: Array<T>) => Array.from(new Set(arr));

export const mapDictionaryKeysToValues = <K extends string | number | symbol, V>(dictionary: Record<K, V>, keys: Array<K>) =>
	keys.reduce<Array<V>>((acc, key) => {
		const value = dictionary[key];

		return value ? acc.concat(value) : acc;
	}, []);

export const groupByProperty = <T, P extends keyof T>(array: Array<T>, property: P, keyTransformFn?: (key: T[P]) => T[P]) =>
	array.reduce<Map<T[P], Array<T>>>((acc, element) => {
		const key = keyTransformFn ? keyTransformFn(element[property]) : element[property];

		const values = acc.get(key);

		return values ? acc.set(key, [...values, element]) : acc.set(key, [element]);
	}, new Map());

export const transformMapValues = <K, V, T>(map: Map<K, V>, transformFn: (value: V) => T) =>
	new Map(Array.from(map.entries()).map<[K, T]>(([key, value]) => [key, transformFn(value)]));

export const convertMapToRecord = <K extends string | number | symbol, V>(map: Map<K, V>): Record<K, V> => {
	const record: Record<K, V> = {} as Record<K, V>;

	map.forEach((value, key) => {
		record[key] = value;
	});

	return record;
};

export type NumericProperty<T> = {
	[K in keyof T]: T[K] extends number ? K : never;
}[keyof T];

export const sortCompareFnFactory =
	<T>(property: NumericProperty<T>, order: "ASC" | "DESC" = "ASC") =>
	(a: T, b: T) => {
		return order === "ASC" ? +a[property] - +b[property] : +b[property] - +a[property];
	};

export const toggleArrayElement = <T>(array: Array<T>, element: T) =>
	array.includes(element) ? difference(array, element) : union(array, element);

export const mapProperties = <T, K extends keyof T>(array: Array<T>, properties: Array<K>, transformFn: (value: T[K]) => T[K]) =>
	array.map<T>((element) => {
		const transformedProperties = properties.reduce<Partial<T>>((acc, property) => {
			acc[property] = transformFn(element[property]);
			return acc;
		}, {});

		return {
			...element,
			...transformedProperties,
		};
	});
