import styles from "@src/components/helpers.module.scss";
import React from "react";

export const GreenCheckmark = () => {
	return (
		<div className={styles.iconContainer}>
			<svg width="14" height="11" viewBox="0 0 14 11" fill="none" xmlns="http://www.w3.org/2000/svg">
				<path
					d="M13 1L4.75 10L1 5.90909"
					stroke="#4E9D3A"
					strokeWidth="1.66667"
					strokeLinecap="round"
					strokeLinejoin="round"
				/>
			</svg>
		</div>
	);
};
