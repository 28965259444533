import { getThunkAndSlice } from "@src/store/getThunkAndSlice";

const initialState = new Array<{
	State: string;
	State_Name: string;
	Country_Code: string;
}>();

export const { thunk: listStates, slice: listStatesSlice } = getThunkAndSlice<Record<string, never>, typeof initialState>({
	initialState,
	path: "/api/System/ListStates",
});
