import { getThunkAndSlice } from "@src/store/getThunkAndSlice";

const initialState = new Array<{
	Notification_ID: number;
	Message: string;
	Subject: string;
	Created_Date: Date;
	Expiration_Date: Date;
	Must_Accept: boolean;
	Type: string;
	Read_Date?: Date | null;
	Status: string;
	Type_ID: number;
	NotificationVersion: string;
	TermsandConditionsMessage: string;
	TermsandConditionsNotification_ID: number;
	PrivacyPolicyMessage: string;
	PrivacyPolicyNotification_ID: number;
}>();

export const { thunk: getMandatoryNotifications, slice: getMandatoryNotificationsSlice } = getThunkAndSlice<
	Record<string, never>,
	typeof initialState
>({
	initialState,
	path: "/api/Notifications/GetMandatoryNotifications",
});
