import { getThunkAndSlice } from "@src/store/getThunkAndSlice";
import { pricingActions } from "..";
import { RootState } from "@src/store/store";

const initialState = false;

export const { thunk: releaseListings, slice: releaseListingsSlice } = getThunkAndSlice<
	{
		ListingIds: Array<number>;
	},
	typeof initialState
>({
	initialState,
	path: "/api/Pricing/ReleaseListings",
	method: "post",
	onAfterRequest: async (data, params, state, dispatch) => {
		const rootState = state as RootState;

		const newData = rootState.pricing.eventInventoryAll.data.map((event) => {
			return {
				...event,
				On_Hold_Comments: "",
				Consignor_Hold: false,
			};
		});
		dispatch(pricingActions.updateInventoryAll({ data: newData }));
		return data;
	},
});
