import { fromType } from "@src/store/helpers";
import { getThunkAndSlice } from "@src/store/getThunkAndSlice";
import { createSelector, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { MapStyle } from "react-map-gl";
import { castDraft } from "immer";
import { RootState } from "@src/store/store";

enum MatchStatus {
	UNMATCHED,
	OWN_LISTING,
	OTHER_CONSIGNOR_LISTING,
}

interface MatchedSection {
	SectionName: string;
	MatchStatus: MatchStatus;
}

export interface MapDataDTO {
	MapDataJSON?: MapStyle;
	MatchedSections: Array<MatchedSection>;
}
const initialState = fromType<MapDataDTO>({
	MapDataJSON: undefined,
	MatchedSections: [],
});
export const {
	thunk: getEventMap,
	slice: hiddenGetEventMapSlice,
	extraReducers: hiddenExtraReducers,
} = getThunkAndSlice<
	{
		eventId: number;
		venueName: string;
		getMatchedSections: boolean;
	},
	typeof initialState
>({
	initialState,
	path: "/api/Marketplace/SeatGeek/Event/Map",
	cacheId: "eventId",
});
export const getEventMapSlice = createSlice({
	name: hiddenGetEventMapSlice.name,
	initialState: hiddenGetEventMapSlice.getInitialState(),
	reducers: {
		updateEventMap: (state, action: PayloadAction<MapDataDTO>) => {
			state.data = castDraft(action.payload);
			state.version++;
		},
		reset(state) {
			state.data = castDraft(initialState);
			state.version++;
		},
	},
	extraReducers: hiddenExtraReducers,
});

const _selectMapStyle = (state: RootState) => state.marketplace.seatgeek.eventMap.data.MapDataJSON;
const _selectMapLoading = (state: RootState) => state.marketplace.seatgeek.eventMap.loading;
const _selectMapError = (state: RootState) => state.marketplace.seatgeek.eventMap.error ?? "No Map Data Available";

export const selectVenueMapData = createSelector(
	[_selectMapStyle, _selectMapLoading, _selectMapError],
	(mapStyle, mapLoading, mapError) => ({ mapStyle, mapLoading, mapError }),
);
