import { fromType, SortField } from "@src/store/helpers";
import { getThunkAndSlice } from "@src/store/getThunkAndSlice";

export interface QuerySalesParamsDto {
	SaleFromDate?: Date | string;
	SaleToDate?: Date | string;
	EventFromDate?: Date | string;
	EventToDate?: Date | string;
	PaymentFromDate?: Date | string;
	PaymentToDate?: Date | string;
	BuyerPOID?: string;
	CategoryId?: number;
	EventHeadlinerId?: number;
	VenueId?: number;
	InvoiceId?: number;
	InvoiceIds?: Array<number>;
	EventId?: number;
	CompanyName?: string;
	ExchangeName?: string;
	Sections?: string;
	Rows?: string;
	Quantity?: number;
	SaleStatus?: string;
	OrderByColumn?: Array<SortField>;
	StartIndex?: number;
	Limit?: number;
	ForceRefresh?: boolean;
	SearchString?: string;
	SearchTag?: string;
	Page?: number;
}

enum PdfsAttached {
	None = 0,
	Some = 1,
	All = 2,
}

export interface SalesInfo {
	Airbill_Date: string | null;
	Airbill_User: string | null;
	Automation_Notes: string | null;
	Buyer_PO_ID: string | null;
	Cancelled_Date: string | null;
	Cat_Desc: string | null;
	Commission_Amount: number | null;
	Commission_Rate: number | null;
	Company_Name: string;
	Completed_Date: string | null;
	Cost_Locked: boolean | null;
	Event_ID: number | null;
	Event_Name: string | null;
	Event_Headliner: string | null;
	Event_Date: string | null;
	Event_Time: string | null;
	Event_Tags: string | null;
	Exchange_Name: string;
	External_Notes: string;
	InHand: boolean | null;
	InHandDate: string | null;
	Internal_Notes: string;
	Invoice_Amount: number;
	Invoice_ID: number;
	Is_eDelivery: boolean;
	Item_Price: number;
	Item_Tags: string | null;
	Listing_ID: number | null;
	Num_Barcodes: number;
	Num_PDFs: number;
	PO_Date: string | null;
	PO_ID: number;
	Payment_Amount: number | null;
	Payment_Date: string | null;
	Payment_Details: string | null;
	Payment_Notes: string | null;
	Payment_Type: string | null;
	PdfsAttached: number;
	Quantity: number;
	ROI: number;
	ROIPercent: number;
	Row: string;
	Sale_Date: string | null;
	Sale_Status: string;
	Sale_Type: string;
	Seat_From: number | null;
	Seat_Thru: number | null;
	Section: string;
	Shipping_Comments: string | null;
	Tickets_Available_Date: string | null;
	Total_Cost: number;
	Venue_City: string;
	Venue_Name: string;
	Venue_State: string;
}

interface PagedSalesInfo {
	itemCount: number;
	SalesInfo: Array<SalesInfo>;
}

const initialState = fromType<PagedSalesInfo>({
	itemCount: 0,
	SalesInfo: [],
});

export const { thunk: querySales, slice: querySalesSlice } = getThunkAndSlice<QuerySalesParamsDto, typeof initialState>({
	initialState,
	path: "/api/Sales/QuerySales",
	method: "post",
});
