import helperStyles from "@src/components/helpers.module.scss";
import React from "react";

export const PDFIcon: React.FC<{ color?: string }> = ({ color = "#101828" }) => {
	return (
		<div className={helperStyles.iconContainer}>
			<svg width="10" height="11" viewBox="0 0 10 11" fill="none" xmlns="http://www.w3.org/2000/svg">
				<path
					d="M4.33164 5.50215C4.21445 5.1584 4.2168 4.49453 4.28477 4.49453C4.48164 4.49453 4.46289 5.2873 4.33164 5.50215ZM4.2918 6.51621C4.11133 6.95019 3.88633 7.44648 3.62617 7.86328C4.05508 7.71289 4.54023 7.49375 5.10039 7.39277C4.80273 7.18652 4.5168 6.89004 4.2918 6.51621ZM2.08633 9.19746C2.08633 9.21465 2.3957 9.08144 2.9043 8.33379C2.74727 8.46914 2.22227 8.86016 2.08633 9.19746ZM5.88086 3.4375H9.06836V10.4844C9.06836 10.7701 8.81758 11 8.50586 11H0.630859C0.319141 11 0.0683594 10.7701 0.0683594 10.4844V0.515625C0.0683594 0.229883 0.319141 0 0.630859 0H5.31836V2.92188C5.31836 3.20547 5.57148 3.4375 5.88086 3.4375ZM5.69336 7.12852C5.22461 6.86641 4.91289 6.50547 4.69258 5.97266C4.79805 5.5752 4.96445 4.97148 4.83789 4.59336C4.72773 3.96172 3.84414 4.02402 3.71758 4.44727C3.60039 4.84043 3.7082 5.39473 3.90742 6.10156C3.63555 6.69453 3.23477 7.48945 2.95117 7.94492C2.94883 7.94492 2.94883 7.94707 2.94648 7.94707C2.31133 8.2457 1.22148 8.90312 1.66914 9.40801C1.80039 9.55625 2.04414 9.62285 2.17305 9.62285C2.59258 9.62285 3.00977 9.23613 3.60508 8.29512C4.20977 8.1125 4.87305 7.88477 5.45664 7.79668C5.96523 8.05019 6.56055 8.21562 6.95664 8.21562C7.64102 8.21562 7.68789 7.52812 7.41836 7.2832C7.09258 6.99102 6.1457 7.0748 5.69336 7.12852ZM8.9043 2.25586L6.60742 0.150391C6.50195 0.0537109 6.35898 0 6.20898 0H6.06836V2.75H9.06836V2.61895C9.06836 2.48359 9.00977 2.35254 8.9043 2.25586ZM7.16758 7.74082C7.26367 7.68281 7.10898 7.48516 6.16445 7.54746C7.03398 7.88691 7.16758 7.74082 7.16758 7.74082Z"
					fill={color}
				/>
			</svg>
		</div>
	);
};
