import helperStyles from "@src/components/helpers.module.scss";
import React from "react";

export const CopyIcon: React.FC = () => {
	return (
		<div className={helperStyles.iconContainer}>
			<svg width="12" height="13" viewBox="0 0 12 13" fill="none" xmlns="http://www.w3.org/2000/svg">
				<path
					d="M8.38636 0.5H1.84091C1.24091 0.5 0.75 0.990909 0.75 1.59091V9.22727H1.84091V1.59091H8.38636V0.5ZM10.0227 2.68182H4.02273C3.42273 2.68182 2.93182 3.17273 2.93182 3.77273V11.4091C2.93182 12.0091 3.42273 12.5 4.02273 12.5H10.0227C10.6227 12.5 11.1136 12.0091 11.1136 11.4091V3.77273C11.1136 3.17273 10.6227 2.68182 10.0227 2.68182ZM10.0227 11.4091H4.02273V3.77273H10.0227V11.4091Z"
					fill="#8A8C91"
				/>
			</svg>
		</div>
	);
};
