import { ETicket, HardTicket, InstantDelivery, MobileTicket } from "@src/assets/general-icons";
import { ObstructedIcon } from "@src/assets/general-icons/ObstructedIcon";
import { SeniorIcon } from "@src/assets/general-icons/SeniorIcon";
import { ActiveListing } from "@src/store/marketplace/seatgeek/getActiveListingsSlice";
import React from "react";

export const enum ExcludeItemLabeEnum {
	hardTicket = "Hard Ticket/UPS",
	instantDelivery = "Instant Delivery",
	mobileTicket = "Mobile Ticket",
	eTicket = "E - Ticket",
	obstructed = "Obstructed",
	seniorYouth = "Senior/Youth",
}

export type TExcludeMethodItem = {
	id: number;
	label: string;
	keys: Array<number>;
	group: string;
	icon: React.JSX.Element;
	stockType?: ActiveListing["st"];
};

export const excludeMethodItems: Array<TExcludeMethodItem> = [
	// Exclude Delivery Method
	{
		id: 1,
		label: ExcludeItemLabeEnum.hardTicket,
		keys: [3, 5, 6, 7, 11],
		group: "Exclude Delivery Method",
		icon: <HardTicket />,
	},
	{ id: 2, label: ExcludeItemLabeEnum.instantDelivery, keys: [2], group: "Exclude Delivery Method", icon: <InstantDelivery /> },
	{
		id: 3,
		label: ExcludeItemLabeEnum.mobileTicket,
		stockType: "mobile",
		keys: [8, 9, 10, 12, 13, 14, 15],
		group: "Exclude Delivery Method",
		icon: <MobileTicket />,
	},
	{
		id: 4,
		label: ExcludeItemLabeEnum.eTicket,
		stockType: "pdf",
		keys: [1],
		group: "Exclude Delivery Method",
		icon: <ETicket />,
	},
	// Exclude Traits
	{ id: 5, label: ExcludeItemLabeEnum.obstructed, keys: [1], group: "Exclude Traits", icon: <ObstructedIcon /> },
	{ id: 6, label: ExcludeItemLabeEnum.seniorYouth, keys: [102], group: "Exclude Traits", icon: <SeniorIcon /> },
];
