import { getThunkAndSlice } from "@src/store/getThunkAndSlice";

const initialState = false;

interface AttachedTicket {
	FileName: string;
	ItemId: number;
}

export const { thunk: forceAttachPdfs, slice: forceAttachPdfsSlice } = getThunkAndSlice<
	{
		attachedTickets: Array<AttachedTicket>;
		listingId: number;
		quantity: number;
		eventName: string;
		eventDate: string;
		eventTime: string;
		eventHeadliner: string;
		catId: number;
		section: string;
		row: string;
	},
	typeof initialState
>({
	initialState,
	path: "/api/System/ForceAttachPdfs",
	method: "post",
});
