import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getInitialState } from "../helpers";
import { AxiosError } from "axios";
import { ApiProvider } from "../ApiProvider";
import { SetListingRulesPayload } from "./autopricerSlice";

type IListingRule = SetListingRulesPayload["rules"];

const initialState = getInitialState<IListingRule | null>(null);

export const getQuickActionListingRules = createAsyncThunk<IListingRule, number>(
	"getQuickActionListingRules/getListingRules",
	async (payload, { rejectWithValue }) => {
		try {
			const response = await ApiProvider.default.get<IListingRule>(`api/AutoPricer/GetListingRules?listingId=${payload}`);
			return response;
		} catch (error) {
			if (error instanceof AxiosError) {
				return rejectWithValue(error.message);
			} else {
				return rejectWithValue("Unknown error");
			}
		}
	},
);

export const getListingRulesSlice = createSlice({
	name: "getListingRules",
	initialState,
	reducers: {
		resetListingRules: (state) => {
			state.data = null;
		},
	},
	extraReducers: (builder) => {
		builder
			.addCase(getQuickActionListingRules.pending, (state) => {
				state.loading = true;
				state.data = null;
			})
			.addCase(getQuickActionListingRules.fulfilled, (state, action) => {
				state.loading = false;
				state.data = action.payload;
				state.version++;
			})
			.addCase(getQuickActionListingRules.rejected, (state, action) => {
				state.loading = false;
				state.data = null;
				state.error = `${action.payload}`;
			});
	},
});
