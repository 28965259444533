import helperStyles from "@src/components/helpers.module.scss";
import React from "react";

export const ExclamationIcon: React.FC = () => {
	return (
		<div className={helperStyles.iconContainer} style={{ cursor: "pointer" }}>
			<svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
				<path
					d="M6.00033 0.166016C2.78033 0.166016 0.166992 2.77935 0.166992 5.99935C0.166992 9.21935 2.78033 11.8327 6.00033 11.8327C9.22033 11.8327 11.8337 9.21935 11.8337 5.99935C11.8337 2.77935 9.22033 0.166016 6.00033 0.166016ZM6.58366 8.91602H5.41699V5.41602H6.58366V8.91602ZM6.58366 4.24935H5.41699V3.08268H6.58366V4.24935Z"
					fill="#C9C9C9"
				/>
			</svg>
		</div>
	);
};
