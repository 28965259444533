import { getThunkAndSlice } from "@src/store/getThunkAndSlice";

const initialState = new Array<{
	Export_Destination_ID: number;
	Icon_Url: string;
	Exchange_Name: string;
}>();

export const { thunk: listExportDestinations, slice: listExportDestinationsSlice } = getThunkAndSlice<
	Record<string, never>,
	typeof initialState
>({
	initialState,
	path: "/api/System/ListExportDestinations",
});
