import styles from "@src/components/helpers.module.scss";
import React from "react";

export const MobileTicket = () => {
	return (
		<div className={styles.iconContainer}>
			<svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
				<path
					d="M9.5 12.8337C10.1434 12.8337 10.6667 12.3104 10.6667 11.667V2.33366C10.6667 1.69024 10.1434 1.16699 9.5 1.16699H3.66667C3.02325 1.16699 2.5 1.69024 2.5 2.33366V11.667C2.5 12.3104 3.02325 12.8337 3.66667 12.8337H9.5ZM6.58333 9.91699C6.73804 9.91699 6.88642 9.97845 6.99581 10.0878C7.10521 10.1972 7.16667 10.3456 7.16667 10.5003C7.16667 10.655 7.10521 10.8034 6.99581 10.9128C6.88642 11.0222 6.73804 11.0837 6.58333 11.0837C6.42862 11.0837 6.28025 11.0222 6.17085 10.9128C6.06146 10.8034 6 10.655 6 10.5003C6 10.3456 6.06146 10.1972 6.17085 10.0878C6.28025 9.97845 6.42862 9.91699 6.58333 9.91699Z"
					fill="#101828"
				/>
			</svg>
		</div>
	);
};
