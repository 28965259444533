import { getThunkAndSlice } from "@src/store/getThunkAndSlice";
import { pricingActions } from "..";
import { RootState } from "@src/store/store";

const initialState = false;
export interface IListingToUpdate {
	ListingId: string;
	NumGroups: string;
}

export const { thunk: updateSplitListingAdvanced, slice: updateSplitListingAdvancedSlice } = getThunkAndSlice<
	IListingToUpdate,
	typeof initialState
>({
	initialState,
	path: "/api/Pricing/SplitListingAdvanced",
	method: "post",
	onAfterRequest: async (data, _params, state, dispatch) => {
		const rootState = state as RootState;
		const EventId = rootState.pricing.selection.event?.Event_ID;
		const CompanyName = rootState.settings.companyName;

		if (!EventId) return;

		dispatch(
			pricingActions.queryEventInventoryAll({
				EventId,
				CompanyName,
				skipCache: true,
			}),
		);

		return data;
	},
});
