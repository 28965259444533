import styles from "@src/components/helpers.module.scss";
import React from "react";

export const InputXicon: React.FC = () => {
	return (
		<div className={styles.iconContainer}>
			<div className={styles.xIcon}>
				<svg height="12px" width="12px" viewBox="4 4 12 12" fill="currentColor" aria-hidden="true">
					<path d="M14.348 14.849c-0.469 0.469-1.229 0.469-1.697 0l-2.651-3.030-2.651 3.029c-0.469 0.469-1.229 0.469-1.697 0-0.469-0.469-0.469-1.229 0-1.697l2.758-3.15-2.759-3.152c-0.469-0.469-0.469-1.228 0-1.697s1.228-0.469 1.697 0l2.652 3.031 2.651-3.031c0.469-0.469 1.228-0.469 1.697 0s0.469 1.229 0 1.697l-2.758 3.152 2.758 3.15c0.469 0.469 0.469 1.229 0 1.698z"></path>
				</svg>
			</div>
		</div>
	);
};
