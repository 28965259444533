import styles from "@src/components/helpers.module.scss";
import React from "react";

export const HardTicket = () => {
	return (
		<div className={styles.iconContainer}>
			<svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
				<path
					d="M8.5 3C8.76522 3 9.01957 3.10536 9.20711 3.29289C9.39464 3.48043 9.5 3.73478 9.5 4V4.5H10.26C10.4099 4.50002 10.5578 4.53373 10.6929 4.59864C10.828 4.66355 10.9468 4.758 11.0405 4.875L11.7805 5.8005C11.9225 5.97785 12 6.19828 12 6.4255V8.5C12 8.76522 11.8946 9.01957 11.7071 9.20711C11.5196 9.39464 11.2652 9.5 11 9.5H10.5C10.5 9.89782 10.342 10.2794 10.0607 10.5607C9.77936 10.842 9.39782 11 9 11C8.60218 11 8.22064 10.842 7.93934 10.5607C7.65804 10.2794 7.5 9.89782 7.5 9.5H6C6 9.69698 5.9612 9.89204 5.88582 10.074C5.81044 10.256 5.69995 10.4214 5.56066 10.5607C5.42137 10.6999 5.25601 10.8104 5.07403 10.8858C4.89204 10.9612 4.69698 11 4.5 11C4.30302 11 4.10796 10.9612 3.92597 10.8858C3.74399 10.8104 3.57863 10.6999 3.43934 10.5607C3.30005 10.4214 3.18956 10.256 3.11418 10.074C3.0388 9.89204 3 9.69698 3 9.5C2.73478 9.5 2.48043 9.39464 2.29289 9.20711C2.10536 9.01957 2 8.76522 2 8.5V4C2 3.73478 2.10536 3.48043 2.29289 3.29289C2.48043 3.10536 2.73478 3 3 3H8.5ZM4.5 9C4.36739 9 4.24021 9.05268 4.14645 9.14645C4.05268 9.24021 4 9.36739 4 9.5C4 9.63261 4.05268 9.75979 4.14645 9.85355C4.24021 9.94732 4.36739 10 4.5 10C4.63261 10 4.75979 9.94732 4.85355 9.85355C4.94732 9.75979 5 9.63261 5 9.5C5 9.36739 4.94732 9.24021 4.85355 9.14645C4.75979 9.05268 4.63261 9 4.5 9ZM9 9C8.86739 9 8.74021 9.05268 8.64645 9.14645C8.55268 9.24021 8.5 9.36739 8.5 9.5C8.5 9.63261 8.55268 9.75979 8.64645 9.85355C8.74021 9.94732 8.86739 10 9 10C9.13261 10 9.25979 9.94732 9.35355 9.85355C9.44732 9.75979 9.5 9.63261 9.5 9.5C9.5 9.36739 9.44732 9.24021 9.35355 9.14645C9.25979 9.05268 9.13261 9 9 9ZM10.26 5.5H9.5V7.5H11V6.425L10.26 5.5Z"
					fill="#101828"
				/>
			</svg>
		</div>
	);
};
