import styles from "@src/components/helpers.module.scss";
import React from "react";

export const ResetIcon: React.FC = () => {
	return (
		<div className={styles.itemRotation}>
			<div className={styles.iconContainer}>
				<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="black">
					<path d="M12.0771 19C10.1231 19 8.46781 18.3223 7.11115 16.967C5.75515 15.611 5.07715 13.9563 5.07715 12.003C5.07715 10.0497 5.75515 8.39433 7.11115 7.037C8.46781 5.679 10.1231 5 12.0771 5C13.2658 5 14.3785 5.28233 15.4151 5.847C16.4525 6.41167 17.2885 7.2 17.9231 8.212V5H18.9231V10.23H13.6931V9.23H17.3931C16.8725 8.23333 16.1398 7.44567 15.1951 6.867C14.2518 6.289 13.2125 6 12.0771 6C10.4105 6 8.99381 6.58333 7.82715 7.75C6.66048 8.91667 6.07715 10.3333 6.07715 12C6.07715 13.6667 6.66048 15.0833 7.82715 16.25C8.99381 17.4167 10.4105 18 12.0771 18C13.3605 18 14.5188 17.6333 15.5521 16.9C16.5855 16.1667 17.3105 15.2 17.7271 14H18.7881C18.3475 15.4973 17.5098 16.705 16.2751 17.623C15.0405 18.541 13.6411 19 12.0771 19Z" />
				</svg>
			</div>
		</div>
	);
};
