import { getThunkAndSlice } from "@src/store/getThunkAndSlice";

interface RowSummary {
	Row: string;
	MinPrice: number;
	NumListings: number;
	NumTickets: number;
}

const initialState = new Array<RowSummary>();

export const { thunk: getRows, slice: getRowsSlice } = getThunkAndSlice<
	{
		eventId: number;
		sectionIds: Array<string>;
		sectionNames: Array<string>;
	},
	typeof initialState
>({
	initialState,
	path: "/api/Marketplace/SeatGeek/GetRows",
	method: "post",
});
