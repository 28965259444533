import { fromType } from "@src/store/helpers";
import { getThunkAndSlice } from "@src/store/getThunkAndSlice";

export interface ActiveListing {
	bp: number;
	dm: "electronic" | "shipped" | "willcall" | "local" | "venue";
	idl: boolean;
	ihd: string;
	q: number;
	r: string;
	s: string;
	st: "pdf" | "mobile" | "paper" | "paperless";
	mtc: number;
	bo?: boolean;
	ds: number;
	id: string;
	m: string;
	pn: string | null;
	sp: Array<number>;
	sro: boolean;
	wa: boolean;
	sglid?: unknown;
}

export interface EventListing extends ActiveListing {
	Match: number;
}

interface Venue {
	Address: string;
	City: string;
	Country: string;
	Id: number;
	Name: string;
	State: string;
}

export interface IEvent {
	Category: string;
	DateTbd: boolean;
	DateTimeUtc: string;
	Id: number;
	Name: string;
	Url: string;
	Venue: Venue;
}
export const IEventEmpty: IEvent = {
	Category: "",
	DateTbd: false,
	DateTimeUtc: "",
	Id: 0,
	Name: "",
	Url: "",
	Venue: {
		Address: "",
		City: "",
		Country: "",
		Id: 0,
		Name: "",
		State: "",
	},
};

export interface EventListingsResponse {
	Event: IEvent;
	Listings: Array<EventListing>;
	RefreshedAtUtc: string;
}
const initialState = fromType<EventListingsResponse>();
export const { thunk: getActiveListings, slice: getActiveListingsSlice } = getThunkAndSlice<
	{
		eventId: number;
	},
	typeof initialState
>({
	initialState,
	path: ({ eventId }) => `/api/Marketplace/SeatGeek/Event/${eventId}/Listings`,
	cacheId: "eventId",
});
