import helperStyles from "@src/components/helpers.module.scss";
import React from "react";

export const TicketsNow: React.FC = () => {
	return (
		<div className={helperStyles.iconContainer} style={{ width: "18px" }}>
			<svg
				xmlns="http://www.w3.org/2000/svg"
				xmlnsXlink="http://www.w3.org/1999/xlink"
				width="100%"
				height="100%"
				viewBox="0 0 25 25"
				version="1.1">
				<g id="surface1">
					<path
						style={{ stroke: "none", fillRule: "evenodd", fill: "rgb(1.176471%,61.176471%,87.058824%)", fillOpacity: 1 }}
						d="M -0.0546875 -0.0546875 C 8.277344 -0.0546875 16.609375 -0.0546875 24.945312 -0.0546875 C 24.945312 8.277344 24.945312 16.609375 24.945312 24.945312 C 16.609375 24.945312 8.277344 24.945312 -0.0546875 24.945312 C -0.0546875 16.609375 -0.0546875 8.277344 -0.0546875 -0.0546875 Z M -0.0546875 -0.0546875 "
					/>
					<path
						style={{ stroke: "none", fillRule: "evenodd", fill: "rgb(97.647059%,98.823529%,99.607843%)", fillOpacity: 1 }}
						d="M 9.097656 10.769531 C 8.488281 10.839844 7.894531 10.914062 7.308594 10.992188 C 6.816406 12.429688 6.574219 13.917969 6.585938 15.457031 C 6.640625 15.511719 6.695312 15.570312 6.753906 15.625 C 7.253906 15.664062 7.738281 15.679688 8.203125 15.679688 C 7.992188 16.140625 7.878906 16.625 7.867188 17.132812 C 5.371094 17.703125 4.277344 16.738281 4.574219 14.230469 C 4.851562 13.15625 5.09375 12.078125 5.300781 10.992188 C 4.855469 10.992188 4.410156 10.992188 3.960938 10.992188 C 3.988281 10.539062 4.0625 10.09375 4.183594 9.652344 C 4.664062 9.542969 5.148438 9.507812 5.636719 9.542969 C 5.71875 9.015625 5.832031 8.496094 5.972656 7.980469 C 6.601562 7.757812 7.234375 7.535156 7.867188 7.308594 C 7.941406 7.347656 8.015625 7.382812 8.089844 7.421875 C 7.855469 8.109375 7.707031 8.816406 7.644531 9.542969 C 8.207031 9.503906 8.765625 9.542969 9.320312 9.652344 C 9.246094 10.03125 9.171875 10.402344 9.097656 10.769531 Z M 9.097656 10.769531 "
					/>
					<path
						style={{ stroke: "none", fillRule: "evenodd", fill: "rgb(98.039216%,98.823529%,99.607843%)", fillOpacity: 1 }}
						d="M 13.894531 9.320312 C 15.066406 9.222656 15.867188 9.707031 16.292969 10.769531 C 17.285156 9.359375 18.570312 9.003906 20.144531 9.710938 C 20.878906 10.304688 21.15625 11.066406 20.980469 11.996094 C 20.59375 13.699219 20.242188 15.410156 19.921875 17.132812 C 19.289062 17.132812 18.65625 17.132812 18.023438 17.132812 C 18.351562 15.367188 18.6875 13.597656 19.027344 11.832031 C 18.988281 11.136719 18.617188 10.820312 17.914062 10.882812 C 17.195312 10.988281 16.730469 11.394531 16.519531 12.109375 C 16.082031 13.742188 15.691406 15.378906 15.347656 17.019531 C 14.71875 17.132812 14.085938 17.167969 13.449219 17.132812 C 13.753906 15.414062 14.089844 13.703125 14.453125 11.996094 C 14.390625 11.015625 13.867188 10.660156 12.890625 10.9375 C 12.089844 11.625 11.625 12.5 11.496094 13.558594 C 11.25 14.710938 11.023438 15.863281 10.824219 17.019531 C 10.183594 17.128906 9.535156 17.167969 8.871094 17.132812 C 9.351562 14.605469 9.835938 12.078125 10.324219 9.542969 C 10.917969 9.542969 11.515625 9.542969 12.109375 9.542969 C 12.066406 9.878906 12.046875 10.214844 12.054688 10.546875 C 12.179688 10.308594 12.347656 10.105469 12.554688 9.933594 C 12.972656 9.632812 13.417969 9.425781 13.894531 9.320312 Z M 13.894531 9.320312 "
					/>
					<path
						style={{ stroke: "none", fillRule: "evenodd", fill: "rgb(62.352941%,83.529412%,94.509804%)", fillOpacity: 1 }}
						d="M 9.097656 10.769531 C 8.550781 10.988281 7.953125 11.0625 7.308594 10.992188 C 7.894531 10.914062 8.488281 10.839844 9.097656 10.769531 Z M 9.097656 10.769531 "
					/>
				</g>
			</svg>
		</div>
	);
};
