import helperStyles from "@src/components/helpers.module.scss";
import React from "react";

export const ViaGogo = () => {
	return (
		<div className={helperStyles.iconContainer}>
			<div style={{ color: "black" }}>VG</div>
		</div>
	);
};
