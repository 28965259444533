import mixpanel, { Dict } from "mixpanel-browser";

const disableMixpanel = true;
const disableApiRequests = false;
const mixPanelToken = process.env.MIXPANEL_TOKEN;

export enum MixpanelCategory {
	// General
	API_REQUEST_TRIGGERED = "Portal API request",
	API_ERROR = "Portal API request error",
	SIDEBAR_ITEMS_CLICKED = "Portal Sidebar clicked",
	ERROR_429 = "429: Too many requests",
	APP_SWITCH = "Portal app switch clicked",

	// Filters
	FILTERS_BUTTON_CLICKED = "Portal Filters button clicked",
	FILTERS_APPLIED = "Portal Filters applied",
	SAVED_FILTERS = "Portal Saved filters",
	COPY_URL_CLICKED = "Portal Copy Url clicked",
	FILTERS_SWITCH_CLICKED = "Portal Filters switch clicked",

	// Event
	EVENT_CLICKED = "Portal Event clicked",
	CUSTOMIZE_CLICKED = "Portal Customize button clicked",
	EXPAND_EVENTS_CLICKED = "Portal Expand Events clicked",
	NEXT_7_DAYS_CLICKED = "Portal Next 7 days clicked",

	// Inventory
	INVENTORY_ACTIONS = "Portal Inventory actions",
	TAG_BUTTON_CLICKED = "Portal tag button clicked",
	EDIT_INVENTORY_CLICKED = "Portal Edit inventory clicked",
	AUTO_PRICER_CLICKED = "Portal Auto pricer clicked",
	SHARKING_OPTIONS_CLICKED = "Portal Sharking options clicked",

	// Map
	MAP_LOAD = "Portal Map loaded",
	MAP_INTERACTION = "Portal Map section picked",
	MAP_TAB_CLICKED = "Portal Map FS/C Tab clicked",
	MAP_RESET_CLICKED = "Portal Map Reset button clicked",
	MAP_CONTROLS_CLICKED = "Portal Map Controls clicked",

	// Active
	ACTIVE_TAB_CLICKED = "Portal Active Charts tab clicked",
	ACTIVE_FETCH_BUTTON_CLICKED = "Portal Active fetch button clicked",
	ACTIVE_FILTERS_CLICKED = "Portal Active  filters clicked",

	// Sold
	SOLD_TAB_CLICKED = "Portal Sold Charts tab clicked",
	SOLD_FETCH_BUTTON_CLICKED = "Portal Sold fetch button clicked",
	SOLD_FILTERS_CLICKED = "Portal Sold filters clicked",

	// Edit Listing (basic inputs)
	SECTION_FIELD_FOCUS = "Portal Section Field Interacted",
	SECTION_FIELD_SAVED = "Portal Section Field Saved",
	ROW_FIELD_FOCUS = "Portal Row Field Interacted",
	ROW_FIELD_SAVED = "Portal Row Field Saved",
	COST_FIELD_FOCUS = "Portal Cost Field Interacted",
	COST_FIELD_SAVED = "Portal Cost Field Saved",
	PRICE_FIELD_FOCUS = "Portal Price Field Interacted",
	PRICE_FIELD_SAVED = "Portal Price Field Saved",

	// Edit Listing (Ticket actions)
	TICKET_SELECTED = "Portal Ticket Selected",
	TICKET_DESELECTED = "Portal Ticket Deselected",
	BARCODE_COPIED = "Portal Barcode Copied",
	HOLD_TOGGLED = "Portal Hold Toggled",
	TICKETS_APPLIED = "Portal Tickets Applied",
	TICKETS_SAVED = "Portal Tickets Saved",
	TICKETS_RESET = "Portal Tickets Reset",

	// Edit Listing (Rules & Marketplaces)
	RULE_DROPDOWN_TOGGLED = "Portal Rule Toggled",
	RULE_SAVED = "Portal Rule Saved",
	MARKETPLACE_TOGGLED = "Portal Marketplace Toggled",
	SPLITS_INTERACTED = "Portal Splits Interacted",
	HIDE_SEATS_TOGGLED = "Portal Hide Seats Toggled",

	// Edit Listing (External, Internal Notes)
	INTERNAL_NOTES_ADDED = "Portal Internal Notes Added",
	EXTERNAL_NOTES_ADDED = "Portal External Notes Added",

	// Edit Listing (File Attackments)
	ATTACH_PDF_INTERACTION = "Portal Attach PDF Interaction",

	// Edit Listing (Advanced)
	PURCHASE_INFO_FOCUS = "Portal Purchase Info Interacted",
	PURCHASE_INFO_SAVED = "Portal Purchase Info Saved",
	SCHEDULE_PRICING_FOCUS = "Portal Schedule Pricing Interacted",
	SCHEDULE_PRICING_SAVED = "Portal Schedule Pricing Saved",
	FLASH_SALE_FOCUS = "Portal Flash Sale Interacted",
	SPLIT_LISTINGS_FOCUS = "Portal Split Listing Interacted",
	SPLIT_LISTINGS_SAVED = "Portal Split Listings Saved",

	// Additional Metrics
	EDIT_LISTING_RETENTION = "Portal Edit Listing Retention Duration",
	EDIT_LISTING_ABANDONMENT_RATE = "Portal Abandonment Rate",
	EDIT_LISTING_COMPLETION_RATE = "Portal Completion Rate",
}

if (mixPanelToken) {
	mixpanel.init(mixPanelToken, {
		debug: false,
		track_pageview: true,
		persistence: "localStorage",
	});
} else {
	console.log(
		"%cMixpanel token missing, tracking not available",
		"background-color:#4f44e0;border-radius:8px;padding: 2px 10px",
	);
}

export const mixpanelTrack = (event: MixpanelCategory, data?: Dict) => {
	if (!mixPanelToken || !event || disableMixpanel) return;
	if (event === MixpanelCategory.API_REQUEST_TRIGGERED && disableApiRequests) return;

	mixpanel.track(event, data);
};
