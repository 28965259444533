import { fromType } from "@src/store/helpers";
import { IEvent } from "./getActiveListingsSlice";
import { getThunkAndSlice } from "@src/store/getThunkAndSlice";

export interface Sale {
	bp: number;
	dm: string;
	id: string;
	idl: boolean;
	ihd: string;
	pn: string;
	putc?: Date;
	q: number;
	r: string;
	s: string;
	st: string;
}

export interface EventSales {
	Event: IEvent;
	Sales: Array<Sale>;
	RefreshedAtUtc: string;
}

const initialState = fromType<EventSales>({});
export const { thunk: getSoldListings, slice: getSoldListingsSlice } = getThunkAndSlice<
	{
		eventId: number;
	},
	typeof initialState
>({
	initialState,
	path: ({ eventId }) => `/api/Marketplace/SeatGeek/Event/${eventId}/Sales`,
	cacheId: "eventId",
});
