import { fromType, SortField } from "@src/store/helpers";
import { getThunkAndSlice } from "@src/store/getThunkAndSlice";

export interface IInventoryItem {
	Event_ID: number;
	Item_ID: number;
	Event_Name: string;
	Event_Headliner_ID: number;
	Event_Headliner: string;
	Event_Date: Date;
	Event_Time: string;
	Cat_ID: number;
	Cat_Desc: string;
	Cat_Type: string;
	Venue_ID: number;
	Venue_Name: string;
	TicketsNow_Production_ID: number;
	StubHub_Event_ID: number;
	StubHub_Listing_ID: number;
	StubHub_Expiration_Date?: Date | null;
	StubHub_Result: string;
	StubHubInt_Listing_ID?: number | null;
	StubHubInt_Event_ID?: number | null;
	StubHubInt_Result: string;
	Listing_ID: number;
	Confirmation_No: string;
	Section: string;
	Row: string;
	Seats: string;
	Quantity: number;
	Consignment: boolean;
	Company_Name: string;
	OnExchange: boolean;
	OnWeb: boolean;
	SH_eDelivery: boolean;
	StubHub_Splits?: number | null;
	Vivid_Splits?: number | null;
	Listing_Cost: number;
	Listing_Price: number;
	Listing_Face: number;
	Days_Since_Last_Price_Update: number;
	Is_AutoPriced: boolean;
	Is_No_Comparables: boolean;
	Is_At_Floor: boolean;
	Is_At_Ceiling: boolean;
	Is_Error: boolean;
	Floor_Price?: number | null;
	Ceiling_Price?: number | null;
	Allow_Price_Increase?: boolean | null;
	AutoPricer_User: string;
	Minutes_Per_Cycle?: number | null;
	Num_OnHold: number;
	Num_Consignor_Hold: number;
	On_Hold_Comments: string;
	Num_PDFs: number;
	Num_Barcodes: number;
	Num_Ticket_IDs: number;
	Hide_Seats?: boolean | null;
	TicketsNow_Listing_ID: number;
	TicketsNow_Result: string;
	Vivid_Listing_ID: number;
	Vivid_Result: string;
	TicketNetwork_Event_ID?: number | null;
	TicketNetwork_Listing_ID: number;
	TicketNetwork_Result: string;
	TicketNetwork_Exchange_ID: number;
	SeatGeek_Event_ID: number;
	SeatGeek_Listing_ID?: number | null;
	SeatGeek_Result: string;
	InHand: boolean;
	Export_Destination_ID_CSV: string;
	Tags: string;
	MasterRuleId?: number | null;
	MasterOrder?: number | null;
	MasterRuleName: string;
	PO_ID?: number | null;
	PO_Date?: Date | null;
	Is_Scheduled_Pricing?: boolean | null;
	Shared_To: string;
	Vivid_Production_ID?: number | null;
	Active_Tiers?: number | null;
	Is_Odd_Even: boolean;
	AXS_Listing_ID: number;
	FlashSaleEnabled?: boolean | null;
	FlashSaleDiscount?: number | null;
	Internal_Notes: string;
	External_Notes: string;
	Last_Price_Update: string;
	InHandDate?: Date | null;
}

export interface IInventoryData {
	itemCount: number;
	Inventory: Array<IInventoryItem>;
}
const initialState = fromType<IInventoryData>({
	itemCount: 0,
	Inventory: [],
});

export const { thunk: queryInventory, slice: queryInventorySlice } = getThunkAndSlice<
	{
		EventFromDate?: Date | null;
		EventToDate?: Date | null;
		POFromDate?: Date | null;
		POToDate?: Date | null;
		CategoryId?: number | null;
		EventHeadlinerId?: number | null;
		EventId: number;
		VenueId?: number | null;
		ListingId?: number | null;
		SearchString?: string | null;
		POId?: number | null;
		Sections?: string | null;
		Row?: string | null;
		Quantity?: number | null;
		DaysSinceLastUpdate?: number | null;
		CompanyName?: string | null;
		OrderByColumn?: Array<SortField> | null;
		UseDBPagination?: boolean | null;
		StartIndex?: number | null;
		Limit?: number | null;
		ForceRefresh?: boolean | null;
		SearchTag?: string | null;
	},
	typeof initialState
>({
	initialState,
	path: "/api/Pricing/QueryInventory",
	method: "post",
	cacheId: "EventId",
});
