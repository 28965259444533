import { DuplicateIcon, XIcon } from "@src/assets/general-icons";
import { systemActions } from "@src/store";
import { EventInventoryRecord } from "@src/store/pricing/queryEventInventoryAllSlice";
import { HeaderFiltersProps } from "@src/validation/HeaderFiltersValidation";
import React, { useEffect, useState } from "react";
import { Control, UseFormGetValues, UseFormReset, UseFormSetValue } from "react-hook-form";
import { useSearchParams } from "react-router-dom";
import { toast } from "sonner";
import { Button, ButtonVariant } from "../button/Button";
import { Dropdown } from "../dropdown/Dropdown";
import { DateInput } from "../input/DateInput";
import { Input } from "../input/Input";
import { SelectYup } from "../select/SelectYup";
import { Switch } from "../switch/Switch";
import { filtersEmptyValues } from "../utils/hookFormUtils";
import { MixpanelCategory, mixpanelTrack } from "../utils/mixPanelUtils";
import styles from "./AllFilters.module.scss";
import { GridSection } from "./GridSection";
import { useAppDispatch, useAppSelector } from "@src/store/hooks";

interface ICategoriesGeneric {
	label: string;
	value: string;
}

interface IAllOPtions {
	categoryOptions: Array<ICategoriesGeneric>;
	headlinerOptions: Array<ICategoriesGeneric>;
	venueOptions: Array<ICategoriesGeneric>;
	smartSearchOptions: Array<ICategoriesGeneric>;
}

interface IAllFilters extends IAllOPtions {
	control: Control;
	closeFilterModal: () => void;
	getValues: UseFormGetValues<HeaderFiltersProps>;
	setValue: UseFormSetValue<HeaderFiltersProps>;
	reset: UseFormReset<HeaderFiltersProps>;
	inventory: Array<EventInventoryRecord>;
}

export const AllFilters: React.FC<IAllFilters> = (props) => {
	const {
		control,
		closeFilterModal,
		getValues,
		setValue,
		reset,
		categoryOptions,
		headlinerOptions,
		venueOptions,
		smartSearchOptions,
	} = props;

	const [params, setParams] = useSearchParams();
	const savedFilterLS = JSON.parse(localStorage.portal_saved_filters ?? null);
	const [savedFilters, setSavedFilters] = useState(savedFilterLS);

	const switches = {
		showSold: params.get("showSold") === "true",
		showParking: (params.get("showParking") ?? "true") === "true",
		showCanceled: params.get("showCanceled") === "true",
	};

	const handleSwitchChange = (switchName: "showSold" | "showParking" | "showCanceled") => {
		const newValue = !switches[switchName];

		params.set(switchName, String(newValue));

		setParams(params);

		mixpanelTrack(MixpanelCategory.FILTERS_SWITCH_CLICKED, {
			switchName,
			value: newValue,
		});
	};

	const handleFullReset = () => {
		reset(filtersEmptyValues);
		["showSold", "showParking", "showCanceled"].forEach((x) => {
			params.delete(x);
		});
		setParams();
	};

	const handleFormSubmit = () => {
		// We don't need onSubmit here since the <Button type="submit"/> will bubble up
		// To the parent form inside the Header.tsx component, therefore submitting it.
		closeFilterModal();
	};

	const onFiltersSave = () => {
		const values = getValues();
		if (!savedFilters) {
			setSavedFilters([values]);
			localStorage.setItem("portal_saved_filters", JSON.stringify([values]));
			return;
		}
		const combinedFilters = [...savedFilters, values];

		setSavedFilters(combinedFilters);
		localStorage.setItem("portal_saved_filters", JSON.stringify(combinedFilters));

		mixpanelTrack(MixpanelCategory.SAVED_FILTERS, {
			data: combinedFilters,
		});
	};

	const savedFilterOptions = (savedFilters ?? []).map((item: HeaderFiltersProps, index: number) => ({
		id: index + 1,
		label: `Saved Filters ${index + 1}`,
		value: item,
		onClick: (value: HeaderFiltersProps) => {
			reset(filtersEmptyValues);
			const values = Object.entries(value) as Array<[keyof HeaderFiltersProps, string]>;
			values.forEach(
				([k, v]) =>
					v &&
					setValue(k, v, {
						shouldDirty: true,
					}),
			);
		},
	}));

	const filterOptionsWithReset = [
		{
			id: 0,
			label: "Select",
			onClick: reset,
		},
		...savedFilterOptions,
	];

	const copyUrlToClipboard = () => {
		if (!navigator.clipboard) {
			toast.warning("Feature not available on this wrowser");
			return;
		}
		navigator.clipboard.writeText(window.location.href);
		toast.success("Successfully copied URL to clipboard");

		mixpanelTrack(MixpanelCategory.COPY_URL_CLICKED, {
			data: window.location.href,
		});
	};

	const { data: allTags, loading: tagsLoading } = useAppSelector((state) => state.system.allTags);
	const dispatch = useAppDispatch();

	const tagOptions = allTags.map((i) => Object.values(i)).map(([x]) => ({ label: x, value: x }));

	useEffect(() => {
		if (allTags.length) return;
		dispatch(systemActions.listAllTags({}));
	}, []);

	return (
		<form onSubmit={handleFormSubmit} className={styles.root}>
			<div className={styles.header}>
				<div></div>
				<div>Filters</div>
				<div aria-hidden="true" onClick={closeFilterModal}>
					<XIcon color="white" />
				</div>
			</div>
			<div className={styles.savedFiltersContainer}>
				<Dropdown containerWidth="50%" dynamicText isDisabled={false} items={filterOptionsWithReset} />
			</div>
			<div className={styles.content}>
				<div className={styles.inner}>
					<GridSection headline="Smart Search">
						<SelectYup
							name="smartSearch"
							placeholder="Event name, Venue, etc."
							control={control}
							options={smartSearchOptions}
						/>
						<SelectYup name="tags" placeholder="Tags" isLoading={tagsLoading} control={control} options={tagOptions} />
					</GridSection>
					<GridSection headline="Event">
						<SelectYup name="category" placeholder="Category" control={control} options={categoryOptions} />
						<SelectYup name="eventHeadliner" placeholder="Headliner" control={control} options={headlinerOptions} />
						<SelectYup name="venue" placeholder="Venue" control={control} options={venueOptions} />
						<div className={styles.subItems}>
							<DateInput control={control} name="startDate" placeholder="Start Date" />
							<DateInput control={control} name="endDate" placeholder="End Date" />
						</div>
					</GridSection>
					<GridSection headline="Ticket">
						<Input control={control} name="section" placeholder="Section" />
						<Input control={control} name="row" placeholder="Row" />
						<Input control={control} type="number" name="minQuantity" placeholder="Min. Quantity" />
						<Input control={control} type="number" name="daysSinceLastUpdate" placeholder="Days Since Last Update" />
					</GridSection>
					<GridSection headline="Advanced">
						<Input name="listingId" placeholder="Listing ID" control={control} />
						<Input name="eventId" placeholder="Event ID" control={control} />
						<Input name="poId" placeholder="PO ID" control={control} />
						<div className={styles.subItems}>
							<DateInput control={control} name="poStartDate" placeholder="PO Start Date" />
							<DateInput control={control} name="poEndDate" placeholder="PO End Date" />
						</div>
					</GridSection>
					<div className={styles.switches}>
						<Button
							style={{
								padding: "0px 9px",
								fontSize: "11px",
							}}
							controlSize="sm"
							variant={ButtonVariant.Tertiary}>
							<Switch checked={switches.showSold} onChange={() => handleSwitchChange("showSold")} label="Show Sold" />
						</Button>
						<Button
							style={{
								padding: "0px 9px",
								fontSize: "11px",
							}}
							controlSize="sm"
							variant={ButtonVariant.Tertiary}>
							<Switch
								checked={switches.showParking}
								onChange={() => handleSwitchChange("showParking")}
								label="Show Parking"
							/>
						</Button>
						<Button
							style={{
								padding: "0px 9px",
								fontSize: "11px",
							}}
							controlSize="sm"
							variant={ButtonVariant.Tertiary}>
							<Switch
								checked={switches.showCanceled}
								onChange={() => handleSwitchChange("showCanceled")}
								label="Show Canceled"
							/>
						</Button>
					</div>
					<div className={styles.saveFiltersContainer}>
						<Button onClick={handleFullReset} variant={ButtonVariant.Quinary}>
							Clear All
						</Button>
						<div className={styles.subItems}>
							<Button
								onClick={onFiltersSave}
								style={{
									minWidth: "96px",
								}}
								variant={ButtonVariant.Tertiary}>
								Save
							</Button>
							<Button onClick={copyUrlToClipboard} variant={ButtonVariant.Tertiary}>
								<DuplicateIcon />
							</Button>
						</div>
					</div>
				</div>
			</div>
			<div className={styles.buttonContainer}>
				<Button
					style={{
						minWidth: "140px",
					}}
					type="submit">
					Apply Filters
				</Button>
			</div>
		</form>
	);
};
