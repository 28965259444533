import { getThunkAndSlice } from "@src/store/getThunkAndSlice";

interface Ticket {
	FileName: string;
	ItemId: number;
}

interface AttachedItem {
	ItemId: number;
	FileName: string;
	SeatNumber: number
}
interface Verification {
	isHeadlinerMatched: boolean;
	isEventDateMatched: boolean;
	isEventTimeMatched: boolean;
	isSectionMatched: boolean;
	isRowMatched: boolean;
	isSeatNumberMatched: boolean;
	errorMessage: string;
}
const initialState: {
	failedTickets: Array<AttachedItem>,
	validationSummary: Array<Verification>
} = {
	failedTickets: [],
	validationSummary: [],
};

export const { thunk: attachPdfs, slice: attachPdfsSlice } = getThunkAndSlice<
	{
		attachedTickets: Array<Ticket>;
		listingId: number;
		quantity: number;
		eventName: string;
		eventDate: string;
		eventTime: string;
		eventHeadliner: string;
		catId: number;
		section: string;
		row: string;
	},
	typeof initialState
>({
	initialState,
	path: "/api/System/AttachPdfs",
	method: "post",
});
