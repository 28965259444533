import { UNKNOWN } from "@src/components/vGrid/VGrid";
import axios, { AxiosInstance, AxiosRequestConfig } from "axios";
import { jwtDecode } from "jwt-decode";

export type EnvironmentType = "PROD" | "UAT" | "QA";
export class ApiProvider {
	private static instance: ApiProvider;
	private axiosInstance: AxiosInstance;
	private environment: EnvironmentType = "QA";
	public setEnviroment(env: EnvironmentType) {
		this.environment = env;
		this.Initialize();
	}
	public get baseURL() {
		const decoded = this.token ? jwtDecode<{ Environment: EnvironmentType }>(this.token) : { Environment: this.environment };
		switch (decoded.Environment) {
			case "PROD":
				return process.env.PROD_API_BASE_URL ?? "";
			case "UAT":
				return process.env.UAT_API_BASE_URL ?? "";
			case "QA":
			default:
				return process.env.QA_API_BASE_URL ?? "";
		}
	}
	private token = "";
	private cache = new Map<string, Map<string, unknown>>();
	setCache<T>(path: string, key: string, data: T) {
		if (!key) return;
		if (!this.cache.has(path)) this.cache.set(path, new Map<string, unknown>());
		this.cache.get(path)?.set(key, data);
	}
	getCache<T>(path: string, key: string): T | undefined {
		if (!key) return;
		if (!this.cache.has(path)) this.cache.set(path, new Map<string, unknown>());
		return this.cache.get(path)?.get(key) as T;
	}
	getAllCache() {
		return this.cache;
	}
	resetCache() {
		this.cache = new Map<string, Map<string, unknown>>();
	}
	private Initialize() {
		this.axiosInstance = axios.create({
			baseURL: this.baseURL,
			headers: {
				"Content-Type": "application/json",
				Accept: "/",
				"Cache-Control": "no-cache",
			},
		});

		this.axiosInstance.interceptors.request.use(
			(config) => {
				if (this.token) {
					config.headers.Authorization = `Bearer ${this.token}`;
				}
				return config;
			},
			(error) => {
				return Promise.reject(new Error(error));
			},
		);
		return this.axiosInstance;
	}
	private constructor() {
		this.axiosInstance = this.Initialize();
	}

	public static get default(): ApiProvider {
		return (ApiProvider.instance ??= new ApiProvider());
	}

	public setToken(token: string): void {
		this.token = token;
		this.Initialize();
	}
	private objectToQueryString(obj?: object) {
		if (!obj) return "";
		return Object.entries(obj)
			.map(([key, value]) => {
				let valueString = "";
				if (value instanceof Date) {
					valueString = value.toISOString();
				} else if (value instanceof Boolean) {
					valueString = value ? "true" : "false";
				} else {
					valueString = `${value}`;
				}
				return `${encodeURIComponent(key)}=${encodeURIComponent(valueString)}`;
			})
			.join("&");
	}
	private appendQueryStringToUrl(url: string, queryString: string) {
		if (!queryString) return url;
		return url.includes("?")
			? `${url}&${queryString}&_dc=${new Date().getTime()}`
			: `${url}?${queryString}&_dc=${new Date().getTime()}`;
	}
	public async get<T>(url: string, data?: object, config?: AxiosRequestConfig): Promise<T> {
		const response = await this.axiosInstance.get<T>(this.appendQueryStringToUrl(url, this.objectToQueryString(data)), config);
		return response.data;
	}
	public async getFile<T>(url: string, data?: object, config?: AxiosRequestConfig): Promise<string> {
		const response = await this.axiosInstance.get<T>(this.appendQueryStringToUrl(url, this.objectToQueryString(data)), {
			...config,
			responseType: "arraybuffer",
		});
		const blob = new Blob([response.data as BlobPart], { type: 'image/png' });
		const imageUrl = URL.createObjectURL(blob);
		return imageUrl;
	}
	public async post<T>(url: string, data?: object): Promise<T> {
		const response = await this.axiosInstance.post<T>(`${url}?_dc=${new Date().getTime()}`, data);
		return response.data;
	}
	public async postWithFile<T>(url: string, data?: Record<string, UNKNOWN>): Promise<T> {
		const formData = new FormData();
		if (data) {
			Object.keys(data).forEach((key) => {
				formData.append(key, data[key]);
			});
		}

		const response = await this.axiosInstance.post<T>(`${url}?_dc=${new Date().getTime()}`, formData, {
			headers: {
				"Content-Type": "multipart/form-data",
			},
		});

		return response.data;
	}
	public async delete<T>(url: string, data?: object): Promise<T> {
		const response = await this.axiosInstance.delete<T>(`${url}?_dc=${new Date().getTime()}`, {
			data
		});
		return response.data;
	}
}
