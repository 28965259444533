import React from "react";
import styles from "@src/components/helpers.module.scss";
import { IIconActiveProps } from "./helpers";

export const PurchasingIcon: React.FC<IIconActiveProps> = ({ isActive }) => {
	return (
		<div className={styles.iconContainer}>
			<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
				<path
					d="M2.5 2.5V15.8333C2.5 16.2754 2.67559 16.6993 2.98816 17.0118C3.30072 17.3244 3.72464 17.5 4.16667 17.5H17.5"
					stroke={isActive ? "var(--activeIcon)" : "var(--inactiveIcon)"}
					strokeWidth="2"
					strokeMiterlimit="5.759"
					strokeLinecap="round"
					strokeLinejoin="round"
				/>
				<path
					d="M5.8335 11.667L9.16683 8.33366L12.5002 11.667L17.5002 6.66699"
					stroke={isActive ? "var(--activeIcon)" : "var(--inactiveIcon)"}
					strokeWidth="2"
					strokeMiterlimit="5.759"
					strokeLinecap="round"
					strokeLinejoin="round"
				/>
				<path
					d="M15 6.66699H17.5V9.16699"
					stroke={isActive ? "var(--activeIcon)" : "var(--inactiveIcon)"}
					strokeWidth="2"
					strokeLinecap="round"
					strokeLinejoin="round"
				/>
			</svg>
		</div>
	);
};
